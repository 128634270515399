﻿.speakers-bureau {
    margin: auto;
    font-family: var(--proxima-nova-regular);
}

.speakers-bureau .top-banner {
    height: 426px;
    padding-top: 10px
}

.speakers-bureau .top-banner * {
    outline: 0
}

.speakers-bureau .top-banner .text-area {
    height: 100%;
    color: #fff;
    background: #232e83;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly
}

.speakers-bureau .top-banner .text-area h1 {
    font-size: 60px;
    margin: 40px 0 0 30px;
    line-height: 1.05;
    color: #fff;
    font-weight: 700;
}

.speakers-bureau .top-banner .text-area p {
    margin: 0 30px;
    font-size: 20px;
    color: #fff;
    font-family: var(--proxima-nova-regular);
}

.speakers-bureau .top-banner .text-area p p {
    margin: 0 !important
}

.speakers-bureau .top-banner .text-area p.bold {
    margin-top: 20px;
    font-weight: 700
}

.speakers-bureau .top-banner .text-area a, .speakers-bureau .top-banner .text-area a:active, .speakers-bureau .top-banner .text-area a:visited, .speakers-bureau .top-banner .text-area a:focus {
    margin: 0 30px 40px;
    background: #fcb53b;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
}

.speakers-bureau .top-banner .banner-image {
    display: none;
}

.speakers-bureau .search {
    margin-bottom: 40px;
    padding-top: 40px
}

@media screen and (min-width:1366px) {
    .speakers-bureau .top-banner {
        display: flex;
        margin-bottom: 40px;
    }

    .speakers-bureau .top-banner .text-area {
        width: -webkit-fill-available;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 2rem;
    }

    .speakers-bureau .top-banner .banner-image {
        display: block;
        height: 100%;
        flex-shrink: 0;

        img {
            height: 100%;
        }
    }

    .speakers-bureau .search {
        padding-top: 0
    }

    .speakers-bureau .search .search-bar {
        width: 90%;
    }
}

.speakers-bureau .search .search-bar {
    height: 43px;
    margin: 0 auto;
    border: 1.5px solid #0088ce;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    overflow: hidden
}

.speakers-bureau .search .search-bar input {
    width: 90%;
    height: 95%;
    outline: 0;
    border: 0;
    margin-left: 20px;
    font-size: 18px;
    color: #0088ce
}

.speakers-bureau .search .search-bar input::-webkit-input-placeholder {
    color: #0088ce
}

.speakers-bureau .search .search-bar input::-moz-placeholder {
    color: #0088ce
}

.speakers-bureau .search .search-bar input::-ms-input-placeholder {
    color: #0088ce
}

.speakers-bureau .search .search-bar input::placeholder {
    color: #0088ce
}

.speakers-bureau .search .search-bar button {
    margin-right: 10px
}

.speakers-bureau .speakers {
    margin-bottom: 40px
}

.speakers-bureau .speakers .title {
    font-size: 20px;
    color: #232e83;
    font-weight: 700;
    text-align: center
}

.speakers-bureau .speakers .speakers-container {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    justify-content: center
}

@media screen and (min-width:650px) and (max-width:1365px) {
    .speakers-bureau .speakers .speakers-container {
        justify-content: space-evenly
    }
}

.speakers-bureau .speakers .speakers-container .speaker-card {
    width: 100%;
    min-height: 145px;
    border: 1px solid #e3e3e3;
    margin-bottom: 15px;
    display: flex;
    padding-right: 10px
}

@media screen and (min-width:430px) {
    .speakers-bureau .speakers .speakers-container .speaker-card {
        width: 430px;
    }
}

@media screen and (min-width:1366px) {

    .speakers-bureau .speakers .speakers-container .speaker-card {
        margin: 15px 40px;
    }

    .speakers-bureau .speakers .speakers-container .speaker-card:nth-child(3n) {
        margin: 0
    }
}

.speakers-bureau .speakers .speakers-container .speaker-card .profile-picture {
    margin: 27px 20px;
    background-position: center;
    background-size: cover;
    height: 92px;
    width: 92px;
    border-radius: 50%;
    background-color: #b7b7b7;
    min-width: 92px
}

.speakers-bureau .speakers .speakers-container .speaker-card .profile .name {
    font-size: 24px;
    color: #0088ce;
    font-weight: 700;
    margin: 27px 0 5px;
    line-height: normal;
}

.speakers-bureau .speakers .speakers-container .speaker-card .profile .description-desktop {
    display: none;
    font-size: 14px;
    font-weight: 700;
    color: #63666a;
    margin-bottom: 15px;
    line-height: 1;
}

.speakers-bureau .speakers .speakers-container .speaker-card .profile .description-mobile {
    font-size: 14px;
    font-weight: 700;
    color: #63666a;
    margin-bottom: 15px
}

.speakers-bureau .speakers .speakers-container .speaker-card:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, .26);
    cursor: pointer
}

.speakers-bureau .speakers .browse-speakers {
    text-align: center
}

.speakers-bureau .speakers .browse-speakers button {
    height: 34px;
    background: #232e83;
    color: #fff;
    font-weight: 700;
    font-size: 16px
}

.speakers-bureau .topics {
    margin-bottom: 40px
}

.speakers-bureau .topics .title {
    font-size: 20px;
    color: #232e83;
    font-weight: 700;
    text-align: center
}

.speakers-bureau .topics .topics-container {
    display: flex;
    flex-direction: column;
    background: #eef7fc;
    padding: 26px 38px 6px 38px;
    justify-content: space-between;
    border-radius: 5px;
    box-sizing: content-box;
}

.speakers-container a {
    text-decoration: none;
    display: block;
}

.speakers-container a:hover {
    text-decoration: none;
}

.speakers-bureau .topics .topics-container .topic-item {
    margin-bottom: 20px;
    text-align: center
}

@include media-breakpoint-down(sm) {
    .speakers-bureau .top-banner {
        display: block;
        width: 100%;
    }

    .speakers-container a {
        width: 100%;
    }
}

@include media-breakpoint-up(xxxl) {
    .speakers-bureau .speakers .speakers-container {
        justify-content: space-between;

        &::after {
            content: '';
            flex: auto;
        }
    }
}

@media screen and (min-width:650px) and (max-width:1365px) {
    .speakers-bureau .topics .topics-container {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .speakers-bureau .topics .topics-container .topic-item button {
        font-size: 15px
    }
}

.speakers-bureau .topics .topics-container .topic-item button {
    height: 48px;
    background: #00bce7;
    font-size: 18px;
    font-weight: 700;
    border: 0;
    width: auto;
}

.request-event {
    background: #f5f5f5;
    padding-bottom: 20px
}

@media screen and (min-width:1366px) {
    .speakers-bureau .speakers .speakers-container .speaker-card .profile .description-desktop {
        display: block;
        line-height: 1;
    }

    .speakers-bureau .speakers .speakers-container .speaker-card .profile .description-mobile {
        display: none
    }

    .speakers-bureau .topics .topics-container {
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .request-event {
        height: 750px
    }
}

.request-event .request-event-container {
    margin: auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%
}

.request-event .request-event-container .title, .request-event .request-event-container .Form__Title {
    font-size: 20px;
    color: #232e83;
    font-weight: 700;
    margin: 27px 0 5px;
    line-height: 36px;
}

.request-event-container .EPiServerForms .Form__Element .Form__Element__Caption {
    display: block;
}

@media screen and (min-width:1366px) {
    .request-event .request-event-container {
        flex-direction: row
    }

    .request-event .request-event-container .text-area {
        width: 400px;
        padding-top: 35px;
        margin-right:10px;
    }

    .request-event .request-event-container .form-area {
        width: 800px
    }
}

.request-event .request-event-container .text-area * {
    outline: 0
}

.request-event .request-event-container .form-area {
    padding: 35px 0 40px
}

.request-event .request-event-container .form-area iframe {
    border: 0;
    width: 100%;
    height: 800px
}

@media screen and (min-width:650px) and (max-width:1365px) {
    .request-event .request-event-container {
        width: 650px;
        margin: 0 auto
    }

    .request-event .request-event-container .form-area iframe {
        height: 600px
    }
}

.request-event .request-event-container .form-area .required-info {
    text-align: right;
    font-size: 13px
}

.request-event .request-event-container .form-area .form-row .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px
}

.request-event .request-event-container .form-area .form-row .form-item label {
    margin-bottom: 5px;
    font-size: 13px
}

.request-event .request-event-container .form-area .form-row .form-item input {
    height: 42px;
    border: 1px solid #707070;
    border-radius: 5px;
    width: 100%
}

.request-event .request-event-container .form-area .form-row .form-item textarea {
    border: 1px solid #707070;
    border-radius: 5px;
    resize: none
}

.request-event .request-event-container .form-area .form-row .no-margin {
    margin-right: 0 !important
}

.request-event .request-event-container .form-area .submit {
    text-align: center
}

@media screen and (min-width:1366px) {
    .request-event .request-event-container .form-area iframe {
        height: 600px
    }

    .request-event .request-event-container .form-area .form-row {
        display: flex
    }

    .request-event .request-event-container .form-area .form-row .form-item:first-child {
        margin-right: 14px
    }

    .request-event .request-event-container .form-area .form-row .form-item input {
        width: 355px
    }

    .request-event .request-event-container .form-area .form-row .form-item textarea {
        width: 730px
    }

    .request-event .request-event-container .form-area .submit {
        text-align: left
    }
}

.request-event .request-event-container .form-area .submit button {
    margin-top: 20px;
    width: 172px;
    height: 34px;
    background: #232e83;
    color: #fff;
    font-weight: 700;
    text-align: center
}

.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay {
    opacity: 0
}

.mat-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: .04
}

@media (hover:none) {
    .mat-button:hover .mat-button-focus-overlay,
    .mat-stroked-button:hover .mat-button-focus-overlay {
        opacity: 0
    }
}

.mat-button,
.mat-flat-button,
.mat-icon-button,
.mat-stroked-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible
}

.mat-button::-moz-focus-inner,
.mat-flat-button::-moz-focus-inner,
.mat-icon-button::-moz-focus-inner,
.mat-stroked-button::-moz-focus-inner {
    border: 0
}

.mat-button[disabled],
.mat-flat-button[disabled],
.mat-icon-button[disabled],
.mat-stroked-button[disabled] {
    cursor: default
}

.mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-flat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-button::-moz-focus-inner,
.mat-flat-button::-moz-focus-inner,
.mat-icon-button::-moz-focus-inner,
.mat-stroked-button::-moz-focus-inner {
    border: 0
}

.mat-raised-button {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1)
}

.mat-raised-button::-moz-focus-inner {
    border: 0
}

.mat-raised-button[disabled] {
    cursor: default
}

.mat-raised-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-raised-button.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-raised-button::-moz-focus-inner {
    border: 0
}

._mat-animation-noopable.mat-raised-button {
    transition: none;
    animation: none
}

.mat-stroked-button {
    border: 1px solid currentColor;
    padding: 0 15px;
    line-height: 34px
}

.mat-stroked-button .mat-button-focus-overlay,
.mat-stroked-button .mat-button-ripple.mat-ripple {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px
}

.mat-fab {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    min-width: 0;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 0;
    flex-shrink: 0
}

.mat-fab::-moz-focus-inner {
    border: 0
}

.mat-fab[disabled] {
    cursor: default
}

.mat-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-fab.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-fab::-moz-focus-inner {
    border: 0
}

._mat-animation-noopable.mat-fab {
    transition: none;
    animation: none
}

.mat-fab .mat-button-wrapper {
    padding: 16px 0;
    display: inline-block;
    line-height: 24px
}

.mat-mini-fab {
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    min-width: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 0;
    flex-shrink: 0
}

.mat-mini-fab::-moz-focus-inner {
    border: 0
}

.mat-mini-fab[disabled] {
    cursor: default
}

.mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
    opacity: .12
}

.mat-mini-fab::-moz-focus-inner {
    border: 0
}

._mat-animation-noopable.mat-mini-fab {
    transition: none;
    animation: none
}

.mat-mini-fab .mat-button-wrapper {
    padding: 8px 0;
    display: inline-block;
    line-height: 24px
}

.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%
}

.mat-icon-button .mat-icon,
.mat-icon-button i {
    line-height: 24px
}

.mat-button-focus-overlay,
.mat-button-ripple.mat-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit
}

.mat-button-ripple.mat-ripple:not(:empty) {
    transform: translateZ(0)
}

.mat-button-focus-overlay {
    opacity: 0;
    transition: opacity .2s cubic-bezier(.35, 0, .25, 1), background-color .2s cubic-bezier(.35, 0, .25, 1)
}

._mat-animation-noopable .mat-button-focus-overlay {
    transition: none
}

@media (-ms-high-contrast:active) {
    .mat-button-focus-overlay {
        background-color: #fff
    }
}

@media (-ms-high-contrast:black-on-white) {
    .mat-button-focus-overlay {
        background-color: #000
    }
}

.mat-button-ripple-round {
    border-radius: 50%;
    z-index: 1
}

.mat-button .mat-button-wrapper > *,
.mat-fab .mat-button-wrapper > *,
.mat-flat-button .mat-button-wrapper > *,
.mat-icon-button .mat-button-wrapper > *,
.mat-mini-fab .mat-button-wrapper > *,
.mat-raised-button .mat-button-wrapper > *,
.mat-stroked-button .mat-button-wrapper > * {
    vertical-align: middle
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    display: block;
    font-size: inherit;
    width: 2.5em;
    height: 2.5em
}

@media (-ms-high-contrast:active) {
    .mat-button,
    .mat-fab,
    .mat-flat-button,
    .mat-icon-button,
    .mat-mini-fab,
    .mat-raised-button {
        outline: solid 1px
    }
}
