.oma-navigation-menu {
    width: 100%;

    @include media-breakpoint-only(md) {
        left: 0;
    }

    @include media-breakpoint-down(md) {
        position: absolute;
        top: 20px;
        z-index: 100;
    }

    &__container {
        position: relative;
        background-color: var(--white);
    }

    &__link-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-right: 1rem;
        min-height: 40px;
        line-height: 22px;

        &._lvl2, &._lvl3, &._lvl4 {
            &:hover {
                @include media-breakpoint-up(md) {
                    background: var(--pale-cyan);
                    text-decoration: underline;

                    a, a:active, a:visited, a:focus {
                        color: var(--blue);
                    }
                }
            }

            &.active {
                background: var(--pale-cyan);
                text-decoration: underline;

                a, a:active, a:visited, a:focus {
                    color: var(--blue);
                }
            }
        }
    }

    &__responsive-toggle {

        &-icon {
            &:after {
                display: inline-block;
                content: '';
                width: 40px;
                height: 30px;
                background: url(/Static/Images/oma/mobile-menu-icon.svg) no-repeat center;
            }

            &.active {
                &:after {
                    background: url(/Static/Images/oma/mobile-menu-icon-close.svg) no-repeat center;
                }
            }

            background: url(/Static/Images/oma/mobile-menu-icon-close.svg) no-repeat -999999px -999999px;
        }

        &-icon {
            &:after {
                display: inline-block;
                content: '';
                width: 40px;
                height: 30px;
                background: url(/Static/Images/oma/mobile-menu-icon.svg) no-repeat center;
                background-size: contain;

                @include media-breakpoint-only(sm) {
                    width: 32px;
                    height: 24px;
                }

                @include media-breakpoint-down(xs) {
                    height: 20px;
                }
            }

            &.active {
                &:after {
                    background: url(/Static/Images/oma/mobile-menu-icon-close.svg) no-repeat center;
                    background-size: contain;
                }
            }

            background: url(/Static/Images/oma/mobile-menu-icon-close.svg) no-repeat -999999px -999999px;
        }
    }

    &__ul {
        padding: 0;

        @include media-breakpoint-down(md) {
            &._lvl1 {
                padding-top: $v-margin-between-blocks;
            }
        }

        &._lvl1 {
            display: flex;
            position: relative;
            margin-bottom: 0;
            z-index: 10;

            @include media-breakpoint-down(md) {
                display: block;
                visibility: hidden;
                padding-bottom: 50px;
                border: 0;
                opacity: 0;
                z-index: 10;
                background: white;
                transition: all .3s;
            }

            &.hidden {
                visibility: hidden;
            }

            &.isVisible {
                // mobile toggle menu
                @include media-breakpoint-down(md) {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &:not(:hover) {
                .oma-navigation-menu__link._lvl1 {
                    &.underlined {
                        @include media-breakpoint-up(lg) {
                            border-bottom: 4px solid var(--bright-blue);
                        }
                    }
                }
            }

            &:hover {
                .oma-navigation-menu__link._lvl1 {
                    &.underlined {
                        @include media-breakpoint-up(lg) {
                            border-bottom: 4px solid var(--white);

                            &:hover {
                                border-bottom: 4px solid var(--bright-blue);
                            }
                        }
                    }
                }
            }
        }

        &._lvl3 {
            display: block;

            @include media-breakpoint-down(md) {
                visibility: hidden;
                opacity: 0;
                height: 0;
            }
        }

        &._lvl4 {
            display: none;

            @include media-breakpoint-up(md) {
                position: absolute;
                background: white;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                padding: $v-margin-between-blocks;
            }


            &.isVisible {
                display: block;
            }
        }

        &._lvl5 {
            display: none;

            @include media-breakpoint-up(md) {
                position: absolute;
                background: white;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                padding: $v-margin-between-blocks;
            }


            &.isVisible {
                display: block;
            }
        }

        &._lvl6 {
            display: none;

            @include media-breakpoint-up(md) {
                position: absolute;
                background: white;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                padding: $v-margin-between-blocks;
            }


            &.isVisible {
                display: block;
            }
        }
    }

    &__li {
        list-style-type: none;

        &._lvl1 {

            @include media-breakpoint-up(md) {
                display: flex;
                line-height: 40px;
                padding-right: 13px;
            }

            @include media-breakpoint-down(md) {
                border-bottom: 1px solid #d5d6d6;
                //padding: 0 0 0 16px;
                transition: all .3s;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5px 0;
                // mobile
                &.expanded {
                    height: auto;

                    .oma-navigation-menu__link._lvl1 {
                        text-decoration: underline;
                    }

                    .oma-navigation-menu__columns-container._lvl2 {
                        visibility: visible;
                        opacity: 1;
                        height: auto;
                        margin: 4px 0 24px;
                    }

                    .oma-navigation-menu__expand-icon {
                        background: url(/Static/Images/oma/mobile-menu-collapse.svg) no-repeat center;
                    }
                }

                &.expanded.chosen {
                    .oma-navigation-menu__link-wrapper._lvl1 {
                        background: var(--pale-cyan);
                    }

                    .oma-navigation-menu__link._lvl1 {
                        background: var(--pale-cyan);
                    }

                    .oma-navigation-menu__columns-container._lvl2 {
                        visibility: visible;
                        opacity: 1;
                        transition-delay: 0s, 0s;
                    }
                }
            }

            .oma-navigation-menu__column._lvl2 {
                @include media-breakpoint-up(lg) {
                    width: 33%;
                }
            }

            .oma-navigation-menu__columns-container._lvl2 {
                @include media-breakpoint-up(lg) {
                    position: absolute;
                    z-index: 10;
                    top: 100%;
                    left: 0;
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-top: 40px;
                    border-top: 1px solid var(--breadcrumb-grey);
                    overflow-y: auto;
                    background: transparent;
                    visibility: visible;
                    display: none;
                    opacity: 0;
                }

                visibility: hidden;
                opacity: 0;
                margin: 0;
                height: 0;
                background: white;
            }

            &:hover, &.active {

                @include media-breakpoint-up(lg) {
                    .oma-navigation-menu__link._lvl1 {
                        border-bottom: 4px solid var(--bright-blue);
                    }

                    .oma-navigation-menu__columns-container._lvl2 {
                        display: flex;
                        animation: lvl2Fade 0.5s ease;
                        animation-fill-mode: forwards;
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .oma-navigation-menu__expand-icon {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    background: url(/Static/Images/oma/mobile-menu-expand.svg) no-repeat center;
                }
            }
        }

        &._lvl2, &._lvl4 {
            @include media-breakpoint-up(lg) {
                width: 100%;
            }
        }

        &._lvl2 {
            @include media-breakpoint-up(lg) {
                margin-bottom: 30px;
                padding: 0 15px;
                height: fit-content;
            }

            @include media-breakpoint-down(md) {
                // mobile
                &.expanded {
                    height: auto;

                    .oma-navigation-menu__link._lvl2 {
                        text-decoration: underline;
                    }

                    .oma-navigation-menu__ul._lvl3 {
                        margin-bottom: 15px;
                        height: auto;
                        visibility: visible;
                        opacity: 1;
                    }

                    .oma-navigation-menu__expand-icon-lvl2 {
                        background-image: url(/Static/Images/oma/mobile-menu-collapse-lvl2.svg);
                    }
                }

                &.expanded.chosen {
                    .oma-navigation-menu__link-wrapper._lvl2 {
                        background: var(--pale-cyan);
                    }
                }

                .oma-navigation-menu__expand-icon-lvl2 {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    background: url(/Static/Images/oma/mobile-menu-expand-lvl2.svg) no-repeat center;
                    background-size: 12px;
                }
            }
        }

        &._lvl3 {

            @include media-breakpoint-down(md) {
                // mobile
                &.expanded {
                    height: auto;

                    .oma-navigation-menu__link._lvl3 {
                        text-decoration: underline;
                    }

                    .oma-navigation-menu__ul._lvl4 {
                        height: auto;
                        visibility: visible;
                        opacity: 1;
                        display: block;
                    }

                    .oma-navigation-menu__expand-icon-lvl3 {
                        background-image: url(/Static/Images/oma/mobile-menu-collapse-lvl2.svg);
                    }
                }

                &.expanded.chosen {
                    .oma-navigation-menu__link-wrapper._lvl3 {
                        background: var(--pale-cyan);
                    }
                }
            }

            @include media-breakpoint-down(md) {
                .oma-navigation-menu__expand-icon-lvl3 {
                    width: 16px;
                    height: 16px;
                    background: url(/Static/Images/oma/mobile-menu-expand-lvl2.svg) no-repeat center;
                    background-size: 10px;
                }
            }
        }

        &._lvl4 {
            @include media-breakpoint-down(md) {
                // mobile
                &.expanded {
                    height: auto;

                    .oma-navigation-menu__link._lvl4 {
                        text-decoration: underline;
                    }

                    .oma-navigation-menu__ul._lvl5 {
                        height: auto;
                        visibility: visible;
                        opacity: 1;
                        display: block;
                    }

                    .oma-navigation-menu__expand-icon-lvl4 {
                        background-image: url(/Static/Images/oma/mobile-menu-collapse-lvl2.svg);
                    }
                }

                &.expanded.chosen {
                    .oma-navigation-menu__link-wrapper._lvl4 {
                        background: var(--pale-cyan);
                    }
                }

                .oma-navigation-menu__expand-icon-lvl4 {
                    width: 16px;
                    height: 16px;
                    background: url(/Static/Images/oma/mobile-menu-expand-lvl2.svg) no-repeat center;
                    background-size: 10px;
                }
            }

            padding-left: 15px;

            &.active {
                background: var(--pale-cyan);
                text-decoration: underline;

                a, a:active, a:visited, a:focus {
                    color: var(--blue);
                }
            }
        }

        &._lvl5 {
            @include media-breakpoint-down(md) {
                // mobile
                &.expanded {
                    height: auto;

                    .oma-navigation-menu__link._lvl5 {
                        text-decoration: underline;
                    }

                    .oma-navigation-menu__ul._lvl6 {
                        height: auto;
                        visibility: visible;
                        opacity: 1;
                        display: block;
                    }

                    .oma-navigation-menu__expand-icon-lvl5 {
                        background-image: url(/Static/Images/oma/mobile-menu-collapse-lvl2.svg);
                    }
                }

                &.expanded.chosen {
                    .oma-navigation-menu__link-wrapper._lvl5 {
                        background: var(--pale-cyan);
                    }
                }

                .oma-navigation-menu__expand-icon-lvl5 {
                    width: 16px;
                    height: 16px;
                    background: url(/Static/Images/oma/mobile-menu-expand-lvl2.svg) no-repeat center;
                    background-size: 10px;
                }
            }

            padding-left: 15px;

            &.active {
                background: var(--pale-cyan);
                text-decoration: underline;

                a, a:active, a:visited, a:focus {
                    color: var(--blue);
                }
            }
        }

        &._lvl6 {
            @include media-breakpoint-down(md) {
                font-size: 13px;
                font-family: var(--proxima-nova-light);
            }

            padding-left: 15px;

            &.active {
                background: var(--pale-cyan);
                text-decoration: underline;

                a, a:active, a:visited, a:focus {
                    color: var(--blue);
                }
            }
        }
    }

    &__link, &__link:visited {
        color: var(--black);

        &._lvl1 {
            border-bottom: 4px solid transparent;
            font-family: var(--proxima-nova-semibold);
            text-decoration: none;
            display: flex;
            height: 100%;
            align-items: center;
            transition: border-color .2s;

            @include media-breakpoint-between(lg, xl) {
                text-align: center;
            }

            @include media-breakpoint-down(md) {
                font-size: 18px;
                padding: 0 10px;
                justify-content: space-between;
                border: 0;
                width: 90%;
                flex-shrink: 0;
            }
        }

        &:hover {
            color: var(--black);
        }

        &:focus + .oma-navigation-menu__columns-container._lvl2 {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s, 0s;
        }

        &._lvl2 {
            font-family: var(--proxima-nova-semibold);
            text-decoration: none;
            height: 50px;
            display: flex;
            justify-content: space-between;
            border: 0;
            width: 100%;

            @include media-breakpoint-down(md) {
                font-family: var(--proxima-nova-light);
            }
        }

        &._lvl3 {
            justify-content: space-between;
            font-family: var(--proxima-nova-light);

            @include media-breakpoint-down(md) {
                padding: 0 10px;
                font-size: 15px;
            }
        }

        &._lvl4 {
            @include media-breakpoint-down(md) {
                font-size: 14px;
                font-family: var(--proxima-nova-light);
            }
        }

        &._lvl5 {
            @include media-breakpoint-down(md) {
                font-size: 14px;
                font-family: var(--proxima-nova-light);
            }
        }

        &._lvl2, &._lvl3, &._lvl4, &._lvl5, &._lvl6 {
            display: block;
            height: auto;
            padding: 8px 10px;

            @include media-breakpoint-down(md) {
                display: flex;
                align-items: center;
            }

            &:hover {
                @include media-breakpoint-up(md) {
                    text-decoration: none;
                }
            }
        }
    }

    &__lvl4-header {
        &:hover {
            color: var(--black);
        }
    }

    &__lvl5-header {
        &:hover {
            color: var(--black);
        }
    }

    &__dynamic-backdrop-panel {
        width: 100%;
        padding: 0 15px;
        height: 0px;
        background: var(--white);
        position: absolute;
        top: 42px;
        left: 0;
        pointer-events: none;
        margin: 0 auto;
        z-index: 5;
        opacity: 0;
        transition: height 0.4s;

        &.with-all-transition {
            transition: all 0.4s;
        }
    }
}


.bg-overlay {
    display: none;

    &.isVisible {
        display: block;
    }

    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: 4;
}

@keyframes lvl2Fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
