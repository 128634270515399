﻿$v-margin-between-blocks: 30px;
$v-big-margin-between-blocks: 40px;
$v-extra-big-margin-between-blocks: 60px;

:root {
    --white: #FFFFFF;
    --border-grey: #D5D6D6;
    --light-grey: #F5F5F5;
    --grey: #868D8D;
    --dark-grey: #636363;
    --breadcrumb-grey: #707070;
    --black: #212121;
    --pale-cyan: #DFF6FC;
    --light-cyan: #BFEEF9;
    --cyan: #00BCE7;
    --dark-cyan: #007DBB;
    --bright-blue: #0088CE;
    --blue: #006DA5;
    --dark-blue: #232E83;
    --member-menu-dark-blue: #252F7E;
    --light-yellow: #F6D781;
    --yellow: #FCB53B;
    --red: #D90B0B;
    --teal: #00818F;
    --proxima-nova-light: 'Proxima Nova Light';
    --proxima-nova-regular: 'Proxima Nova Regular';
    --proxima-nova-semibold: 'Proxima Nova Semibold';
    --omf-green:#E0F1D8;
    --omf-blue:#232E83;

}