.oma-searchable-expanding-content {
    padding: 16px 0;

    &__title {
        float: left;
        padding-bottom: 10px;
    }

    &__search {
        width: 25%;
    }

    &__button {
        padding: 8px 0;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        text-decoration: none !important;
        font-family: var(--proxima-nova-light);
        color: var(--blue);
        background: url('/Static/images/oma/accordion-arrow-up.svg') no-repeat -9999px -9999px;

        i {
            background: url('/Static/images/oma/accordion-arrow-down.svg') center no-repeat;
        }

        &:hover {
            i {
                background: url('/Static/images/oma/accordion-arrow-down-dark.svg') center no-repeat;
            }
        }

        .active & {
            color: var(--black);

            i {
                background-image: url('/Static/images/oma/accordion-arrow-up.svg');
            }
        }
    }

    &__icon {
        width: 15px;
        height: 10px;
        display: inline-block;
        float: right;
    }

    &__body {
        padding: 0;
    }

    &__inner-content {
        padding: 8px 0;
    }

    &__clear {
        height: fit-content;
    }

    &__cross {
        font-style: normal;
        color: #9b9b9b;
    }
}

.searchMatchResult {
    background-color: yellow;
}

.lblCount {
    margin-bottom: 10px;
    background: #ffff56
}


.bkg-aqua {
    background-color: #BFEEF9;
    float: left;
    margin-right: 5px;
    text-align: center;
    width: 40px;
    color: #626060;
    font-size: medium;
}
.accordionTitle{
    display: inline-block;
    max-width:92%;
}