﻿.oma-featured-news {
    max-width: 900px;
    margin-bottom: 20px;

    .oma-featured-news-container {
        padding-top: 15px;
    }

    .oma-featured-news-main {
        display: flex;
        flex-wrap: wrap;

        .oma-featured-news-main-thumbnail {
            padding-right: 20px;
            padding-bottom: 10px;
            padding-left: 0;
        }
    }

    .oma-featured-news-second {
        display: flex;
        flex-wrap: wrap;
        margin-top:1em;
    }

    .oma-featured-news-item {
        margin-bottom: 20px;
        padding: 0;
        padding-right: 15px;

        .oma-featured-news-item-title {
            margin-top: 0;
        }

        .oma-featured-news-item-caption {
            font-family: var(--proxima-nova-light);
            font-size: 16px;
            line-height: 22px;
            margin: 0;
        }
    }

    .featured-news-more-link {
        font-family: var(--proxima-nova-semibold);
        font-size: 16px;
        line-height: 20px;
    }
}
