/* media queries examples:
    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(lg) {}
    @include media-breakpoint-between(sm, md) {}
*/

// Override Bootstrap breakpoints to OMA breakpoints (X small 360, Medium 720, X large 1140)
$grid-breakpoints: (
        xs: 0,
        sm: 360px,
        md: 720px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1600px,
);

// .container max width
$container-max-widths: (
        sm: 540px,
        md: 710px,
        lg: 960px,
        xl: 1140px,
        xxl: 1380px,
        xxxl: 1560px,
);

@import "../../../../node_modules/bootstrap/scss/bootstrap";

@import "shared/mixins";
@import "shared/variables";

@import "blocks/bottom-feature-block";
@import "blocks/featured-content-block";
@import "blocks/cta/cta-block";
@import "blocks/cta/cta-news";
@import "blocks/cta/cta-with-background-image";
@import "blocks/cta/cta-with-dark-background";
@import "blocks/cta/cta-with-grey-background";
@import "blocks/image-block";
@import "blocks/text-block";
@import "blocks/table-block";
@import "blocks/breadcrumb";
@import "blocks/expanding-content";
@import "blocks/left-nav-block";
@import "blocks/marketplace-company-block";
@import "blocks/masonry-container";
@import "blocks/quick-links-block";
@import "blocks/related-discount-block";
@import "blocks/member-login-block";
@import "blocks/retirement-calculator";
@import "pages/oma-news-event-detail";
@import "pages/oma-discount-detail";
@import "pages/oma-page";
@import "pages/oma-one-column";
@import "pages/oma-two-column.scss";
@import "pages/oma-two-column-left-nav";
@import "pages/oma-three-column-left-nav";
@import "pages/oma-speaker-profile";
@import "pages/oma-senior-management-team-profiles";
@import "pages/oma-award-winner";
@import "pages/oma-search-page";
@import "pages/oma-publication";
@import "pages/oma-new-publication";
@import "pages/oma-speaker-search";
@import "pages/oma-speakers-bureau";
@import "pages/oma-marketplace-company-search-page";
@import "pages/oma-community";
@import "pages/oma-careers-page";
@import "shared/back-to-top-link";
@import "shared/fonts";
@import "shared/header";
@import "shared/footer";
@import "shared/main";
@import "shared/navigation-menu";
@import "shared/sf-forms";
@import "partials/oma-news-item";
@import "pages/oma-news-events-list-page";
@import "shared/paging";
@import "blocks/priority-cta/priority-cta-block";
@import "blocks/priority-cta/priority-cta-with-button";
@import "blocks/priority-cta/priority-cta-top-feature";
@import "blocks/priority-cta/priority-leadership";
@import "blocks/profile-block";
@import "pages/oma-discounts";
@import "shared/checkbox";
@import "blocks/cta/cta-homepage-feature";
@import "blocks/priority-cta/priority-ctas-container";

@import "blocks/media-report-links-block";
@import "shared/new-flag";
@import "shared/header-search-bar";

@import "editor.css";
@import "blocks/row-content-container";
@import "forms/oma-forms";
@import "blocks/video-block";
@import "blocks/video-collection-block";
@import "blocks/captioned-video-block";
@import "shared/notification";
@import "blocks/candidate-block";
@import "blocks/shared-style";
@import "blocks/content-tile-block";
@import "blocks/resource-block";
@import "blocks/featured-news-block.scss";
@import "blocks/split-content-container-block.scss";
@import "blocks/contact-an-advisor.scss";
@import "blocks/oma-apply-cta.scss";
@import "blocks/benefit-block.scss";
@import "blocks/wide-featured-content-block.scss";
@import "blocks/oma-product-tile.scss";
@import "blocks/marketing-intro-block.scss";
@import "blocks/omai-table-block.scss";
@import "blocks/omai-opip-premium-calculator.scss";
@import "blocks/priority-cta-with-video.scss";
@import "blocks/omai-help-me-choose";
@import "blocks/homepage-cta.scss";
@import "blocks/iframe-block.scss";
@import "blocks/oma-omr-banner-block.scss";
@import "blocks/oma-publication-article-tile-block.scss";
@import "blocks/oma-salesforce-case-submission-block.scss";