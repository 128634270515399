﻿.oma-discounts {

    &__intro-text {
        margin-bottom: 30px;

        p {
            font-family: var(--proxima-nova-regular);
            font-size: 28px;
            line-height: 36px;
            color: var(--dark-blue);
        }
    }

    &-form {
        display: flex;
        justify-content: flex-end;

        &__dropdown {
            width: 50%;
        }
    }

    &__paging {
        padding-top: 30px;
    }

    &__items {
        margin-bottom: 60px;

        @include media-breakpoint-down(sm) {
            margin-bottom: $v-margin-between-blocks;
        }
    }
}

.discount-item {
    display: flex;
    margin-top: 30px;

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 10px;
    }

    &__image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 160px;
        max-width: 160px;
        height: 120px;
        margin-right: 15px;
    }

    &__image {
        height: 100%;
        width: fit-content;
        object-fit: contain;
    }
}
