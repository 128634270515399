.oma-margin-centre {
    margin: 0 auto;
}

.oma-product-tiles-container {
    & > h2, & > p{
        text-align:center;
    }
    & > .oma-product-tiles-inner-container {
        & > * {
            margin-bottom: 30px;
            display: flex;
        }
    }
}

.oma-product-tile-block {
    width: 100%;

    .oma-product-tile-block-container {
        border: 2px solid #D5D6D6;
        text-align: center;
        padding: 25px;
        flex-direction: column;
        height: 100%;

        .product-tile-block-image {
            padding-right: 10px;
            display: inline-block;
            margin-bottom: 0.5rem;

            img {
                width: 70px;
                height: 70px;
                min-width: 70px;
                min-height: 70px;
                border-radius: 50%;

                @media only screen and (max-width: 768px) {
                    width: 35px;
                    height: 35px;
                    min-width: 35px;
                    min-height: 35px;
                }
            }
        }

        .image-title {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .highlight {
            display: inline-block;
            font-family: var(--proxima-nova-medium);
            text-align: center;
            font-size: 13px;
            line-height: 20px;
            color: #FFFFFF;
            background-color: #00818F;
            padding-left: 10px;
            padding-right: 10px;
        }

        .prod-title {
            font-family: var(--proxima-nova-light);
            text-align: center;
            font-size: 30px;
            line-height: 36px;
            color: #006DA5;
            margin-top: 2px;
            font-weight: 400;
            margin-bottom: 0.5rem;
        }

        .with-image {
            display: inline-block;
        }

        .sub-title {
            font-family: var(--proxima-nova-regular);
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            color: #212121;
        }

        .cta-link {
            font-family: var(--proxima-nova-semibold);
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            color: #006DA5;
            text-decoration: none;

            &:hover {
                color: #0056b3;
                text-decoration: underline;
            }
        }
    }
}
