﻿.priority-with-button {
    padding-bottom: 1rem;
    height: 100%;

    &.narrow-view {
        margin-left: 5rem;
        margin-right: 5rem;
    }

    .oma-text-container {
        text-align: center;
        width: 100%;
        word-break: break-word;

        .oma-title {
            color: var(--black);
            margin-bottom: 1rem;
            margin-top: 0;
        }

        .oma-caption {
            text-align: center;
            margin-bottom: 1.5rem;
        }

        .oma-subtitle {
            margin-bottom: 0.5rem;
        }

        .oma-btn {
            padding-right: 1rem;
            padding-left: 1rem;
            background-color: var(--bright-blue);
            margin-bottom: 2rem;
            color: #fff !important;
            margin-right: 0.5rem;
            margin-left: 0.5rem;
        }

        .oma-additional-content p {
            margin-bottom: 0.5rem;
        }

        ul {
            font-family: var(--proxima-nova-light);
        }
    }
}
