﻿.oma-omr-banner-block {
    margin-bottom: 30px;

    .omr-banner-block-content {
        width: auto;
        height: 400px;
        padding: 0;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        @include media-breakpoint-down(md) {
            width: auto;
            height: 690px;
        }

        @media(min-width:1141px) {
            width: 100%;
            height: 400px;
        }

        @include media-breakpoint-down(sm) {
            width: auto;
            height: 420px;
        }



        .omr-banner-block-text {
            width: 555px;
            padding: 5px 15px 5px 15px;
            overflow: hidden;
            background-color: #232E83;
            text-align: left;
            opacity: 0.93;
            margin-bottom: $v-margin-between-blocks;
            margin-right: 0px;
            max-height: 150px;

            @media(min-width:1141px) {
                margin-bottom: $v-extra-big-margin-between-blocks !important;
            }

            @media(max-width: 600px) {
                width: 280px;
                max-height: 215px;
            }

            .omr-caption {
                line-height: 24px;
                color: #00BCE7;
                font-size: 1.2rem;
                font-family: var(--proxima-nova-regular);
                margin: 10px 0px;
            }

            .omr-title {
                line-height: 40px;
                overflow: hidden;
                color: var(--white);
                font-size: 36px;
                font-family: var(--proxima-nova-light);
                margin: 10px 0px;
            }
        }
    }
}
