﻿.oma-new-flag {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 45px;
    height: 20px;
    font-family: var(--proxima-nova-regular);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    background-color: var(--yellow);
    color: var(--black);

    @include media-breakpoint-only(xxl) {
        width: 55px;
        height: 25px;
        line-height: 25px;
        font-size: 14px;
    }

    @include media-breakpoint-only(xxxl) {
        width: 62px;
        height: 27px;
        line-height: 27px;
        font-size: 14px;
    }
}
