﻿.oma-video-container {
    margin-bottom: $v-big-margin-between-blocks;

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    .container-child {
        width: 560px;
        height: 380px;
        margin: 0px;
        min-height: 180px;

        @include media-breakpoint-down(xl) {
            height: 310px;
        }

        @include media-breakpoint-down(md) {
            height: auto;
        }
    }

    .container-child-half {
        &-video {
            display: flex;
            flex-shrink: 0;
            width: 50%;

            @include media-breakpoint-down(md) {
                width: 100%;
            }

            iframe {
                width: 100%;
                height: 380px;
                border: none;

                @include media-breakpoint-down(xl) {
                    height: 310px;
                }

                @include media-breakpoint-only(md) {
                    height: 270px;
                }

                @include media-breakpoint-down(sm) {
                    height: auto;
                }
            }
        }

        &-text {
            background-color: #E7E2DF;
            width: 100%;

            .text-container {
                line-height: 1.15 !important;
                font-family: var(--proxima-nova-regular);
            }

            .gold-bar {
                border-left: 5px solid #e8c400;

                .heading {
                    line-height: 1.15;
                    color: rgb(0, 26, 114) !important;
                    padding-left: 5px;
                    display: block;
                    font-size: 1.5em;
                    margin: 0.83em 0;
                    font-weight: bold;
                }
            }

            &.blue-background {
                background-color: rgb(35,45,132);

                .text-container p {
                    color: rgb(160,171,234);
                }

                .gold-bar {
                    .heading {
                        color: #FFF !important;
                    }
                }
            }
        }

        &-info {
            padding: 1.8em;
        }
    }
}
