﻿.oma-news-item {
    display: flex;
    word-break: break-word;
    margin-bottom: 2em;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .oma-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 350px;
        max-width: 350px;
        height: 200px;
        overflow: hidden;
        margin-right: 15px;

        .oma-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .oma-news-item-text {
        width: 100%;
        padding-top: 0.5em;
        overflow: hidden;

        .oma-news-type {
            margin-bottom: 0.7em;
            font-size: 1.125rem;
            font-family: var(--proxima-nova-regular);
            line-height: 24px;
            color: var(--black);
        }

        .oma-news-item-media-source {
            font-weight: bold;
        }

        .oma-news-item-title {
            font-family: var(--proxima-nova-light);
            font-size: 1.5rem;
            line-height: 28px;
        }
    }
}
