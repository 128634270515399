﻿.oma-apply-cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:30px;
    & .oma-apply-cta-button {
        font-size: 16px;
        padding: 10px 36px;
    }

    & .or {
        margin: 5px 0;
    }

    & .oma-apply-cta-link {
        font-family: var(--proxima-nova-semibold);
        font-size: 16px;
        cursor:pointer;
    }

    & .oma-candidate-block-modal-container {
        & .expander {
            flex: 1;
        }
    }

    & .oma-candidate-block-modal-container {
        & > .oma-candidate-block-modal {
            max-width: 750px;
            max-height: 550px;
        }

        & .modal-page-actions {
            & > button:first-child {
                margin-right: 20px;
            }
        }

        & .modal-prev-button {
            font-family: var(--proxima-nova-semibold);

            & > .icon {
                background: url('/Static/images/oma/back-arrow.svg');
                width: 13px;
                height: 15px;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .triage-list {
        list-style: none;
        counter-reset: item;

        & > li {
            counter-increment: item;
            margin-bottom: 5px;


            &:before {
                margin-right: 10px;
                content: counter(item);
                background: #EAF6F6;
                color: #006DA5;
                border-radius: 100%;
                width: 1.2em;
                text-align: center;
                display: inline-block;
                font-size: 18px;
                width: 30px;
                height: 30px;
                font-family: var(--proxima-nova-semibold);
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
