﻿.homepage-feature {
    height: 100%;
    position: relative;
    padding-bottom: $v-margin-between-blocks;



    @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        height: auto;
        padding-bottom:0;
    }

    .oma-cta-block-image-wrapper {
        height: 100%;
        width: 100%;
        position: relative;
        background-position: center;
        background-size: cover;

        @include media-breakpoint-down(md) {
            min-height:300px;
        }

    }

    .oma-cta-block-image {
        object-fit: cover;
        height: 100%;

        @include media-breakpoint-down(md) {
            height: auto;
            width: 100%;
        }
    }

    .oma-image-bottom-overlay, .oma-image-top-overlay {
        position: absolute;
        left: -160px;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    .oma-image-bottom-overlay {
        bottom: -80px;
    }

    .oma-image-top-overlay {
        top: -40px;
    }

    .oma-cta-block-text {
        position: absolute;
        width: 460px;
        padding: 20px 15px 25px 15px;
        margin-bottom: 80px;
        background-color: var(--dark-blue);
        opacity: 1;
        min-height: 160px;
        overflow: visible;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 0;
            position: relative;
        }

        @include media-breakpoint-only(xxl) {
            width: 560px;
            padding: 25px 18px 30px 18px;
        }

        @include media-breakpoint-only(xxxl) {
            width: 630px;
            padding: 27px 20px 33px 20px;
        }

        .relative-container {
            overflow: visible;
        }

        .oma-cta-caption {
            line-height: 1.5em;
            color: var(--cyan);
            font-size: 18px;
            margin-bottom: 12px;
        }

        .oma-cta-link:hover {
            color: var(--white);
        }

        .oma-cta-title {
            line-height: 40px;
            color: var(--white);
            font-size: 36px;
        }
    }
}
.homepage-cta-block {
    .btn-info, .btn-info:visited {
        color: #fff !important;
    }
}

