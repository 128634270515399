.oma-new-publication {

    &__issue {
        display: flex;
        justify-content: flex-end;

        select {
            min-width: 255px;
        }
    }

    &__items {
        margin: auto;
    }

    &__item {
        &-header {
            color: white;
            font-size: 18px;
            padding: 10px 16px;
            background: var(--dark-blue);
        }

        &-content {
            padding: 16px 16px 0 16px;
            margin-bottom: 25px;
        }
    }

    &__page-subtitle {
        font-size: 13px;
        margin-bottom: 4px;
        color: var(--red);
        font-family: var(--proxima-nova-regular);
    }

    &__page-title {
        font-size: 18px;
        color: var(--dark-blue);
    }

    padding-bottom: $v-margin-between-blocks;

    h1 {
        @media (min-width: 1200px){
            float:left;
        }
    }
}
