﻿.Form__MainBody {
    input {
        width: 100%;
        border: 1px solid rgb(207, 212, 216);
        background-color: rgb(255, 255, 255);
        border-radius: 4px;
        padding: 5px;
    }

    textarea {
        width: 100%;
        min-height: 100px;
    }

    button[type="submit"] {
        font-size: 16px;
        font-weight: bold;
        height: 34px;
        line-height: 0;
        padding: 14px 14px;
        border: 0 solid transparent;
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        font-style: normal;
        color: #ffffff;
        background-color: #232E83;
    }

   
}
