﻿/* ProximaNova-Light */
@font-face {
    font-family: 'Proxima Nova Light';
    font-style: normal;
    font-weight: normal;
    src: url('/static/fonts/oma/ProximaNova-Light.eot'); /* IE9 Compat Modes */
    src: url('/static/fonts/oma/ProximaNova-Light.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/oma/ProximaNova-Light.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* ProximaNova-Regular */
@font-face {
    font-family: 'Proxima Nova Regular';
    font-style: normal;
    font-weight: normal;
    src: url('/static/fonts/oma/ProximaNova-Regular.eot'); /* IE9 Compat Modes */
    src: url('/static/fonts/oma/ProximaNova-Regular.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/oma/ProximaNova-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* ProximaNova-Semibold */
@font-face {
    font-family: 'Proxima Nova Semibold';
    font-style: normal;
    font-weight: normal;
    src: url('/static/fonts/oma/ProximaNova-Semibold.eot'); /* IE9 Compat Modes */
    src: url('/static/fonts/oma/ProximaNova-Semibold.woff') format('woff'), /* Modern Browsers */
    url('/static/fonts/oma/ProximaNova-Semibold.ttf') format('truetype'); /* Safari, Android, iOS */
}
