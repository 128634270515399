﻿.oma-checkbox {

    &-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &-input:checked ~ .oma-checkbox-checkmark::after {
        display: block;
    }

    &-checkmark {
        position: absolute;
        top: 7px;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: var(--white);
        border: solid 1px;

        &::after {
            content: url(../../../Static/Images/oma/checkmark.svg);
            position: absolute;
            display: none;
            left: 2px;
            top: -7px;
            color: var(--blue);
        }
    }

    &-label {
        line-height: 20px;
        font-size: 16px;
        line-height: 22px;
        font-family: var(--proxima-nova-light);
    }
}
