﻿.oma-row-content-container {
    .oma-content-tile-block {
        margin-bottom: 0;
    }
}

.oma-content-tile-block {
    margin-bottom: 25px;

    .oma-content-tile-block-container {
        display: flex;
        padding: 20px 15px;
        height: 100%;

        @media only screen and (max-width: 768px) {
            border-bottom: 1px solid #D5D6D6;
            padding: 5px 10px;
            align-items: center;
        }

        .title {
            margin: 0;
            font-weight: 700;

            a, a:active, a:visited, a:focus {
                color: var(--blue);
                font-family: var(--proxima-nova-light);
                font-size: 18px;
                line-height: 24px;
                text-decoration: none;

                &:hover {
                    color: #0056b3;
                    text-decoration: underline;
                }
            }
        }

        .sub-title {
            color: #212121;
            font-family: var(--proxima-nova);
            font-size: 18px;
            line-height: 24px;
            margin-bottom: 5px;
        }

        .caption p {
            color: #212121;
            font-family: var(--proxima-nova-light);
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 0;

            @media only screen and (max-width: 768px) {
                display: none;
            }
        }

        .content-tile-block-image {
            padding-right: 15px;

            img {
                width: 65px;
                height: 65px;
                min-width: 65px;
                min-height: 65px;
                border-radius: 50%;

                @media only screen and (max-width: 768px) {
                    width: 32px;
                    height: 32px;
                    min-width: 32px;
                    min-height: 32px;
                }
            }
        }
    }
}
