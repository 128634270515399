﻿$block-height: 60px;

.oma-cta-news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 15px 20px 20px 20px;
    background-color: var(--pale-cyan);
    text-align: center;

    .oma-cta-block-text {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .oma-cta-title-wrapper {
        padding-bottom: 5px;
    }

    .oma-cta-caption {
        color: var(--black);
        margin: 0;
    }

    .oma-cta-title {
        margin: 0;
        color: var(--blue);
        line-height: 36px;
    }

    .oma-cta-link-wrapper {
        margin-top: 0;
        line-height: 20px;
        padding-left: 10px;

        a,a:active,a:visited,a:focus {
            color: var(--blue);
        }
    }
}

@include media-breakpoint-up(lg) {
    .oma-cta-news {
        height: $block-height;
        flex-direction: row;
        justify-content: left;
        padding: 0;
        background: var(--light-cyan);

        .oma-cta-title-wrapper::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            right: -25px;
            border-left: 25px solid var(--pale-cyan);
            border-top: 30px solid transparent;
            border-bottom: 30px solid transparent;
        }

        .oma-cta-block-text {
            height: 100%;
            width: 70%;
        }

        .oma-cta-title-wrapper {
            position: relative;
            height: 100%;
            width: 30%;
            padding: 0;
        }

        .oma-cta-caption {
            line-height: $block-height;
            padding-left: 45px;
            width: 70%;
            text-align: left;
            overflow: hidden;
        }

        .oma-cta-title {
            background-color: var(--pale-cyan);
            height: 100%;
            margin: 0;
            line-height: $block-height;
            padding: 0 10px;
            text-align: center;
            overflow: hidden;
        }

        .oma-cta-link-wrapper {
            text-align: center;
            padding: 0 20px;
            line-height: $block-height;
            width: 30%;
            overflow: hidden;
        }
    }
}

@include media-breakpoint-down(md) {
    .oma-cta-news .oma-cta-block-text {
        flex-direction: column;
        text-align: center;

        .oma-cta-block-text {
            .oma-cta-caption {
                margin-bottom: 15px;
            }
        }

        .oma-cta-link-wrapper {
            padding-left: 0;
        }
    }
}

.startpage {
    @include media-breakpoint-up(lg) {
        .oma-cta-news {
            background: #232E83;

            .oma-cta-title-wrapper::after {
                border-left: 25px solid #0088CE;
            }

            .oma-cta-title {
                background-color: #0088CE;
                color: white;
            }

            .oma-cta-caption {
                color: white;
            }

            .oma-cta-link-wrapper {
                a, a:active, a:visited, a:focus {
                    color: #00BCE7;
                }
            }
        }
    }
}