﻿.oma-priority-cta-block {
    margin-bottom: $v-margin-between-blocks;
}

.oma-priority-cta-block-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--pale-cyan);
    position: relative;
    padding: 30px 25px 25px 25px;

    @include media-breakpoint-only(xxl) {
        padding: 35px 30px 30px 30px;
    }

    @include media-breakpoint-only(xxxl) {
        padding: 20px 35px 35px 35px;
    }

    .oma-image-container {
        width: 250px;
        max-width: 100%;
        overflow: hidden;
        margin-bottom: 1rem;
        position: relative;

        @include media-breakpoint-only(xxl) {
            width: 290px;
        }

        @include media-breakpoint-only(xxxl) {
            width: 340px;
        }

        .oma-image {
            width: 100%;
            object-fit: contain;

            &-overlay {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }

    .oma-text-container {

        .oma-title {
            font-family: var(--proxima-nova-light);
            color: var(--blue);
            margin-top: 0;
        }

        .oma-subtitle {
            font-size: 18px;
            line-height: 24px;
            font-family: var(--proxima-nova-regular);
        }

        .oma-link-wrapper {
            a {
                font-size: 16px;
                font-family: var(--proxima-nova-semibold);
                line-height: 20px;
            }
        }
    }

    &.flex-row {
        flex-direction: row;
        align-items: center;
        justify-content: stretch;

        .oma-image-container {
            margin-right: 1rem;
            margin-bottom: 0;
            flex-shrink: 0;
        }

        .oma-text-container {
            text-align: left;
            align-self: flex-start;

            .oma-caption {
                text-align: left;
                padding: 0;
                padding-right: 1rem;
            }

            .oma-link-wrapper {
                margin-bottom: 0;
            }
        }
    }
}

.oma-text-container {
    p {
        font-size: 15px;
        line-height: 20px;
    }

    ul {
        font-family: var(--proxima-nova-light);
    }
}