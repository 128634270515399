﻿.oma-search-page {

    em {
        font-weight: bold;
        font-style: normal;
    }

    &__title {
        padding-bottom: 10px;
    }

    &__search-form {
        position: relative;
        margin-bottom: 20px;

        input[type=search] {
            margin-bottom: 20px;
        }
        /* clears the Bootstrap 'X' icon from Internet Explorer */
        input[type=search]::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
        /* clears the Bootstrap 'X' icon from Chrome */
        input[type="search"]::-webkit-search-cancel-button {
            display: none;
        }
    }

    &__search-button {
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        position: absolute;
        right: 10px;
        top: 6px;

        &:focus {
            outline: 0;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: var(--grey);
        }
    }

    &__sort-options-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .sortby-label {
            width: 25%;
            margin: 0;
            line-height: 22px;
        }
    }

    &__filters-title {
        font-size: 28px;
        line-height: 36px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    &__filter {
        margin-bottom: 20px;
    }

    &__filters-subtitle {
        display: flex;
        justify-content: space-between;

        .clear-all-button {
            text-decoration: underline;
        }
    }

    &__selected-filters, &__content-type-filter-options, &__facets-filter-options {
        border-bottom: solid 2px var(--border-grey);
    }

    &__selected-filters, &__filters, &__content-type-filter-options, &__facets-filter-options, &__results {
        list-style-type: none;
        padding: 0;
    }

    &__selected-filters {
        .remove-filter-option {
            display: flex;
            text-decoration: none;
        }

        .icon-wrapper {
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            margin-right: 15px;
        }
    }

    &__results {
        em {
            font-weight: bolder;
        }

        .result {
            min-height: 200px;
            padding: 10px 0 40px;

            &-type-wrapper {
                display: flex;
                margin-bottom: 10px;
            }

            &-type-icon {
                margin-right: 10px;
            }

            &-type {
                margin: 0;
                font-size: 18px;
                line-height: 24px;
            }

            &-title-link-wrapper {
                display: flex;
                width: fit-content;
                position: relative;
            }

            &-title {
                margin-right: 4px;
            }

            &-additional-data {
                display: flex;
                justify-content: space-between;

                @include media-breakpoint-down(xs) {
                    flex-direction: column;
                }

                @include media-breakpoint-between(xs, sm) {
                    flex-direction: column;
                }
            }

            &-link, &-date {
                font-size: 14px;
                line-height: 19px;
            }

            &-link {
                font-family: var(--proxima-nova-semibold);
            }

            &-date {
                font-family: var(--proxima-nova-light);
            }
        }

        .external-link-icon {
            width: 22px;
            height: 22px;
            bottom: 18px;
            position: absolute;
            right: -22px;
            max-width: none;

            &__bestbet {
                position: revert;
            }
        }

        .best-bet-result {
            min-height: 150px;
            padding: 10px 0px;
        }
    }
}
