/* Edit Variables Here */
/* General Styles */
/* General Styles End */
/* Inputs */
/* Inputs End */
/* Outputs */
/* Longevity */
/* Suggested Savings */
/* Target Income */
/* Landscape phone to portrait tablet */
/* .RetirementCalculator__tooltip {
    top: 33px;
  } */
/* Landscape phones and down */
html {
    box-sizing: border-box;
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    [type=button] {
        -webkit-appearance: button;
    }
}

body {
    margin: 0;
}

article {
    display: block;
}

aside {
    display: block;
}

footer {
    display: block;
}

header {
    display: block;
}

nav {
    display: block;
}

section {
    display: block;
}

h1 {
    font-size: 2em;
    margin: .67em 0;
}

figcaption {
    display: block;
}

figure {
    display: block;
    margin: 1em 40px;
}

main {
    display: block;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace,monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b {
    font-weight: inherit;
    font-weight: bolder;
}

strong {
    font-weight: inherit;
    font-weight: bolder;
}

code {
    font-family: monospace,monospace;
    font-size: 1em;
}

kbd {
    font-family: monospace,monospace;
    font-size: 1em;
}

samp {
    font-family: monospace,monospace;
    font-size: 1em;
}

dfn {
    font-style: italic;
}

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    bottom: -.25em;
}

sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
    top: -.5em;
}

audio {
    display: inline-block;

    &:not([controls]) {
        display: none;
        height: 0;
    }
}

video {
    display: inline-block;
}

img {
    border-style: none;
}

svg {
    &:not(:root) {
        overflow: hidden;
    }
}

button {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:-moz-focusring {
        outline: 1px dotted ButtonText;
    }
}

input {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
}

optgroup {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

select {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
}

textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: auto;
}

[type=reset] {
    -webkit-appearance: button;

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:-moz-focusring {
        outline: 1px dotted ButtonText;
    }
}

[type=submit] {
    -webkit-appearance: button;

    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:-moz-focusring {
        outline: 1px dotted ButtonText;
    }
}

[type=button] {
    &::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }

    &:-moz-focusring {
        outline: 1px dotted ButtonText;
    }

    &:-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
    }
}

fieldset {
    padding: .35em .75em .625em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

[type=checkbox] {
    box-sizing: border-box;
    padding: 0;
}

[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

[type=number] {
    &::-webkit-inner-spin-button {
        height: auto;
    }

    &::-webkit-outer-spin-button {
        height: auto;
    }
}

[type=search] {
    -webkit-appearance: textfield;
    outline-offset: -2px;

    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }

    &::-webkit-search-decoration {
        -webkit-appearance: none;
    }
}



details {
    display: block;
}

menu {
    display: block;
}

summary {
    display: list-item;
}

canvas {
    display: inline-block;
}

template {
    display: none;
}

[hidden] {
    display: none;
}

* {
    box-sizing: inherit;

    &:before {
        box-sizing: inherit;
    }

    &:after {
        box-sizing: inherit;
    }
}

:root {
    --calculator-white: #FFFFFF;
    --calculator-black: #212121;
    --calculator-background-white: #FFFFFF;
    --calculator-highlight-blue: #145094;
    --calculator-border-grey: #EBEBEB;
    --calculator-label-grey: #575757;
    --calculator-medium-grey: #D8D8D8;
    --calculator-dark-grey: #AEAEAE;
    --calculator-gold: #FCB53B;
    --calculator-light-green: #9DD0A7;
    --calculator-light-teal: #80C8D0;
    --calculator-teal: #00818F;
    --calculator-font-grey: #575757;
    --calculator-font-tiny: 10px;
    --calculator-font-small: 12px;
    --calculator-font-small-plus: 13px;
    --calculator-font-medium: 16px;
    --calculator-container-padding: 30px;
    --calculator-border-radius: 4px;
    --calculator-general-gutter: 20px;
    --calculator-box-shadow: 0 0px 30px 0 rgba(141, 106, 106, 0.1);
    --calculator-solid-border: 1px solid var(--calculator-medium-grey);
}

.RetirementCalculator {
    position: relative;
    padding: 0px 140px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-family: var(--calculator-font-stack);
    font-size: var(--calculator-font-medium);
    background: var(--calculator-background-white);

    * {
        font-family: var(--calculator-font-stack);
    }

    button {
        &:focus {
            outline: none;
        }
    }
}

.RetirementCalculator__mainContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    background: var(--calculator-background-white);
}

.RetirementCalculator__appHeading {
    width: 100%;
    text-align: center;
    color: #686868;
    font-size: 40px;
}

.RetirementCalculator__appSubheading {
    width: 100%;
    text-align: center;
    color: #686868;
    font-size: 20px;
    margin-bottom: 110px;
    margin-top: 15px;
    padding: 0 25%;
}

.RetirementCalculator__inputsContainer {
    position: relative;
    width: calc((360 / 1160) * 100%);
    background: var(--calculator-white);
    padding: var(--calculator-container-padding);
    display: flex;
    flex-direction: column;
    align-items: center;

    .RetirementCalculator__field {
        padding: var(--calculator-container-padding) 0;

        &:first-of-type {
            padding: 0 0 var(--calculator-container-padding);
        }

        &:last-of-type {
            border-bottom: none;
        }
    }
}

.RetirementCalculator__methodology {
    display: flex;
    align-items: center;
    color: var(--calculator-highlight-blue);
    text-decoration: none;

    &:active {
        display: flex;
        align-items: center;
        color: var(--calculator-highlight-blue);
        text-decoration: none;
    }

    &:focus {
        display: flex;
        align-items: center;
        color: var(--calculator-highlight-blue);
        text-decoration: none;
    }

    &:visited {
        display: flex;
        align-items: center;
        color: var(--calculator-highlight-blue);
        text-decoration: none;
    }
}

.RetirementCalculator__outputsContainer {
    position: relative;
    width: calc((700 / 1160) * 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--calculator-dark-grey);
    background-color: var(--calculator-border-grey);

    .RetirementCalculator__sectionTitle {
        background-color: var(--teal);
        color: var(--calculator-white);
        padding: 13px 15px;
    }

    .RetirementCalculator__field {
        // margin-bottom: var(--calculator-general-gutter);
        //border: 1px solid var(--calculator-border-grey);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    button {
        display: none;
    }
}

.RetirementCalculator__sectionTitle {
    font-weight: bold;
    text-align: left;
    width: 100%;
    position: relative;
    top: 0;

    font-family: var(--proxima-nova-regular);
    color:var(--calculator-black);
}

.RetirementCalculator__field {
    position: relative;
    //background: var(--calculator-white);
    padding: var(--calculator-container-padding);
    border-bottom: 1px solid var(--white);
    width: 100%;

    &:last-of-type {
        border-bottom: none;
    }
}

.RetirementCalculator__label {
    display: block;
    width: 100%;
    font-weight: 500;
    color: var(--calculator-label-grey);

    &[for=input-currentAge], &[for=input-retireAge] {
        display: inline;

        + p.RetirementCalculator__errorMessage {
            position: absolute;
        }
    }

}

.RetirementCalculator__label--large {
    font-size: 18px;
    font-weight: 400;
}

.RetirementCalculator__errorMessage {
    color: var(--calculator-label-grey);
    font-size: var(--calculator-font-small-plus);
    font-style: italic;
    //position:absolute;
}

.RetirementCalculator__numberInputContainer {
    position: relative;
    display: inline-flex;
    align-items: center;
    //width: 100%;

    
}
#input-incomePreTax, #input-retirementSavings {
    width: 100%;
}
.RetirementCalculator__numberInput {
    color: var(--calculator-black);
   
    font-size: 30px;
    font-weight: 700;
    display:inline;
    border: none;
    border-bottom: 2px solid var(--calculator-medium-grey);
    padding: 5px 10px;
    margin: 10px 0;
    min-width: 0;
    

    &:focus {
        outline: none;
    }

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
.tooltip-inner{
    background-color:var(--bright-blue);
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: var(--bright-blue);
}
.RetirementCalculator__numberInput[name=currentAge] {
    text-align: center;
    width: 75px;
}

.RetirementCalculator__numberInput[name=retireAge] {
    border: none;
    text-align: center;
    margin-bottom: 0;
}

.RetirementCalculator__arrow {
    position: relative;
    top: 0px;
    width: 20px;
    height: 20px;
    fill: var(--bright-blue);
    transition: fill 0.2s ease-out;
    left:auto !important;

    &:hover {
        fill: var(--blue);
    }
}

.RetirementCalculator__arrow--left {
    transform: translateY(0);
}

.RetirementCalculator__arrow--right {
    transform: translate(0, 0) scaleX(-1);
}
#input-retireAge{
    max-width:75px;
    margin-top:0;
}
.RetirementCalculator__inputPrefix {
    display: inline-block;
    font-size: var(--calculator-font-medium);
    color: var(--calculator-font-grey);
    white-space: nowrap;
    margin-right: 16px;
}

.RetirementCalculator__inputSuffix {
    display: inline-block;
    font-size: var(--calculator-font-medium);
    color: var(--calculator-font-grey);
    white-space: nowrap;
    margin-right: 16px;
    margin:0;
}

.RetirementCalculator__perTerm {
    display: inline-block;
    font-size: var(--calculator-font-medium);
    color: var(--calculator-font-grey);
    white-space: nowrap;
    margin-right: 16px;
}

.RetirementCalculator__fieldset {
    padding: 40px 0 10px;
    display: flex;
    
    flex-direction: column;
}

.RetirementCalculator__radioInputContainer {
    display: inline-flex;
    
}

.RetirementCalculator__radioInput {
    position: relative;
    z-index: 1;
    width: 20px;
    height: 20px;
    overflow: visible;
    margin-right: 8px;

    

    &:checked {
        &:before {
            border: 2px solid #000;
        }

        &:after {
            opacity: 100;
        }
    }
}

.RetirementCalculator__button {
    background: var(--calculator-highlight-blue);
    color: var(--calculator-white);
    border-radius: 25px;
    padding: 11px 20px;
    border: 2px solid var(--calculator-highlight-blue);
    transition: border 0.3s ease-out, color 0.3s ease-out, background 0.3s ease-out;

    &:disabled {
        background: var(--calculator-white);
        color: var(--calculator-medium-grey);
        border-color: var(--calculator-medium-grey);
    }
}

.RetirementCalculator__field.RetirementCalculator__field--longevity {
    margin-bottom: 0;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
    margin-bottom: 0;
    border-bottom: none;
    border-radius: 4px 4px 0 0;
}

.RetirementCalculator__longevityProbability {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

.RetirementCalculator__description--longevity {
    font-size: 14px;
    margin-top: 10px;
}

.RetirementCalculator__probabilityLineContainer {
    display: flex;
    width: 100%;
}

.RetirementCalculator__longevityAgeContainer {
    display: flex;
    width: 100%;
}

.RetirementCalculator__subLabel {
    width: 25%;
    display: flex;
    align-items: center;
}

.RetirementCalculator__longevityAges {
    position: relative;
    display: flex;
    width: 75%;
    justify-content: space-around;
    .RetirementCalculator__longevityAge{
        font-size:18px;
    }
}

.RetirementCalculator__probabilityLine {
    position: relative;
    display: flex;
    width: 75%;
    justify-content: space-around;
}

.RetirementCalculator__strikeThrough {
    position: absolute;
    top: 50%;
    left: 0;
    height: 2px;
    width: 100%;
    transform: translateY(-50%);
    background: var(--calculator-medium-grey);
}

.RetirementCalculator__longevityAge {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 64px;
    border:0px solid transparent;
    border-radius:10px;
    font-size: 1.375em;
    background-color:var(--calculator-background-white);
    font-weight: bold;
    margin-top:1em;
}

.RetirementCalculator__probabilityPercent {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    width: 64px;
    background: var(--teal);
    color:var(--calculator-white);
    border: 6px solid var(--teal);
    border-radius: 50%;
    font-size:1.375em;
    
}

.RetirementCalculator__probabilityPercent--75 {
    visibility: hidden;
}

.RetirementCalculator__longevityAge--75 {
    visibility: hidden;
}

.RetirementCalculator__probabilityPercent--active {
    border-color: var(--calculator-gold);
    transform: scale(1.15);
    transition: border-color 0.3s ease-out, transform 0.3s ease-out;
}



.RetirementCalculator__suggestedSavingsContainer {
    display: flex;
    justify-content: space-between;

    .RetirementCalculator__perTerm {
        margin-right: 0;
    }

    .RetirementCalculator__label {
        width: auto;
        display: flex;
        align-items: center;

        
    }
}

.RetirementCalculator__suggestedSavings {
    display: flex;
    justify-content: flex-end;

    .RetirementCalculator__perTerm {
        display: flex;
        align-items: center;
    }
}

.RetirementCalculator__suggestedSavingsPrefix {
    font-size: 30px;
    margin-right: 10px;
}

.RetirementCalculator__suggestedSavingsValue {
    font-size: 30px;
    margin-right: 10px;
    background-color: #ffffff;
    padding: 0 30px;
    border-radius: 10px;
}

.RetirementCalculator__monthlyIncomeContainer {
    .RetirementCalculator__label {
        margin-bottom: var(--calculator-general-gutter);
    }
}

.RetirementCalculator__monthlyIncomeAmount {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 30px;
    width: 100%;
    padding-bottom: 10px;

    .RetirementCalculator__perTerm {
        margin-left: 5px;
    }

    .RetirementCalculator__label.RetirementCalculator__perTerm {
        position: relative;
        margin: var(--calculator-general-gutter) 0;
        white-space: pre-line;
        width: 100%;
        flex-basis: 100%;
    }
}

.RetirementCalculator__columnChartPosition {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.RetirementCalculator__columnChartContainer {
    width: 27.14285714%;
    min-height: 360px;
    background: var(--calculator-medium-grey);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RetirementCalculator__incomePortionContainer {
    position: relative;
    width: 100%;
    height: 33%;
    min-height: 50px;
    transition: opacity 0.2s ease-out;

    &:first-child {
        .RetirementCalculator__dottedLineSeperator {
            display: none;
        }
    }
}

.RetirementCalculator__incomePortionContainer--inactive {
    opacity: 0;
    transition: opacity 0.3s ease-in;
}

.RetirementCalculator__incomePortionContainer--active {
    opacity: 100;
    transition: opacity 0.3s ease-out;
}

.RetirementCalculator__incomePortion {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.RetirementCalculator__incomePortion--top {
    background: var(--calculator-gold);
    transition: height 0.3s ease-out;
    color: #fff;
}

.RetirementCalculator__incomePortion--middle {
    background: var(--teal);
    .RetirementCalculator__incomePortionValue {
        color:var(--calculator-white);}
    transition: height 0.3s ease-out;
}

.RetirementCalculator__incomePortion--bottom {
    background: var(--calculator-light-teal);
    transition: height 0.3s ease-out;
    color: #fff;
}

.RetirementCalculator__incomePortionValue {
    margin: 0;
}

.RetirementCalculator__dottedLineSeperator {
    position: absolute;
    top: 1px;
    left: -75%;
    width: 150%;
    transform: translateX(-50%);
    margin: 0;
    position: absolute;
    //z-index: 2;
    height: 2px;
    //background: #fff;
    left: -150%;
    transform: translateX(0);
    width: 250%;
    transform-origin: right;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 1.5px;
        z-index: 2;
        height: 4px;
        border-bottom: 1px dashed #868D8D;
    }
}

.RetirementCalculator__incomePortionText {
    position: absolute;
    top: 0;
    left: -75%;
    width: 150%;
    transform: translateX(-50%);
    margin: 0;
    padding-top: 10px;
}

.RetirementCalculator__disclaimer {
    font-style: italic;
    border-top: 1px solid var(--calculator-medium-grey);
    padding: var(--calculator-container-padding) 0 0;
    font-size: var(--calculator-font-medium);
    line-height: 1.33;
    color: #686868;
}

.RetirementCalculator__tooltip {
    background: #fff;
    color: var(--bright-blue);
    border: 2px solid var(--bright-blue);
    position: relative;
    width: 20px;
    height: 20px;
    font-size: var(--calculator-font-small);
    display: inline-block;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding-left: 6px;
    right: 0;
    cursor: pointer;
    font-family:serif;
    font-weight:bold;
    float:right;
}

.RetirementCalculator__tooltip--relative {
    position: relative;
    top: auto;
    left: auto;
}

.RetirementCalculator__field--longevity {
    .RetirementCalculator__tooltip {
        right: auto;
        //left: 30%;
    }
}

.ui-tooltip {
    position: absolute;
    z-index: 100;
    max-width: 85%;
    width: 30vmin;
    background: white;
    padding: 20px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    box-shadow: var(--calculator-box-shadow);
    font-family: var(--calculator-font-stack);
    font-size: var(--calculator-font-medium);
    line-height: calc(18/16 * 1em);
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    background: #000;
}

.RetirementCalculator__methodologyContainer {
    color: var(--calculator-highlight-blue);
    margin-top: var(--calculator-general-gutter);

    .RetirementCalculator__tooltip {
        margin-right: 10px;
    }
}

.RetirementCalculator__methodologyText {
    font-size: var(--calculator-font-medium);
}

.ui-dialog {
    position: absolute;
    z-index: 3;
    background: white;
    border: 1px solid #d8d8d8;
    border-color: #d8d8d8;
    border-radius: 4px;
    padding: 20px;
    font-family: var(--calculator-font-stack);
    color: var(--calculator-font-grey);
    line-height: 1.25em;
    box-shadow: 0 0 2000px 2000px rgba(0,0,0,0.6);
    margin-bottom: var(--calculator-general-gutter);

    &:focus {
        outline: none;
    }
}

.ui-dialog-titlebar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--calculator-general-gutter);
}

.ui-button-icon-only {
    background: none;
    border: none;
    outline: none;
    color: var(--calculator-font-grey);
    font-size: 20px;
    transition: color 0.3s ease-out;

    &:focus {
        color: var(--calculator-highlight-blue);
    }

    &:active {
        color: var(--calculator-highlight-blue);
    }

    &:hover {
        color: var(--calculator-highlight-blue);
    }
}

.RetirementCalculator__legend {
    display: none;
}

@media (max-width: 1024px) {
    .RetirementCalculator {
        padding: 40px;
    }

    .RetirementCalculator__appSubheading {
        padding: 0 15%;
    }

    .RetirementCalculator__inputsContainer {
        width: calc((294 / 690) * 100%);
        padding: 20px;
    }

    .RetirementCalculator__numberInput {
        max-width: 80%;
        margin: 0;
        padding: 0;
        font-size: 40px;
        margin-right: 20px;
    }

    .RetirementCalculator__outputsContainer {
        width: calc((353 / 690) * 100%);
    }

    .RetirementCalculator__label {
        font-size: var(--calculator-font-medium);
        max-width: 90%;
        margin-bottom: var(--calculator-general-gutter);
    }

    .RetirementCalculator__fieldset {
        flex-wrap: wrap;
        padding: 20px;
        padding-bottom: 0;
        padding-left: 0;
    }

    .RetirementCalculator__radioInputContainer {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .RetirementCalculator__suggestedSavings {
        width: 100%;
    }

    .RetirementCalculator__suggestedSavingsContainer {
        flex-wrap: wrap;
    }
    .RetirementCalculator__incomePortion--middle {
        

        .RetirementCalculator__incomePortionValue {
            color: var(--calculator-black);
        }


    }

    .RetirementCalculator__subLabel {
        font-size: var(--calculator-font-small-plus);
        width: 35%;
    }

    .RetirementCalculator__probabilityPercent {
        width: 40px;
        height: 40px;
        font-size: 1.125em;
    }

    .RetirementCalculator__longevityAge {
        width: 40px;
        height: 40px;
        font-size: 1.125em;
    }

    .RetirementCalculator__legend {
        display: block;
        margin: 15px 0;
    }

    .RetirementCalculator__legendItem {
        display: flex;
        font-size: var(--calculator-font-small-plus);
    }

    .RetirementCalculator__legendMark {
        width: 12px;
        height: 12px;
        //margin-right: 12px;
        margin-bottom: 12px;
        background: silver;

        span {
            white-space: nowrap;
            margin-left: 24px;
            top: 0px;
        }
    }

    .RetirementCalculator__legendMark--top {
        background: var(--calculator-gold);
    }

    .RetirementCalculator__legendMark--middle {
        background: var(--teal);
    }

    .RetirementCalculator__legendMark--bottom {
        background: var(--calculator-light-teal);
    }

    .RetirementCalculator__incomePortionText {
        display: none;
    }

    .RetirementCalculator__incomePortionValue {
        position: absolute;
        right: 0;
        white-space: nowrap;
        width: 100%;
        transform: translateX(-200%);
        color: #000;
    }

    .RetirementCalculator__dottedLineSeperator {
        position: absolute;
        left: 0;
        width: 100%;

        &:before {
            width: 370%;
            left: -270%;
        }
    }

    .RetirementCalculator__field--longevity {
        .RetirementCalculator__tooltip {
            left: 50%;
        }
    }

  
}

@media (max-width: 667px) {
    .RetirementCalculator {
        width: 100%;
        position: relative;
        flex-wrap: wrap;
        padding: 16px;
    }

    .RetirementCalculator__appSubheading {
        padding: 0;
        white-space: pre-line;
        margin-bottom: 24px;
        line-height: 1.34;
    }

    .RetirementCalculator__mainContainer {
        display: block;
    }

    .RetirementCalculator__inputsContainer {
        width: 100%;
    }

    .RetirementCalculator__outputsContainer {
        width: 100%;

        .RetirementCalculator__sectionTitle {
            margin: 30px 0 10px;
            background: var(--calculator-teal);
            color: #ffffff;
        }

        button {
            display: block;
            width: 50%;
            margin: 20px auto;
        }
    }

    .RetirementCalculator__appHeading {
        padding: 15px 0;
    }

    .RetirementCalculator__sectionTitle {
        transform: initial;
        position: relative;
        margin: 10px 0;
    }

    .RetirementCalculator__longevityProbability {
        justify-content: space-around;
        align-items: center;
    }

    #input-retireAge {
        margin-top: 10px;
    }

    .RetirementCalculator__numberInputContainer {
        align-items: baseline;
        width: 100%;
    }

    .RetirementCalculator__inputSuffix {
        padding: 0;
        margin: 0;
    }

    .RetirementCalculator__probabilityLineContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
    }

    .RetirementCalculator__probabilityLine {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        padding: var(--calculator-general-gutter) 0;
    }

    .RetirementCalculator__longevityAgeContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
    }

    .RetirementCalculator__longevityAges {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        padding: var(--calculator-general-gutter) 0;
    }

    .RetirementCalculator__subLabel {
        width: 100%;
        margin-bottom: 22px;
        justify-content: center;
    }

    .RetirementCalculator__probabilityPercent {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .RetirementCalculator__longevityAge {
        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    .RetirementCalculator__strikeThrough {
        top: auto;
        left: 50%;
        bottom: 0;
        height: 100%;
        width: 2px;
        transform: translateX(-50%);
    }

    .ui-tooltip {
        width: 100%;
        transform: translateX(-50%);
    }

    .RetirementCalculator__field--longevity {
        .RetirementCalculator__tooltip {
            left: 75%;
        }
    }
    .RetirementCalculator__incomePortion--middle {


        .RetirementCalculator__incomePortionValue {
            color: var(--calculator-black);
        }
    }
}
