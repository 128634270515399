.oma-related-discounts {
    display: flex;
    flex-direction: row;
    margin-bottom: $v-margin-between-blocks;
    width: 100%; 

    @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-bottom: 0;
    }
}

.oma-related-discount {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
        flex-direction: row;
        align-items: center;
        margin-bottom: $v-margin-between-blocks;
    }

    &__image-with-flag-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__image {
        margin-bottom: 23px;
        display: flex;
        justify-content: center;
        height: 190px;
        width: 100%;

        @include media-breakpoint-down(sm) {
            flex: 0 0 33%;
            margin-bottom: 0;
        }
    }

    &__link {
        text-decoration: none !important;

        @include media-breakpoint-down(sm) {
            padding-left: 20px;
        }
    }

    &__header {
        text-align: center;
        color: var(--blue);

        @include media-breakpoint-down(sm) {
            font-size: 24px;
            line-height: 1.2;
            text-align: left;
        }
    }

    &__caption {
        text-align: center;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
