﻿.contact-an-advisor-row {
    justify-content: center;
    margin-bottom: 30px !important;
    margin: 0px;
}
.contact-an-advisor {
    position: relative;
    background-color: #EAF6F6;
    margin: 0px;
    width:100%;

    & > .contact-an-advisor-container {
        text-align: center;
        padding: 25px;
        z-index: 2;
        position: relative;

        & > h3 {
            margin-top: -15px;

            &.small-title {
                font-size: 18px;
                line-height: 24px;
                font-family: var(--proxima-nova-regular);
            }
            &.bigger-bottom-margin{
                margin-bottom:1rem;
            }
        }

        & > p {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
        }
    }



    & > .contact-an-advisor-icon {
        border-radius: 100%;
        background-color: #EAF6F6;
        position: relative;
        left: 50%;
        transform: translate(-50%, -38%);
        top: 0;
        padding: 20px;
        height: 100px;
        width: 100px;
        margin-bottom: -70px;
        padding-bottom: 0;
    }
}

.contact-an-advisor :first-child {
    & > h3 {
        padding-top: 10px;
    }
}
.margin-style{
    margin-top: 40px !important;
}
.OMF {
    .contact-an-advisor {
        background-color: var(--omf-green);

        & > .contact-an-advisor-icon {
            background-color: var(--omf-green);
        }
    }
}
.OMA {
    .contact-an-advisor {
        background-color: var(--pale-cyan);

        & > .contact-an-advisor-icon {
            background-color: var(--pale-cyan);
        }
    }
}
