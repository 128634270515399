﻿.oma-candidate-list {
        margin-top: 20px;

        .oma-candidate-list-title {
            font-size: 36px;
            font-family: var(--proxima-nova-light)
        }

        .oma-candidate-list-description {
            p {
                margin: 0;
                margin-bottom: 1rem;
            }

            li {
                font-family: var(--proxima-nova-light);
                margin-bottom: 1rem;
            }

            li strong, li b {
                font-family: var(--proxima-nova-regular);
            }
        }

        .oma-candidate-list-container {
            .oma-candidate-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }
            }
        }
    }

    .candidate-profile {
        max-width: 100%;
        min-width: 398px;
        width: 48%;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        @include media-breakpoint-down(lg) {
            min-width: 200px;
        }
    }



.oma-candidate-tile {
    border: 1px solid #D5D6D6;
    border-radius: 2px;
    margin: 15px 25px 15px 0;
    min-width: 398px;
    max-width: 100%;
    height: 119px;
    background: #fff;
    padding: 15px;
    width: 100%;
    display: flex;

    &[data-nomodal="False"] {
        cursor: pointer;

        &:hover {
            border-color: var(--blue);
        }
    }

    .oma-candidate-tile-image {
        padding-right: 15px;

        img {
            width: 80px;
            height: 80px;
            min-width: 80px;
            min-height: 80px;
            border-radius: 50%;
        }
    }

    @include media-breakpoint-down(lg) {
        min-width: 200px;
        height: 140px;
    }

    @include media-breakpoint-down(xl) {
        margin: 10px 10px 10px 0;
    }

    @include media-breakpoint-down(sm) {
        height: auto;
    }

    .candidate-name {
        color: var(--blue);
        font-family: var(--proxima-nova-light);
        font-size: 1.875rem;
        line-height: 2.25rem;
        margin-top: 0;

        @include media-breakpoint-down(sm) {
            font-size: 1.875rem;
        }

        @include media-breakpoint-down(lg) {
            font-size: 1.5rem;
        }

        img {
            width: 26px;
            margin-left: 4px;
            padding-bottom: 4px;
        }
    }

    .candidate-title {
        font-size: 1.125rem;
        font-family: var(--proxima-nova-regular);
        line-height: normal;
        margin-top: 0;

        @include media-breakpoint-down(sm) {
            font-size: 1.125rem;
        }

        @include media-breakpoint-down(lg) {
            font-size: 1rem;
        }

        img {
            margin-left: 4px;
        }
    }

    .candidate-description {
        font-family: var(--proxima-nova-semibold);
        font-size: 1rem;
        padding-top: 0.625rem;
    }
}

    .oma-candidate-block-modal-container {
        display: none;
        position: fixed;
        z-index: 2500;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.6);
        cursor: auto;
    }

    .oma-candidate-block-modal-container.active {
        display: block;
    }

.oma-candidate-block-modal {
    max-width: 950px;
    padding: 24px;
    background: #fff;
    margin: 5% auto;
    height: 80vh;
    width: 80vw;
    max-width: 730px;
    overflow-y: scroll;
    border: 2px solid var(--bright-blue);

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--border-grey);

        &:hover {
            background: var(--grey);
        }
    }

    .top-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .close-button {
            cursor: pointer;
            background: url(/Static/images/oma/header/close-icon.png);
            width: 20px;
            height: 20px;
        }
    }

    .candidate-header-modal {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
            text-align: center;
        }

        .oma-candidate-tile-image {
            padding-right: 15px;

            @include media-breakpoint-down(sm) {
                padding-right: 0px;
                margin-bottom: 2em;
            }

            img {
                width: 145px;
                height: 145px;
                min-width: 145px;
                min-height: 145px;
                border-radius: 50%;
            }
        }
    }

    .modal-navigation {
        display: flex;
        justify-content: space-evenly;
        flex-grow: 1;
        line-height: 2.5rem;
        color: var(--blue);
        font-family: var(--proxima-nova-semibold);
        font-size: 1rem;
        flex-direction: row;

        .modal-previous-button {
            cursor: pointer;
        }

        .modal-next-button {
            cursor: pointer;
        }

        .previous-arrow {
            width: 10px;
            height: 10px;
            border-top: 3px solid var(--blue);
            border-left: 3px solid var(--blue);
            display: inline-block;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
            margin-right: 10px;
        }

        .next-arrow {
            width: 10px;
            height: 10px;
            border-top: 3px solid var(--blue);
            border-left: 3px solid var(--blue);
            display: inline-block;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            transform: rotate(135deg);
            margin-left: 10px;
        }
    }

    .candidate-name-modal {
        font-family: var(--proxima-nova-light);
        margin-bottom: 20px;
        font-size: 2.25rem;
        line-height: 2.5rem;
        color: var(--black);

        img {
            margin-left: 4px;
            padding-bottom: 4px;
        }
    }

    .candidate-title-modal {
        font-size: 1.125rem;
        font-family: var(--proxima-nova-regular);
        line-height: 1.5rem;
        color: var(--black);
    }

    .candidate-election-modal {
        font-size: 0.875rem;
    }

    .candidate-file {
        a, a:active, a:visited, a:focus {
            font-family: var(--proxima-nova-semibold);
            font-size: 1rem;
            color: var(--blue);
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

            img {
                max-width: 20px;
                max-height: 20px;
            }
        }
    }

    .candidate-video {
        width: 90%;
        height: 357px;
        margin: 10px auto;

        @include media-breakpoint-down(md) {
            height: 257px;
            width: 100%;
        }

        iframe {
            width: 100%;
            height: 100%;
        }
    }

    .candidate-content-modal {
        margin-top: 20px;

        h2, h3, ul {
            font-family: var(--proxima-nova-light);
        }
    }
}
