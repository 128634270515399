﻿.oma-two-column-left-nav {

    .main-content {

        .page-title {
            color: var(--dark-blue);
            font-size: 40px;
            line-height: 50px;
        }

        .page-description {
            font-family: var(--proxima-nova);
            color: var(--dark-blue);
            font-family: var(--proxima-nova);
            font-size: 28px;
            line-height: 36px;
        }
    }
}
