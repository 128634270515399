﻿.oma-image-block {
    margin-bottom: $v-big-margin-between-blocks;
    width: 100%;

    .oma-image-block-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        margin: 0 auto;

        .oma-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc(75%);
            overflow: hidden;
            margin-bottom: 10px;

            .oma-image {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .oma-image-block-text {
            flex-direction: row;
            width: calc(75%);
            text-align: left;
            font-size: 14px;
            line-height: 18px;
            padding-bottom: 10px;
            font-family: var(--proxima-nova-light);
            color: var(--black);

            .oma-image-link {
                text-decoration: underline;
                color: var(--blue);
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .oma-image-block > .oma-image-block-content > .oma-image-container {
        width: 100%;
    }

    .oma-image-block > .oma-image-block-content > .oma-image-block-text {
        width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .oma-image-block > .oma-image-block-content > .oma-image-container {
        height: auto;
    }
}

.oma-image-block.banner-mode {
    .oma-image-container {
        width: 100%;
        overflow: hidden;
        & .oma-image{
            height:auto;
        }
        @include media-breakpoint-down(sm) {
            max-height: 200px;
        }

        @include media-breakpoint-only(md) {
            max-height: 250px;
        }

        @include media-breakpoint-only(xxl) {
            
        }

        @include media-breakpoint-only(xxxl) {
            
        }
    }

    .oma-image-block-text {
        width: 100%;
        height: auto;
        overflow: auto;
    }
}