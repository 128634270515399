﻿.oma-paging {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    .pages {
        margin: 0 1rem;

        .page {
            display: inline-flex;
            min-width: 27px;
            min-height: 27px;
            justify-content:center;
            align-items:center;
        }

        .page.active {
            border: 2px solid var(--grey);
            border-radius: 50%;
        }
    }

    .oma-prev-page-link {
        &-icon {
            width: 10px;
            height: 12px;
            background: url(/Static/Images/back_arrow.svg) no-repeat center;
            background-size: contain;
        }
    }

    .oma-next-page-link {
        &-icon {
            width: 10px;
            height: 12px;
            background: url(/Static/Images/forward_arrow.svg) no-repeat center;
            background-size: contain;
        }
    }
}
