.oma-profile-tile {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    margin: 15px 25px 15px 0;
    width: 429px;
    height: 126px;
    background: #fff;
    display: flex;
    padding: 15px;
    cursor: pointer;

    

    .profile-picture {
        height: 92px;
        flex-basis: 92px;
        flex-grow: 0;
        flex-shrink: 0;

        img {
            max-width: 100%;
            max-height: 100%;
            border-radius: 50%;
        }
    }

    .profile-name-title {
        margin-left: 23px;
        overflow: hidden;

        .name {
            font-size: 24px;
            font-family: var(--proxima-nova-semibold);
            color: #14284b;
            font-weight: 600;
            margin: 0;
        }

        .title {
            font-size: 14px;
            font-family: var(--proxima-nova-semibold);
            font-weight: 600;
            color: #63666a;
            border: #707070;
            margin-top: 5px;
        }
    }
}

.oma-profile-block-modal-container {
    display: none;
    position: fixed;
    z-index: 2500;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    cursor: auto;
}

.oma-profile-block-modal-container.active {
    display: block;
}

.oma-profile-block-modal {
    max-width: 950px;
    padding: 24px;
    background: #fff;
    border-radius: 5px;
    margin: 5% auto;
    max-height: 80vh;
    width:80vw;
    max-width:950px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: #00859b;
        border-radius: 5px;

        &:hover {
            background: var(--dark-blue);
        }
    }

    .oma-profile-block-modal-content {
        display: flex;

        .profile-picture {
            min-width: 120px;

            img {
                width: 92px;
                height: 92px;
                border-radius: 50%;
            }
        }

        .profile-detail {
            .name {
                color: #14284b;
                font-size: 24px;
                font-family: var(--proxima-nova-semibold);
                margin: 0;
            }

            .title {
                color: #14284b;
                font-size: 14px;
                font-family: var(--proxima-nova-semibold);
            }

            .desc {
                color: #14284b;
                font-size: 14px;
            }

            .content, .content p, .content p span {
                color: #14284b;
                font-size: 16px;
                line-height: 28px;
                font-family: var(--proxima-nova-regular);
            }

            .award-detail {
                background: rgba(227,227,227,.3);
                border: #707070;
                border-radius: 5PX;
                padding: 15px;
                display: flex;

                .award-icon {
                    min-width: 60px;
                    margin-right: 20px;

                    img {
                        width: 55px;
                        height: 60px;
                        border-radius: 50%;
                    }
                }

                .award-name {
                    font-size: 18px;
                    color: #14284b;
                    padding-bottom: 10px;
                    font-family: var(--proxima-nova-regular);
                    margin: 0;
                }

                .award-desc {
                    margin: 0;
                }
            }
        }
    }

    .close-button-container {
        text-align: center;

        button {
            width: 124px;
            height: 34px;
            background-color: #00859b;
            border-radius: 5px;
            border-color: #fff;
            color: #fff;
            border: 0;
        }
    }
}

@media only screen and (max-width: 720px) {
    .oma-profile-block-modal{
        height: 90%;
        overflow-y:unset;

        .oma-profile-block-modal-content {
            height: 90%;
            margin-bottom: 20px;

            .profile-detail {
                overflow-y: scroll;
            }
        }
    }
}
