﻿.oma-ctas-container {
    margin-bottom: $v-margin-between-blocks;

    @include media-breakpoint-down(md) {
        display: flex;
        justify-content: space-between;
    }

    @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
    }

    .oma-priority-cta-block.small {
        margin-bottom: 20px;

        .oma-text-container {
            max-height: 120px;
            overflow: hidden;
            padding: 20px 15px;
        }

        .oma-image-container {
            max-width: 90px;
            min-width: 90px;
            height: 120px;
        }
    }

    .oma-priority-cta-block.small:last-child {
        margin-bottom: 0;
    }
}
