﻿.oma-left-nav {
    width: 100%;
}

.oma-left-nav-container {
    padding-top: 4px;

    .parent-link {
        padding: 0 15px;
        font-size: 1.5rem;
        line-height: 1.75rem;
        color: var(--black);
        font-family: var(--proxima-nova-regular);

        a, a:active, a:visited, align-content:focus {
            color: var(--black);
            font-size: 1.75rem;
            line-height: 1.5em;
            font-family: var(--proxima-nova-regular);
        }
    }

    .nav-list, .sub-items {
        list-style: none;
    }

    .nav-list {
        padding: 0;

        .main-item, .sub-item {
            display: flex;
            align-items: flex-start;
            justify-content: center;

            .nav-item-link {
                display: block;
                width: 90%;
                padding: 10px 15px;
                color: var(--black);
                font-size: 0.875rem;
                line-height: 1.5em;
                font-family: var(--proxima-nova-light);
            }
        }

        .main-item {
            flex-direction: column;
            position: relative;

            .main-item-link-wrapper:hover {
                color: var(--blue);
                background: var(--pale-cyan);
            }

            .main-item-link-wrapper {
                display: flex;
                width: 100%;
                align-items: center;

                .main-item-expand-button {
                    padding: 0;
                    border: none;
                    font: inherit;
                    color: inherit;
                    background-color: transparent;
                    position: absolute;
                    right: 10px;

                    &:focus {
                        outline: 0;
                    }

                    svg {
                        width: 20px;
                        height: 20px;
                        fill: var(--grey);
                    }
                }
            }

            .sub-items {
                display: none;
            }

            &.active {
                .main-item-link-wrapper {
                    background: var(--pale-cyan);

                    .nav-item-link {
                        color: var(--blue);
                        text-decoration: underline;
                        text-decoration-color: var(--blue);
                    }
                }
            }

            .bi-plus {
                display: block;
            }

            .bi-dash {
                display: none;
            }

            &.expanded {

                .main-item-link-wrapper {
                    text-decoration: underline;
                    text-decoration-color: var(--blue);
                }

                .bi-plus {
                    display: none;
                }

                .bi-dash {
                    display: block;
                }

                .sub-items {
                    display: block;
                    width: 100%;
                    padding: 0;

                    .sub-item {

                        &:hover {
                            color: var(--blue);
                            background: var(--pale-cyan);
                        }

                        .nav-item-link {
                            padding: 10px 15px;
                        }

                        &.active {
                            background: var(--pale-cyan);

                            .nav-item-link {
                                color: var(--blue);
                                text-decoration: underline;
                                text-decoration-color: var(--blue);
                            }
                        }
                    }

                    .sub-item.active {
                        background: var(--pale-cyan);

                        .nav-item-link {
                            color: var(--blue);
                            text-decoration: underline;
                            text-decoration-color: var(--blue);
                        }
                    }
                }
            }
        }
    }
}
