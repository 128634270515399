﻿.captioned-video-container {
    display: flex;
    flex-direction: column;
    min-width: 350px;
    min-height: 300px;
    margin-right: 25px;

    & > .video {
        width: 100%;
        overflow: hidden;
        position: relative;
        padding-bottom: 56.25%;
        height: 0;

        & > * {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            border:none;
        }
    }

    & > .caption {
        padding: 10px 0 0 0;
    }
}

@media (max-width: 1599.98px) and (min-width: 720px){
    .oma-priority-cta-block-content {
        & > .captioned-video-container {
/*            margin-left: -30px;
            margin-top: -35px;*/
            margin-right: 30px;
            width: 320px;
            min-width:320px;
            & > .video {
                & iframe {
                }
            }
            & > .caption{
                /*padding:10px;*/
            }
        }
    }
}
