﻿.oma-cta-block-content.oma-cta-with-white-background {
    background-color: var(--light-grey);
}

.oma-cta-with-white-background .oma-cta-block-text {
    text-align: left;
}

    .oma-cta-with-white-background .oma-cta-block-text .oma-cta-title,
    .oma-cta-with-white-background .oma-cta-block-text .oma-cta-caption {
        font-family: var(--proxima-nova-light);
        color: var(--black);
    }

    .oma-cta-with-white-background .oma-cta-block-text a, .oma-cta-with-white-background .oma-cta-block-text a:active,
    .oma-cta-with-white-background .oma-cta-block-text a:visited, .oma-cta-with-white-background .oma-cta-block-text a:focus{
    font-family: var(--proxima-nova-semibold);
    color: var(--blue);
}
