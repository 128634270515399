﻿.video-collection-block {
    border-radius: 4px;
    margin-bottom: $v-big-margin-between-blocks;
    min-height: 720px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .intro {
        background: #fff;
        border-radius: 4px;
        color: #14284b;
        font-size: 16px;
        line-height: 28px;
        padding: 20px;
        margin-bottom: 20px;
    }

    .video-collection-block-content {
        display: flex;
        flex-direction: row;

        .video-content {
            width: 913px;
            max-width: 100%;
            background: #fff;
            min-height: 465px;
            flex-shrink: 0;

            @include media-breakpoint-down(xxl) {
                width: 770px;
            }

            @include media-breakpoint-down(lg) {
                display: none;
            }

            .video-container {
                width: 100%;
                min-height: 465px;
            }

            .story {
                color: #14284b;
                font-size: 16px;
                padding: 30px 80px 0 10px;
                line-height: 26px;

                .story-text-container {
                    padding-left: 1em;
                    display: flex;
                    font-size: 16px;
                    line-height: 26px;
                    flex-direction: column;
                    color: #14284b;

                    .title {
                        font-size: 24px;
                        line-height: 24px;
                        margin-bottom: 15px;
                        font-family: var(--proxima-nova-light);
                        font-weight: 400;
                        margin-top: 5px;
                        color: #14284b;

                        &:focus {
                            outline: none;
                        }
                    }

                    .subtitle, .summary {
                        font-size: 16px;
                        line-height: 26px;
                        font-family: var(--proxima-nova-regular);
                    }

                    .subtitle {
                        color: #63666a;
                    }

                    .summary {
                        margin-bottom: 20px;
                    }
                }
            }
        }

        .meet-your-doctors {
            background: #63666a;
            max-width: 700px;
            min-height: 100%;
            max-height: 750px;
            color: #fff;
            margin-bottom: auto;
            width: 100%;

            @include media-breakpoint-down(lg) {
                max-width: 531px;
                max-height: unset;
                margin: auto;
            }

            .title {
                font-size: 22px;
                line-height: 36px;
                padding: 10px;
                border-bottom: 2px solid rgba(227,227,227,.4);
                margin-bottom: 0;
                font-family: var(--proxima-nova-regular);
                font-weight: 700;
                background-image: linear-gradient(#12284a,#124367);
            }

            .doctors {

                &.full-story {
                    overflow-y: hidden;
                }

                overflow-y: auto;
                max-height: 605px;
                background-color: #63666a;

                &::-webkit-scrollbar {
                    width: 12px;
                    background-color: #63666a;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    box-shadow: inset 0 0 6px #0000004d;
                    -webkit-box-shadow: inset 0 0 6px #0000004d;
                    background-color: #555;
                }

                @include media-breakpoint-down(lg) {
                    max-height: unset;
                    overflow-y: unset;
                } 
                
                .doctors-item {
                    display: flex;
                    margin-top: 0;
                    border-bottom: 1px solid #767676;
                    padding: 0;
                    width: 100%;
                    cursor: pointer;

                    .content {
                        background-image: url(https://content.oma.org/wp-content/uploads/full-story.png);
                        padding-left: 250px;
                        padding-right: 5px;
                        height: 148px;
                        width: 100%;
                        background-repeat: no-repeat;
                        cursor: pointer;
                        min-width: 244px;
                        background-size: cover;

                        @include media-breakpoint-down(sm) {
                            height: 114px;
                            min-width: auto;
                            padding-left: 50%;
                        }

                        @include media-breakpoint-only(xl) {
                            padding-left: 150px;
                        }

                        .youtube-icon {
                            border-radius: 0;
                            border: 0 solid transparent;
                            float: left;
                            margin-left: -50px;
                            margin-top: 2em;
                            width: auto;
                            height: auto;

                            @include media-breakpoint-down(lg) {
                                margin-left: -102px;
                            }

                            @include media-breakpoint-down(sm) {
                                position: relative;
                                margin-left: -120px;
                                margin-top: 0;
                                left: 95px;
                                top: 65px;
                                width: 79px;
                                height: 59px;
                            }

                            @include media-breakpoint-only(xl) {
                                margin-top: 30px;
                            }
                        }

                        .name {
                            font-size: 24px;
                            font-family: var(--proxima-nova-light);
                            font-weight: 400;
                            padding-bottom: 0;
                            margin-bottom: 0;
                            margin-top: 55px;
                            color: #fff;
                            line-height: 1.15;

                            &:focus {
                                outline: none;
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 18px;
                                margin-top: 12px;
                            }

                            @include media-breakpoint-only(xl) {
                                font-size: 22px;
                                margin-top: 40px;
                            }
                        }

                        .summary {
                            font-size: 16px;
                            font-family: var(--proxima-nova-semibold);
                            margin-top: 0;
                            color: #fff;
                            line-height: 1.15;

                            &:focus {
                                outline: none;
                            }

                            @include media-breakpoint-down(sm) {
                                font-size: 12px;
                                margin-top: 0;
                            }

                            @include media-breakpoint-only(xl) {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.oma-video-block-modal {
    @include media-breakpoint-only(md) {
        top: 8%;
    }

    @include media-breakpoint-only(lg) {
        top: 10%;
    }

    .modal-dialog {
        max-width: 80vw;

        @include media-breakpoint-down(lg) {
            margin: 0 auto;
        }

        @include media-breakpoint-down(sm) {
            margin: 4.75rem auto;
        }

        .modal-body {
            max-width: 100%;
            background: #fff;
            padding: 0;
            overflow-y: auto;

            @include media-breakpoint-down(sm) {
                min-height: 80vh;
            }

            @include media-breakpoint-only(lg) {
                width: 845px;
            }

            .video-container {
                iframe {
                    height: 465px;

                    @include media-breakpoint-down(sm) {
                        height: 300px;
                    }
                }
            }

            .story {
                padding: 19px 15px 0 1em;
                line-height: 23px;
                color: #14284b;
                font-size: 16px;

                .title {
                    font-size: 21px;
                    line-height: 24px;
                    margin-bottom: 15px;
                    font-family: var(--proxima-nova-light);
                    font-weight: 400;
                    margin-top: 5px;
                    color: #14284b;
                }

                .subtitle {
                    color: #63666a;
                    line-height: 23px;
                    font-size: 16px;
                }

                .summary {
                    margin-bottom: 20px;
                    line-height: 23px;
                    color: #14284b;
                    font-size: 16px;
                    font-family: var(--proxima-nova-regular);
                }
            }
        }
    }
}

body.modal-open .container {
    filter: blur(5px);
}
