﻿.oma-notification {
    width: 100%;
    min-height: 60px;
    display: none;
    padding-bottom: 2px;

    @include media-breakpoint-down(md) {
        min-height: 100px;
    }

    .oma-notification-wrapper {
        width: 100%;
        position: fixed;
        background: #DFF6FC;
        z-index: 100000;

        .oma-notification-container {
            margin: 0 auto;
            padding: 15px;
            display: flex;
            justify-content: center;

            @include media-breakpoint-down(md) {
                flex-direction: column;
                justify-content: space-around;
                min-height: 100px;
            }

            .message {
                font-size: 16px;
                line-height: 30px;
                text-align: center;
                padding: 0 20px;
                margin-right: 20px;

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                }
            }

            .buttons {
                display: flex;
                justify-content: space-evenly;

                @include media-breakpoint-down(md) {
                    justify-content: center;
                }

                button {
                    height: 30px;
                    border: 0;
                }

                #vote-button, #action-button {
                    background: var(--dark-cyan);
                    color: #fff;
                    font-size: 15px;
                    line-height: 18px;
                    font-weight: bold;
                    padding: 0 15px;
                    border-radius: 5px;
                    font-family: var(--proxima-nova-regular);
                    margin-right: 10px;
                }

                #remind-button, #snooze-button {
                    background: #DFF6FC;
                    color: #0088CE;
                    font-size: 15px;
                    line-height: 20px;
                    font-family: var(--proxima-nova-semibold);
                }
            }
        }
    }
}

.oma-notification:first-child {
    margin-top: -16px;
}

.oma-cookie-notification {
    background: #232E83;
    position: fixed;
    width: 100%;
    height: auto;
    bottom: 0;
    display: none;
    z-index: 9999;

    @include media-breakpoint-down(md) {
        height: auto;
        min-height: 100px;
    }

    .oma-cookie-notification-container {
        display: flex;
        padding: 15px;
        justify-content: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

        .message {
            color: #fff;
            line-height: 30px;
            margin-right: 25px;
            text-align: center;

            @include media-breakpoint-down(md) {
                margin-right: 0;
            }

            a, a:active, a:visited, a:focus {
                color: #fff;
                text-decoration: underline;
            }
        }

        .buttons {
            display: flex;
            justify-content: center;

            .cookie-page-link {
                line-height: 30px;
                text-decoration: underline;
                margin-right: 15px;

                a, a:active, a:visited, a:focus {
                    color: #fff;
                }
            }

            #cookie-accept-button {
                color: #fff;
                background: --var(--dark-cyan);
                font-weight: bold;
                height: 30px;
                line-height: 0;
            }
        }
    }
}