﻿.speaker-detail-container {
    margin: auto auto 40px;
    display: flex;
    justify-content: space-between;
}

.speaker-detail-container .speaker-media .profile-picture {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 1366px) {
    .speaker-detail-container {
        flex-direction: row;
    }
}

@media screen and (max-width:1365px) {
    .speaker-detail-container .speaker-media {
        display: flex;
        padding-top: 40px;
    }
}

@include media-breakpoint-down(lg) {
    .speaker-detail-container {
        flex-direction: column;
    }

    .speaker-detail-container .speaker-media {
        justify-content: center;
    }

    .speaker-detail-container {
        .speaker-media-desktop {
            display: none;
        }

        .speaker-media-mobile {
            display: block;
        }
    }

    .speaker-detail-container .speaker-media {
        padding-top: 0;
    }
}

@include media-breakpoint-up(md) {
    .speaker-detail-container .speaker-media {
        flex-direction: column;
    }
}

.speaker-detail-container .speaker-media .profile-picture {
    width: 100%;
    height: auto;
    margin: 20px 0 30px;

    img {
        height: auto;
        width: 354px;
        border-radius: 50%;
        background-color: #b7b7b7;
    }
}

.speaker-detail-container .speaker-bio {
    width: 100%;
    margin-bottom: 30px;
}

.speaker-detail-container .speaker-bio * {
    outline: 0;
    width: 100%;
}

.speaker-detail-container .speaker-bio .speaker-name {
    width: 100%;
    font-size: 30px;
    color: #0088ce;
    font-weight: 700;
    margin-bottom: 10px;
    text-align:left;
}

.speaker-detail-container .speaker-bio .speaker-description {
    font-style: italic;
    font-size: 18px;
    color: #212e83;
    font-weight: 700;
    margin-bottom: 20px;
    line-height: 22px;
    padding:0;
    text-align:left;
}

.speaker-detail-container .speaker-bio .speaker-article {
    font-size: 14px;
    margin-bottom: 30px;
    line-height: 18px;
}

.speaker-detail-container .speaker-bio .speaker-intro {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background: #eef7fc;
    padding: 26px;
    margin-bottom: 30px;
    border-radius: 4px;
}

@media screen and (min-width: 1366px) {
    .speaker-detail-container .speaker-bio {
        width: 517px;
        padding: 0 4rem;
    }
}

.speaker-detail-container .speaker-bio .speaker-intro .intro {
    width: 210px;
    margin-bottom: 20px;
    min-height: 40px;

    p {
        margin: 0;
    }
}

.speaker-detail-container .speaker-bio .speaker-intro .intro-title {
    font-size: 20px;
    color: #0088ce;
    font-weight: 700;
    margin-bottom: 10px;
}

.speaker-detail-container .speaker-bio .speaker-intro .intro-content {
    font-size: 14px;
    line-height: 30px;
}

.speaker-detail-container .speaker-bio .speaches-presentations .sp-title {
    font-size: 20px;
    color: #212e83;
}

.speaker-detail-container .speaker-bio .speaches-presentations .sp-card {
    border: 1px solid #e3e3e3;
    border-radius: 2px;
    padding: 23px 30px;
    margin-bottom: 20px;

    .sp-card-title {
        font-size: 16px;
        color: #000;
        font-weight: 700;
    }

    .sp-content {
        font-size: 14px;
        line-height: 19px;
    }
}

@media screen and (min-width: 650px) and (max-width:1365px) {
    .speaker-detail-container .request-speaker-container {
        width: 100%;
        margin: 0 auto;
    }
}

.speaker-detail-container .request-speaker-container .request-speaker {
    background: #f8f8f8;
    border-radius: 4px;
    padding: 33px 30px;
    width: 100%;

    .title {
        font-size: 20px;
        color: #212e83;
        font-weight: 700;
        margin-top: 0;
        outline: 0;
    }

    .text {
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 20px;
        outline: 0;
    }

    iframe {
        width: 100%;
        height: 650px;
        border: 0;
    }

    .form-item {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;

        label {
            font-size: 13px;
            line-height: 16px;
            margin-bottom: 4px;
        }

        input {
            height: 42px;
            border-radius: 4px;
            border: 1px solid #707070;
        }
    }
}

@media screen and (min-width: 1366px) {
    .speaker-detail-container .request-speaker-container {
        width: 323px;

        .request-speaker .form-item input {
            width: 262px
        }
    }
}

.speaker-detail-container .request-speaker-container .request-speaker .submit {
    text-align: center;
    padding-top: 15px;

    .submit-button {
        background: #212e83;
        color: #fff;
        font-size: 14px
    }
}

.speaker-detail-container .speaker-media-desktop .speaker-watch .title p,
.speaker-detail-container .speaker-media-mobile .speaker-watch .title p {
    line-height: 36px
}

.speaker-detail-container .speaker-media-desktop .speaker-listen .title p,
.speaker-detail-container .speaker-media-mobile .speaker-listen .title p {
    line-height: 60px
}

.speaker-detail-container .speaker-media-desktop .title,
.speaker-detail-container .speaker-media-mobile .title {
    display: flex;
    margin-bottom: 23px
}

.speaker-detail-container .speaker-media-desktop .title img,
.speaker-detail-container .speaker-media-mobile .title img {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-right: 30px
}

.speaker-detail-container .speaker-media-desktop .title p,
.speaker-detail-container .speaker-media-mobile .title p {
    font-size: 20px;
    font-weight: 700;
    margin: 0
}

.speaker-detail-container .speaker-media-desktop .thumbnail-container,
.speaker-detail-container .speaker-media-mobile .thumbnail-container {
    display: flex;
    margin-bottom: 30px
}

.speaker-detail-container .speaker-media-desktop .thumbnail-container .thumbnail,
.speaker-detail-container .speaker-media-mobile .thumbnail-container .thumbnail {
    width: 115px;
    flex-shrink: 0;
    height: 81px;
    background: #b7b7b7;
    text-align: center;
    line-height: 81px;
    margin-right: 15px;
    border-radius: 5px;
}

.speaker-detail-container .speaker-media-desktop .thumbnail-container .text,
.speaker-detail-container .speaker-media-mobile .thumbnail-container .text {
    font-size: 18px;
    font-weight: 700;
    width: 100%;
}

.speaker-detail-container .speaker-media-desktop .thumbnail-container a,
.speaker-detail-container .speaker-media-mobile .thumbnail-container a {
    display: flex;
    width: 100%;
}

.speaker-media-mobile {
    display: none;
    margin-top: 30px;
}

.loading {
    display: flex;
    justify-content: space-around;
}

.loading .banner {
    margin-left: 0;
    width: 354px;
    height: 354px;
    border-radius: 50%;
}

.loading .loading-content {
    width: 400px;
}

.breadcrumbs {
    display: none;
}

@media screen and (min-width: 1366px) {
    .breadcrumbs {
        margin: auto;
        display: block;
        height: 20px;
        padding: 20px 10px;
    }

    .breadcrumbs span {
        color: rgba(115, 116, 139, .7);
    }

    .breadcrumbs span a, .breadcrumbs span a:visted, .breadcrumbs span a:focus {
        text-decoration: none;
        font-size: 12px;
        line-height: 20px;
        color: rgba(115, 116, 139, .7);
    }

    .breadcrumbs span a.active {
        color: #00859b;
        font-family: "Proxima Nova Rg";
        padding-bottom: 5px;
        border-bottom: 1px solid #00859b;
    }
}

@media screen and (min-width: 1366px) and (min-width:1366px) {
    .breadcrumbs {
        max-width: 1366px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
