﻿.oma-page-one-column {
    .page-title {
        color: var(--dark-blue);
        font-size: 40px;
        line-height: 50px;
    }

    .page-description {
        color: var(--dark-blue);
        font-size: 28px;
        line-height: 36px;
        font-family: var(--proxima-nova);
        margin-bottom: 0.5rem;
    }

    li {
        font-family: var(--proxima-nova-light);
        margin-bottom: 1rem;
    }

    li strong, li b {
        font-family: var(--proxima-nova-regular);
    }
}
