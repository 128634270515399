﻿.opip-calc-container {
    margin-bottom: 30px;

    #OPIPCalc {
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 30px;
        margin: 0 15px;
        align-items: end;
        margin-bottom: 30px;

        & > div {
            display: flex;
            flex-direction: column;


            &.span-2 {
                grid-column-end: span 2;
            }

            &.center-items {
                align-items: center;
            }
        }

        label {
            font-weight: bold;
            font-size: 16px;
        }

        @media (max-width: 991.98px) {
            grid-template-columns: 1fr !important;
        }
    }

    .results {
        display: none;
        width: 100%;
        padding: 5px;

        &.visible {
            display: block;
        }
    }

    .fineprint {
        border-radius: 10px;
        background-color: #EAF6F6;
        padding: 10px;
        font-size:0.85em !important;
        & * {
            font-size:inherit;
        }
    }
}
