﻿.homepage-cta-block {
    margin-bottom: $v-margin-between-blocks;
    height: 260px;
    overflow:hidden;

    @include media-breakpoint-only(xxl) {
        height: 315px;
    }

    @include media-breakpoint-only(xxxl) {
        height: 260px;
    }
}

.homepage-cta-content {
    position: relative;
    padding: 27px 27px;
    background-size: cover;
    background-position: center;
    background-color: #EAF6F6;
    height: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content:center;
    .homepage-cta-text-container {
        text-align: center;

        .homepage-cta-title {
            margin-bottom: 10px;
            font-size: 24px;
            line-height: 28px;
            font-family: var(--proxima-nova-regular);
        }

        .oma-btn {
            background-color: var(--dark-cyan);
            margin-top: 20px;
        }
    }

    .homepage-cta-overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }
}
