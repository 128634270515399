﻿.member-login-block {
    margin-bottom: $v-margin-between-blocks;
    height: 260px;

    @include media-breakpoint-only(xxl) {
        height: 315px;
    }

    @include media-breakpoint-only(xxxl) {
        height: 360px;
    }
}

.member-login-content {
    position: relative;
    padding: 27px 27px;
    background-size: cover;
    background-position: center;
    background-color: var(--light-yellow);
    height: 100%;
    z-index: 0;

    .member-login-text-container {
        text-align: center;

        .member-login-title {
            margin-bottom: 10px;
        }

        .member-login-join-link-wrapper {
            position: absolute;
            bottom: 20px;
            transform: translateX(-50%);
            left: 50%;
        }

        .oma-btn {
            background-color: var(--blue);
            margin-top: 20px;
        }

        .btn-info, .btn-info:visited {
            color: #fff !important;
        }
    }

    .member-login-overlay {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;

        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }
}
