﻿.oma-news-events {
    &__banner {
        margin-bottom: 0;
    }

    &__items {
        padding: 1em 0;
    }

    &__featured-content {
        background-color: var(--pale-cyan);

        .oma-news-item {
            padding: 0;
            padding-right: 2em;
            margin-bottom: 2em;

            .oma-image-container {
                min-width: 445px;
                max-width: 445px;
                height: 250px;
            }

            .oma-news-item-text {
                padding-top: 1.5em;
                padding-left: 0.5em;
            }

            .oma-news-item-text.no-image {
                padding-left: 20px;
            }
        }
    }

    &__view-more-link {
        display: inline-block;
        margin-bottom: 2rem;
    }
}

.oma-search-news {
    margin-bottom: 50px;
    fieldset.form-group{
        legend{
            font-size:initial;
        }
        label{
            visibility:hidden;
            height:0;
            margin:0;
        }
    }
    &__title {
        margin-bottom: 1em;
    }

    &__date-picker {
        margin-bottom: 10px;
    }

    &__buttons {
        display: flex;
        justify-content: center;

        .oma-search-button {
            padding: 0 20px;
            line-height: 18px;
        }

        .oma-btn-link {
            margin-right: 10px;
        }
    }

    input[type=date] {
        display: flex;
        position: relative;
    }
    
    /* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
    input[type=date]::-webkit-calendar-picker-indicator {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        color: transparent;
        background: transparent;
        z-index: 100;
    }

    .date-input {
        display: flex;
    }
    
    /* adjust increase/decrease button */
    input[type=date]::-webkit-inner-spin-button {
        z-index: 1;
    }
    
     /* adjust clear button */
     input[type=date]::-webkit-clear-button {
         z-index: 1;
     }

     .calendar-icon {
         position: absolute;
         right: 20px;
         margin-top: 3px;
         @include media-breakpoint-down(md) {
            right: 8px;
        }

     }
}

.oma-bottom-dates {
    margin-top: 32px;
    font-size: 14px;
}

.search-form-mobile {
    padding: 20px 0 0;
}