﻿.oma-bottom-feature-block {
    width: 100%;
}

.oma-bottom-feature-item {
    margin-bottom: $v-margin-between-blocks;
}

    .oma-bottom-feature-block > .oma-bottom-feature-block-content > .oma-bottom-feature-item {
        padding: 15px;
    }

        .oma-bottom-feature-block > .oma-bottom-feature-block-content > .oma-bottom-feature-item > .oma-bottom-feature-item-image-container {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 100%;
            height: 200px;
        }

            .oma-bottom-feature-block > .oma-bottom-feature-block-content > .oma-bottom-feature-item > .oma-bottom-feature-item-image-container > .oma-bottom-feature-item-image {
                width: 100% !important;
                height: 100% !important;
                object-fit: cover;
            }

    .oma-bottom-feature-block > .oma-bottom-feature-block-content > .oma-bottom-feature-item .oma-bottom-feature-item-caption {
        padding: 0 30px;
        padding-bottom: 30px;
        text-align: center;
        font-family: var(--proxima-nova-light);
        font-size: 16px;
        line-height: 1.5em;
        color: var(--black);
    }

    .oma-bottom-feature-block > .oma-bottom-feature-block-content .oma-bottom-feature-item-text > .oma-bottom-feature-item-link-wrapper {
        padding: 20px 20px 10px 20px;
        text-align: center;
    }

        .oma-bottom-feature-block > .oma-bottom-feature-block-content .oma-bottom-feature-item-text .oma-bottom-feature-item-link > .oma-bottom-feature-item-title {
            font-family: var(--proxima-nova-light);
            font-size: 30px;
            line-height: 36px;
            color: var(--blue);
        }

@media only screen and (max-width: 420px) {
    .oma-bottom-feature-block .oma-bottom-feature-block-content {
        flex-direction: column;
    }

    .oma-bottom-feature-block > .oma-bottom-feature-block-content > .oma-bottom-feature-item {
        margin-bottom: 25px;
    }

    .oma-bottom-feature-block > .oma-bottom-feature-block-content .oma-bottom-feature-item-text > .oma-bottom-feature-item-link-wrapper {
        padding: 15px 15px 10px 15px;
    }
}

@media only screen and (min-width: 421px) and (max-width: 1024px) {
    .oma-bottom-feature-block > .oma-bottom-feature-block-content .oma-bottom-feature-item-text > .oma-bottom-feature-item-link-wrapper {
        padding: 10px 0;
    }

    .oma-bottom-feature-block > .oma-bottom-feature-block-content > .oma-bottom-feature-item .oma-bottom-feature-item-caption {
        padding: 0;
        padding-bottom: 15px;
    }
}