.oma-header {
    z-index: 1000;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
        position: relative;
        border-bottom: 1px solid var(--border-grey);
        margin-bottom: 15px;
    }

    @include media-breakpoint-down(sm) {
        height: 65px;
    }

    &__logo {
        height: 60px;
        max-width: 300px;
        z-index: 200;

        @include media-breakpoint-down(md) {
            height: 40px;
            margin-left: 60px;
        }

        @include media-breakpoint-down(sm) {
            max-width: 130px;
            margin-left: 30px;
        }

        @include media-breakpoint-down(xs) {
            max-width: 85px;
            padding: 0 !important;
            margin-left: 40px;
        }

        .logo-wrapper {
            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        .logo-main {
            max-height: 100%;
            max-width: 100%
        }
    }

    &__top-features {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;
    }

    &.member-message {
        .member-public-content-message {
            background-color: var(--pale-cyan);
            margin-top: -15px;
            height: 40px;
            display: flex;
            justify-content: center;
            padding: 10px;

            p {
                font-family: var(--proxima-nova-regular);
                color: var(--black);
            }

            @include media-breakpoint-down(md) {
                height: 60px;
            }

            @include media-breakpoint-down(sm) {
                height: 80px;
            }
        }

        .oma-navigation-menu {
            @include media-breakpoint-down(md) {
                top: 90px;
            }

            @include media-breakpoint-down(sm) {
                top: 110px;
            }
        }

        @include media-breakpoint-down(sm) {
            height: auto;
        }

        .oma-header__top-features {
            margin-top: 20px;
            margin-bottom: 10px;
        }

    }
}

.oma-member-menu-wrapper {
    z-index: 2500;
}

.oma-member-menu {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
    padding-top: 5px;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }

    &-item {
        list-style-type: none;
        margin-left: 15px;
        position: relative;
        cursor: pointer;

        @include media-breakpoint-down(lg) {
            margin: 0 10px;
        }

        .notifications-icon {
            content: attr(notifications-count);
            display: inline-block;
            position: absolute;
            font-size: 11px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            border-radius: 50%;
            background: var(--red);
            font-family: 'Montserrat', sans-serif;
            font-weight: bold;
            vertical-align: middle;
            color: var(--white);
        }

        .member-btn {
            min-width: 140px;
            font-family: var(--proxima-nova-semibold);
            font-weight: bold;
            line-height: 1.5em;

            &:hover, &:focus, &:active {
                background-color: var(--member-menu-dark-blue) !important;
            }
        }

        .btn-info,.btn-info:visited{
            color: #fff !important;
        }
    }

    .homepage-item, .community-item {
        .item-icon {
            max-height: 22px;

            @include media-breakpoint-down(xs) {
                max-height: 19px;
            }
        }
    }

    .homepage-item, .community-item, .user-information-item {
        display: block;
        height: 40px;

        @include media-breakpoint-down(lg) {
            height: 20px;
        }

        .menu-item-text-wrapper {
            position: relative;

            .icon-wrapper {
                display: inline-block;
                position: relative;

                .notifications-icon {
                    top: -10px;
                    left: 11px;
                }
            }

            .menu-arrow {
                &:before {
                    display: none;
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-top: 15px solid transparent;
                    border-bottom: 15px solid var(--bright-blue);
                    left: 50%;
                    top: 50%;
                }

                &:after {
                    display: none;
                    content: "";
                    width: 0px;
                    height: 0px;
                    position: absolute;
                    border-left: 13px solid transparent;
                    border-right: 13px solid transparent;
                    border-top: 13px solid transparent;
                    border-bottom: 13px solid var(--white);
                    left: calc(50% + 2px);
                    top: calc(50% + 5px);
                    z-index: 3000;
                }

                &.centered-from-icon {
                    &:before {
                        left: -10%;
                    }

                    &:after {
                        left: calc(-10% + 2px);
                    }
                }
            }
        }

        .link, .link:active, .link:visited, .link:focus {
            color: var(--black);
            text-decoration: none;
        }

        .item-text {
            margin-left: 3px;
            font-size: 14px;
            font-family: var(--proxima-nova-light);
            line-height: 19px;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        .panel {
            display: none;
            position: absolute;
            padding: 15px;
            right: -50%;
            top: 70%;
            z-index: 2000;
            width: 255px;
            min-height: 145px;
            text-align: left;
            border: solid 2px var(--bright-blue);
            background-color: var(--white);
            margin-top: 10px;
            cursor: auto;
            box-shadow: 4px 4px 10px #222;
            justify-content:space-between;

            @include media-breakpoint-down(lg) {
                right: -100%;
                top: 30px;
            }
        }

        &.active {
            outline: none;

            .panel {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .menu-item-text-wrapper {
                .menu-arrow {
                    &:before {
                        display: block;

                        @include media-breakpoint-down(lg) {
                            left: -20%;
                            top: 50%;
                        }
                    }

                    &:after {
                        display: block;

                        @include media-breakpoint-down(lg) {
                            left: calc(-20% + 2px);
                            top: calc(50% + 5px);
                        }
                    }
                }
            }
        }
    }

    .initials:before {
        content: attr(data-letters);
        display: inline-block;
        font-size: 12px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border-radius: 50%;
        background: var(--dark-blue);
        font-family: var(--proxima-nova-semibold);
        vertical-align: middle;
        margin-right: 5px;
        color: var(--white);

        @include media-breakpoint-down(xs) {
            width: 22px;
            height: 22px;
            line-height: 22px;
        }
    }

    .initials.grey-background:before {
        width: 70px;
        height: 70px;
        background: var(--border-grey);
        color: var(--dark-blue);
        font-size: 24px;
        line-height: 70px;
    }

    .panel.right-arrow {
        right: 0;
    }

    .user-information-item {

        .username {
            font-family: var(--proxima-nova-semibold);
            margin-top: 12px;
            margin-bottom: 0;
        }

        .dues-outstanding {
            display: flex;
            justify-content: center;
            align-items: start;
            align-self: start;
            flex-direction: column;
            padding: 5px 0 5px;

            .text {
                font-size: 13px;
                line-height: 16px;
            }

            .value, .link {
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 5px;
            }
        }

        .my-account-btn {
            max-width: 70%;
            margin-bottom: 7px;
            margin-top: 7px
        }

        .popup-link, .popup-link:active, .popup-link:visited, .popup-link:focus {
            color: var(--blue);
            text-decoration: underline;
        }
    }

    .community-item {
        &:focus {
            .panel {
                align-items: start;
            }
        }

        .community-subitem {
            display: flex;
            flex-direction: column;
            align-items: start;
            margin-bottom: 20px;
            font-size: 13px;
            line-height: 16px;
            position: relative;
            width: 100%;

            .notifications-icon {
                right: 0;
                bottom: 0;
            }
        }

        .community-subitem:first-of-type {
            margin-top: 20px;
        }

        .community-subitem:last-of-type {
            margin-bottom: 30px;
        }

        .link, .link:active, .link:visited, .link:focus {
            font-size: 15px;
            line-height: 18px;
            color: var(--blue);
            text-decoration: underline;
        }

        .community-item-text {
            font-size: 15px;
            line-height: 20px;
            font-family: var(--proxima-nova-regular);
            text-align: left;
            margin-bottom: 0;
        }
    }

    .searchbar-item {
        width: 24px;
        height: 24px;
        margin-left: 0;
        margin-right: 1rem;

        @include media-breakpoint-down(md) {
            margin-right: 0;
            padding-top: 3px;
        }

        @include media-breakpoint-down(xs) {
            width: 22px;
            height: 22px;
        }

        .searchbar-btn {
            position: inherit;
        }

        .close-searchbar-btn {
            position: absolute;
        }

        .header-searchbar {
            position: fixed;
            width: 100%;
            top: 1rem;
            height: 62px;
            background-color: white;

            @include media-breakpoint-down(md) {
                top: -16px;
                position: absolute;
                width: 680px;
                padding-right: 0;

                .search-icon {
                    left: 0;
                }

                .close-searchbar-btn {
                    right: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                width: 510px;
                max-width: 94vw;
            }

            @include media-breakpoint-down(xs) {
                width: 90vw;
            }

            .searchbar-input {
                height: 63px;
                padding: 0 0 0 3.5rem;

                @include media-breakpoint-down(lg) {
                    padding: 0 0 0 2.5rem;
                }

                @include media-breakpoint-down(md) {
                    padding: 0;
                }

                @include media-breakpoint-down(sm) {
                    padding-left: 2rem;
                }

                @include media-breakpoint-down(xs) {
                    font-size: 14px;
                }
            }
        }
    }
}
