﻿.oma-navigation-menu {
    &__desktop-wrapper {
        display: none;
        position: relative;
        background-color: var(--white);

        @include media-breakpoint-up(lg) {
            border-bottom: 1px solid var(--border-grey);
            display: block;
        }

        @include media-breakpoint-only(xs) {
            top: 18px;
        }

        @include media-breakpoint-only(sm) {
            top: 14px;
        }

        @include media-breakpoint-only(md) {
            top: 20px;
        }
    }

    &__ul._lvl1 {
        padding-top: 30px;

        @include media-breakpoint-up(lg) {
            padding-right: 30px !important;
            padding-top: 0;
        }

        @include media-breakpoint-down(sm) {
            padding: 15px 0 45px 0;
        }
    }
}

@include media-breakpoint-down(lg) {
    .oma-navigation-menu {
        &.isVisible {
            .oma-navigation-menu__desktop-wrapper {
                display: block;
            }
        }
    }
}

.search-element {
    &.desktop {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    &.mobile {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .header-searchbar {
        display: none;
        width: 100%;
        position: absolute;
        padding: 0 40px 5px 15px;
        right: 0;
        bottom: 0;
        border: none;
        z-index: 2100;

        @include media-breakpoint-only(md) {
            padding: 0 40px 5px 4rem;
        }

        .search-icon {
            position: absolute;
            bottom: 1rem;
            left: 1rem;

            @include media-breakpoint-only(md) {
                left: 3rem;
            }
        }

        &.visible {
            display: block;
        }

        input[type=search] {
            border: none;
            margin-bottom: 3px;
            padding: 0 2.5rem;

            &:focus {
                box-shadow: none;
            }
        }
        /* clears the Bootstrap 'X' icon from Internet Explorer */
        input[type=search]::-ms-clear {
            display: none;
            width: 0;
            height: 0;
        }
        /* clears the Bootstrap 'X' icon from Chrome */
        input[type="search"]::-webkit-search-cancel-button {
            display: none;
        }

        .header-searchbar-button {
            position: absolute;
            bottom: 1rem;
            right: 2rem;
        }

        .predictive-search-results {
            position: absolute;
            top: 45px;
            width: 98%;
            padding: 1rem;
            background-color: var(--light-grey);

            @include media-breakpoint-down(lg) {
                margin-top: 1rem;
                width: 95%;
            }

            @include media-breakpoint-only(md) {
                width: 95%;
            }

            @include media-breakpoint-down(sm) {
                width: 90%;
            }

            &:empty {
                height: 0;
                padding: 0;
            }

            .predictive-search-result-item {
                display: block;
                padding: 0.5rem 3rem 0.5rem;
            }
        }
    }

    .searchbar-btn {
        display: none;
        position: absolute;
        right: 1rem;
        top: 6px;
        bottom: 12px;
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
        background-color: transparent;
        z-index: 1000;

        @include media-breakpoint-only(md) {
            right: 3rem;
            top: 1rem;
        }

        &.visible {
            display: block;

            &:focus-visible {
                outline: solid black 1px;
            }
        }

        &:focus {
            outline: 0;
            border: none;
        }
    }
}
