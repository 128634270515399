﻿.oma-community-tab-container {
    margin: auto;
}

.oma-community-tab {
    display: block;
}

.oma-community-tab-active {
    color: #00bce7;
    font-weight: 700;
    border-bottom: 2px #00bce7 solid;
}

.oma-community-tab-activeDistrict {
    color: #00bce7;
    font-weight: 700;
    border-bottom: 2px #00bce7 solid;

    a, a:active, a:visited, a:focus {
        color: inherit;
    }
}

.oma-community-tab-activeSection {
    color: #0090a1;
    font-weight: 700;
    border-bottom: 2px #0090a1 solid;

    a {
        color: inherit;
    }
}

.oma-community-tab-activeCommittee {
    color: #fcb53b;
    font-weight: 700;
    border-bottom: 2px #fcb53b solid;

    a {
        color: inherit;
    }
}

.oma-community-tab-headers {
    border: none !important;
    overflow: hidden;
    flex-shrink: 0;
    flex-grow: 1;
    position: relative;
    transition: transform .5s cubic-bezier(.35,0,.25,1);
    color: rgba(0,0,0,.87);



    a {
    }

    a:hover {
        text-decoration: none;
    }
}

.oma-community-tab-header {
    height: 48px;
    padding: 0 24px;
    cursor: pointer;
    box-sizing: border-box;
    opacity: .6;
    min-width: 160px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
}

.oma-community-tab-bodys {
    display: block;
    background: #f5f5f5;
    min-height: calc(100vh - 400px);
}



.oma-community-tab-body {
    display: none;

    .titlecontainer {
        max-height: 62px;
        background-color: #f5f5f5;
        padding: 20px 0;
        max-width: 1366px;
        margin: auto;
    }

    .title {
        font-weight: 700;
        font-size: 28px;
    }

    .subtitle {
        color: #001a72;
        font-size: 18px;
        border: #707070;
        font-family: var(--proxima-nova-regular);
        padding: 0 0 10px;
        margin-top: 15px;
    }
}

.body-active {
    display: flex;
}

.oma-community-item {
    background: #fff;
    padding: 10px 5px 5px 15px;
    margin-top: 0;
    border-radius: 5px;
    margin-bottom: 15px;

    .itemtitle {
        text-decoration: none;
        color: #001a72;
        font-size: 14px;
        line-height: 18px;
        font-family: var(--proxima-nova-semibold);
        margin-right: 25px;
    }

    .itembody {
        font-size: 14px;
        color: #4d526e;
        margin-bottom: 5px;
        line-height: 18px;
    }

    .itemlink {
        display: flex;
        flex-wrap: wrap;
        line-height: 18px;
        color: #00b5e2;
        padding-bottom: 3px;
        font-size: 12px;
    }

    .itemdate {
        color: #8a8c8f;
        font-size: 10px;
        margin-right: 10px;
        display: flex;
        align-items: center;
        line-height: 18px;
    }

    .itemtitlerow {
        display: block;

        .itemtype {
            position: relative;
            display: inline-flex;
            margin: 0 auto;
            left: 0;
            right: 0;
            text-align: center;
            top: 0;
            font-family: var(--proxima-nova-semibold);
            font-size: 12px;
            font-weight: 500;
            background: #001a72;
            border-radius: 20px;
            border: 1px solid;
            padding-left: 15px;
            padding-right: 15px;
            color: #fff;
            overflow: hidden;
        }
    }
}

.Districtcolor {
    color: #00bce7;
}

.Sectioncolor {
    color: #0090a1;
}

.Committeecolor {
    color: #fcb53b;
}

.mat-paginator-icon {
    width: 28px;
    fill: currentColor;
}

.mat-icon-disable {
    color: rgba(0,0,0,.26);
}

.oma-community-paginator {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #0569ba;
}

@media screen and (min-width: 1366px) {
    .leftpanel {
        width: 61%;
    }
}

@media screen and (min-width: 1366px) {
    .right-panel {
        width: 38%;
        
    }
}
.right-panel {
    margin-left: 20px;
}
 
.community-content-header-container {
    height: 20px;
    max-height: 62px;
    background-color: #f5f5f5;
    padding: 20px 0;
    max-width: 1366px;
    margin: auto;
}

.right-panel .community-content-header-container .exchange-message-title {
    color: #001a72;
    font-size: 18px;
    border: #707070;
    font-family: var(--proxima-nova-regular);
    padding: 0 0 10px;
}

.right-panel .right-section-block {
    padding: 0 0 15px;
}

.right-panel .right-section-block .right-container {
    background: #fff;
    padding: 10px 15px;
    font-size: 14px;
    color: #4d526e;
    border: #707070;
    border-radius: 5px;
    font-family: var(--proxima-nova-regular);
}

.community-content .community-content-header-container {
    height: 20px;
    max-height: 62px;
    background-color: #f5f5f5;
    padding: 20px 0;
    max-width: 1366px;
    margin: auto
}

.community-content .community-content-header-container .title {
    font-family: var(--proxima-nova-regular);
    font-size: 24px;
    font-weight: 700;
    color: #001a72
}

@media screen and (min-width:1366px) {
    .community-content .community-content-header-container .title {
        font-size: 28px
    }
}

.community-content .community-content-header-container .exchange-message-title {
    color: #7c9ac0;
    font-size: 24px;
    border: #707070;
    font-family: var(--proxima-nova-regular);
    padding: 0 0 10px
}

.community-content .community-content-header-container .left {
    float: left
}

.community-content .community-content-header-container .district {
    color: #00bce7
}

.community-content .community-content-header-container .section {
    color: #0090a1
}

.community-content .community-content-header-container .committee {
    color: #fcb53b
}

.community-content .community-content-results {
    background: #f5f5f5;
    min-height: calc(100vh - 400px)
}

.community-content .community-content-results .community-content-container {
    padding-left: 10px;
    padding-right: 10px
}

.community-content .community-content-results .left-panel {
    width: 100%
}

@media screen and (min-width:1366px) {
    .community-content .community-content-results .community-content-container {
        max-width: 1366px;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: row
    }

    .community-content .community-content-results .left-panel {
        width: 61%
    }
}

.community-content .community-content-results .loading > div {
    display: flex;
    max-width: 718px;
    margin-bottom: 20px
}

.community-content .community-content-results .loading .banner {
    width: 134px;
    height: 87px;
    margin: unset
}

.community-content .community-content-results .loading .loading-content {
    width: 300px;
    margin-left: 20px
}

.community-content .community-content-results .loading .loading-content h1 {
    margin: 12px 0
}

.community-content .community-content-results .loading .loading-content h2 {
    margin: 0 0 7px
}

.community-content .community-content-results .community-description-block {
    padding: 0 10px 0 0;
    font-size: 14px;
    color: #4d526e;
    border: #707070;
    border-radius: 5px;
    font-family: var(--proxima-nova-regular);
}

.community-content .community-content-results .date-title {
    color: #4d526e;
    font-family: var(--proxima-nova-light);
    font-size: 16px;
    margin-bottom: 16px
}

.community-content .community-content-results article {
    display: flex;
    max-width: 718px;
    min-width: 0 !important;
    margin-bottom: 20px;
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    width: 90%
}

.community-content .community-content-results article p {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0
}

.community-content .community-content-results .image-article {
    display: inline-block;
    max-width: 745px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #e3e3e3;
    width: 90%;
    max-height: 190px
}

.community-content .community-content-results .image-article p {
    -webkit-margin-before: 0;
    margin-block-start: 0;
    -webkit-margin-after: 0;
    margin-block-end: 0
}

.community-content .community-content-results .image-article img {
    float: left
}

.community-content .community-content-results .image-text {
    margin-left: 20px;
    padding: 20px
}

.community-content .community-content-results .image-title {
    font-size: 16px;
    font-family: var(--proxima-nova-regular);
    color: #4d526e;
    margin-bottom: 6px
}

.community-content .community-content-results .image-date {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    color: #8a8c8f;
    font-size: 8px;
    margin-right: 10px
}

.community-content .community-content-results .item-image img {
    width: 87px;
    height: 56px;
    border-radius: 4px
}

.community-content .community-content-results .image-description {
    font-size: 14px;
    color: #4d526e;
    margin-bottom: 10px;
    line-height: 18px;
    margin-top: 5px
}

.community-content .community-content-results .item-content {
    display: flex;
    flex-direction: column
}

.community-content .community-content-results .item-filters {
    display: flex;
    align-items: center;
    line-height: 18px
}

.community-content .community-content-results .item-filters .filter-date {
    color: #8a8c8f;
    font-size: 10px;
    margin-right: 10px
}

.community-content .community-content-results .item-filters .filter-category {
    color: #fff;
    font-size: 10px;
    background: #d87c65;
    border-radius: 8px;
    padding: 3px 6px 2px 7px;
    font-family: var(--proxima-nova-light);
    font-weight: 600
}

.community-content .community-content-results .item-title {
    font-size: 14px;
    font-family: var(--proxima-nova-regular);
    color: #001a72;
    line-height: 18px
}

.community-content .community-content-results .item-title a {
    text-decoration: none;
    color: #001a72
}

.community-content .community-content-results .item-title .person-section-info {
    margin-left: 15px;
    position: relative;
    display: inline-block
}

.community-content .community-content-results .item-title .person-section-info .text {
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    font-family: var(--proxima-nova-semibold);
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    overflow: hidden
}

.community-content .community-content-results .text-links {
    color: #00b5e2;
    font-size: 14px;
    border: #707070;
    font-family: var(--proxima-nova-regular);
    line-height: 18px
}

.community-content .community-content-results .separation-line {
    border-top: 1px solid #ededed;
    padding-bottom: 5px
}

.community-content .community-content-results .item-description {
    font-size: 14px;
    color: #4d526e;
    margin-bottom: 5px;
    line-height: 18px
}

.community-content .community-content-results .seemore {
    padding-top: 5px;
    cursor: pointer
}

.community-content .community-content-results .item-links {
    display: flex;
    flex-wrap: wrap;
    line-height: 18px;
    color: #00b5e2;
    padding-bottom: 3px
}

.community-content .community-content-results .item-links > div {
    display: flex;
    align-items: center;
    margin-bottom: 7px
}

.community-content .community-content-results .item-links a {
    color: #00b5e2;
    font-size: 12px;
    font-family: var(--proxima-nova-light);
    font-weight: 600;
    margin-right: 15px
}

.community-content .community-content-results .item-links .mat-icon {
    margin-right: 6px
}

.community-content .community-content-results .item-links .mat-icon.pdf {
    width: 14px
}

.community-content .community-content-results .item-links .mat-icon.press {
    width: 19px
}

.community-content .community-content-results .item-links .mat-icon.youtube {
    width: 24px
}

.community-content .community-content-results .item-links .mat-icon.agenda, .community-content .community-content-results .item-links .mat-icon.calendar, .community-content .community-content-results .item-links .mat-icon.minutes, .community-content .community-content-results .item-links .mat-icon.podcast {
    width: 17px
}

.community-content .community-content-results .item-links .mat-icon.survey {
    width: 15px
}

.community-content .community-content-results .item-links .mat-icon.surveyQA {
    width: 80px;
    padding: 20px
}

.community-content .community-content-results .item-links .mat-icon.results {
    width: 12px
}

.community-content .community-content-results .item-links .mat-icon.connect {
    width: 17px
}

.community-content .community-content-results .item-links .mat-icon.rightChevron {
    width: 10px;
    height: auto !important;
    margin-left: 5px;
    color: #0067b9
}

.community-content .community-content-results .event-img-overlay-wrap {
    width: 50%;
    max-height: 190px;
    background-color: #001a72;
    position: relative
}

.community-content .community-content-results .event-img-overlay-wrap img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 22px 15px 15px
}

.community-content .community-content-results .event-img-overlay-wrap div {
    position: absolute
}

.community-content .community-content-results .event-img-overlay-wrap .text {
    top: 100px;
    left: 25px;
    line-height: 16px;
    font-size: 12px;
    font-family: var(--proxima-nova-regular);
    color: #fff
}

.community-content .community-content-results .news-feed-container {
    background: #fff;
    padding: 10px 5px 5px 15px;
    margin-top: 0;
    border-radius: 5px;
    margin-bottom: 15px
}

.community-content .community-content-results .news-feed-container .img-content-container {
    display: flex
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content {
    border-radius: 5px;
    border-color: #001a72;
    max-height: 190px;
    background-color: #001a72;
    position: relative;
    padding: 0 15px;
    margin-right: 20px
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content .img-container {
    position: relative;
    text-align: center;
    color: #fff
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content .img-container div {
    position: absolute
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content .img-container img {
    max-width: 100%;
    height: auto;
    margin: 8px 0
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content .img-container .text-date {
    left: 20px;
    top: 25px;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content .img-container .text-month {
    left: 18px;
    top: 50px;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px
}

.community-content .community-content-results .news-feed-container .img-content-container .img-content .text {
    padding-left: 7px;
    padding-right: 7px;
    margin-bottom: 8px;
    line-height: 16px;
    font-size: 14px;
    font-family: var(--proxima-nova-regular);
    color: #fff;
    text-align: center
}

.community-content .community-content-results .img-overlay-wrap {
    position: relative
}

.community-content .community-content-results .img-overlay-wrap img {
    display: block;
    max-width: 100%;
    height: auto
}

.community-content .community-content-results .img-overlay-wrap div {
    position: absolute;
    top: 0;
    left: 0
}

.community-content .community-content-results .img-overlay-padding {
    padding-top: 20px;
    padding-left: 12px
}

.community-content .community-content-results .svg-text {
    font-size: 12px;
    font-family: var(--proxima-nova-regular);
    color: #fff;
    margin-top: 75%;
    margin-left: 25%;
    padding-top: 50px;
    text-align: center
}

.community-content .community-content-results .div-img-text {
    padding: 15px
}

.community-content .community-content-results .exchange-message-container {
    background: #fff;
    padding: 10px;
    font-size: 14px;
    color: #4d526e;
    border: #707070;
    border-radius: 5px;
    font-family: var(--proxima-nova-regular);
    margin-right: 20px
}

.community-content .community-content-results .exchange-message-title {
    color: #001a72;
    font-size: 18px;
    border: #707070;
    font-family: var(--proxima-nova-regular);
    padding: 0 0 10px;
    margin-top: 15px
}

.rightpanel .right-section-block {
    padding: 0 0 15px
}

.right-panel .right-container-title {
    color: #001a72;
    font-size: 18px;
    border: #707070;
    font-family: var(--proxima-nova-regular);
    padding: 0 0 10px
}

.right-panel .right-container {
    background: #fff;
    padding: 10px 15px;
    font-size: 14px;
    color: #4d526e;
    border: #707070;
    border-radius: 5px;
    font-family: var(--proxima-nova-regular);
}

.right-panel .right-section-block .right-container .exchange-description {
    font-size: 14px;
    font-family: var(--proxima-nova-regular);
    color: #001a72;
    line-height: 18px;
}

.right-panel .right-container .exchange-message-heading {
    color: #001a72;
    font-family: var(--proxima-nova-regular);
    padding: 0 0 20px;
    font-size: 16px
}

.right-panel .right-container .exchange-links {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0 0;
    font-size: 16px;
    line-height: 18px;
    color: #00b5e2;
    font-family: var(--proxima-nova-regular);
}

.right-panel .right-container .exchange-links a {
    color: #00b5e2
}

.right-panel .right-container .person-picture-container {
    float: left;
    width: 20%;
    display: flex
}

.right-container .person-picture {
    width: 80%
}

.right-panel .right-container .person-info-container {
    width: 79%
}

.right-panel .right-container .person-info-basic {
    display: flex
}

.right-panel .right-container .person-section-info {
    margin-left: 15px;
    position: relative;
    display: inline-block
}

.right-panel .right-container .person-section-info .text {
    position: absolute;
    z-index: 999;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 15%;
    font-family: var(--proxima-nova-semibold);
    font-size: 10px;
    font-weight: 500;
    color: #fff;
    overflow: hidden
}

.right-panel .right-container .person-name {
    color: #4d526e;
    border: #707070;
    font-size: 16px;
    font-family: var(--proxima-nova-regular);
}

.right-panel .right-container .person-company {
    font-size: 14px;
    font-family: var(--proxima-nova-regular);
    color: #4d526e;
    border: #707070
}

.right-panel .right-container .person-email {
    font-size: 14px;
    font-family: var(--proxima-nova-regular);
    color: #00b5e2;
    border: #707070;
    padding-top: 5px;
    padding-bottom: 5px
}

.right-panel .right-container .person-email a {
    color: #00b5e2;
    text-decoration: none
}

.right-panel .right-container .person-phone {
    font-size: 14px;
    font-family: var(--proxima-nova-regular);
    color: #00b5e2;
    border: #707070;
    padding-top: 10px
}

.community-content .mat-paginator {
    background-color: transparent;
    color: #0569ba
}

.community-content .mat-paginator-container {
    padding: 0 20px;
    min-height: 50px
}

.community-content .mat-expansion-panel {
    margin-bottom: 10px
}

.community-content .mat-expansion-panel-spacing:last-child .mat-expansion-panel-spacing:first-child {
    margin-bottom: 10px
}
