﻿.priority-top-feature {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 0;
    background-color: var(--dark-blue);

    @include media-breakpoint-down(md) {
        flex-direction: column;
    }

    &.narrow-view {
        .oma-image-container {
            height: 350px;
            width: 590px;
            flex-shrink: 0;

            @include media-breakpoint-down(xl) {
                height: 250px;
                width: 420px;
            }

            @include media-breakpoint-down(md) {
                height: auto;
                width: 100%;
            }
        }

        .oma-text-container {
            max-height: 350px;

            @include media-breakpoint-down(xl) {
                max-height: 250px;
                overflow: hidden;
            }

            @include media-breakpoint-down(md) {
                max-height: unset;
                overflow: hidden;
            }
        }
    }

    .oma-image-container {
        height: 360px;
        overflow: hidden;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            width: 635px;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .overlay-blur {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 65px;
            height: 100%;
            background: linear-gradient( to left, white, transparent);
            opacity: 0.6;
        }

        .oma-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .oma-text-container {
        text-align: left;
        padding: 2rem 2rem 2rem 1rem;

        .oma-title {
            color: var(--white);
        }

        .oma-subtitle {
            color: var(--white);
        }

        .oma-caption {
            padding: 0;
            color: var(--white);
        }

        .oma-link-wrapper a, .oma-link-wrapper a:active, .oma-link-wrapper a:visited, .oma-link-wrapper a:focus {
            color: var(--cyan);
        }
    }
}
