﻿.oma-cta-with-dark-background {
    background-color: var(--dark-blue);

    .oma-cta-block-text {
        .oma-cta-title {
            margin-bottom: 20px;
            margin-top: 0;
        }

        a:hover {
            color: #fff;
        }
    }
}