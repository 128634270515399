.help-me-choose-block-container {
    button {
        margin-left: 10px;

        &:first-child {
            margin-left: 0;
        }
    }

    .cat-description {
        font-family: var(--proxima-nova-regular);
        color: var(--black);
        font-size: 1.125rem;
        white-space: normal;
        text-decoration-color: var(--black);
    }

    .concerns {
        margin-bottom: 30px;
    }

    h2 {
        margin-bottom: 20px;
        white-space: normal;
    }

    .step-subtitle {
        font-family: var(--proxima-nova-light);
        line-height: 22px;
        font-size: 16px;
        margin-bottom: 20px;
    }

    .step-counter {
        text-align: right;
    }

    .choiceResults {
        display: block;
        width: 100%;
        margin-left: 0;
        white-space: nowrap;
        overflow-x: visible;
        text-align: center;
        animation: ease-in;
        animation-duration: 0.2s;
    }

    .step {
        margin: 0;
        padding: 0;

        ul {
            list-style-type: none;

            li {
            }
        }
    }

    .step1, .step2, .step3, .step4 {
        width: 100%;
        display: inline-block;
        margin: 0;
    }

    .step1, .step2 {
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;
            justify-content: center;
            column-gap: 30px;
            padding-top: 4em;
            padding-bottom: 1em;

            li {
                flex-basis: 30%;
                margin-bottom: 3em;

                img {
                    width: 100%;
                    margin-bottom: 10px;
                    border: 4px solid transparent;
                    transition: all 0.3s;
                }

                a, a:active, a:visited, a:focus {
                    color: var(--black);

                    &:hover, &.active {
                        text-decoration: underline;

                        img {
                            border-color: #0088CE;
                        }
                    }
                }
            }
        }
    }

    .step3 {
        ul {
            max-width: 540px;
            margin: 0 auto;
            text-align: left;

            li {
                display: flex;

                label {
                    display: block;
                    white-space: pre-wrap;
                }

                input[type="checkbox"] {
                    width: 20px;
                    height: 20px;
                    flex-shrink:0;
                    vertical-align: middle;
                    margin-right: 1em;
                }
            }
        }
    }

    .step4 {
        text-align: left;

        .quiz-buttons {
            margin-left: 1em;

            .retake-quiz {
                background-color: var(--white);
                color: #0088ce;
                font-family: var(--proxima-nova-bold);
                border: 0;
            }
        }

        .product-listing {
            text-align: left;

            .product-list {
                display: flex;
                flex-wrap: wrap;
                list-style-type: none;
                margin: 0;
                padding: 0;
                justify-content: space-evenly;

                li {
                    min-height: 202px;
                    flex-basis: 30%;
                    margin: 4em 0;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 1139px) {
        .step1, .step2 {
            ul {


                li {
                    flex-basis: 40%;
                    max-width: 40%;
                }
            }
        }

        .step3 {
            ul {
                padding: 0;
            }
        }
    }
}

.choice-navigator {
    text-align: center;
}

.help-me-choose-summary-container {
    margin-bottom: 30px;

    & .steps {
        max-width: inherit;
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 40px;
    }

    & .steps li {
        margin: 30px;
        counter-increment: my-awesome-counter;
        position: relative;
        list-style-type: none;
    }

    & .steps li::before {
        content: counter(my-awesome-counter);
        color: #006DA5;
        font-family: var(--proxima-nova-bold);
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        --size: 32px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        background: #EAF6F6;
        border-radius: 50%;
        text-align: center;
        transform: translateY(-15%);
    }

    .buttons {
        padding-left: 40px;

        & > *:last-child {
            margin-left: 10px;
        }
    }
}

.btn-transparent {
    background-color: transparent;
    border-color: transparent;
    color: var(--blue);

    &:hover {
        color: var(--dark-blue);
        background-color: transparent !important;
        border-color: #0062cc !important;
    }

    &:focus {
        color: #fff;
        background-color: #0069d9 !important;
        border-color: #0062cc !important;
    }
}

.btn-info {
    &:focus {
        color: #fff;
        background-color: #0069d9 !important;
        border-color: #0062cc !important;
    }

    &:hover {
        border-color: #0062cc !important;
    }
}
.help-me-choose-results-recommended {
    margin-bottom: 30px;

    .block-title > * {
        text-align: center;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        justify-content: space-evenly;

        li {
            min-height: 202px;
            flex-basis: 30%;

            h3 {
                color: #006DA5;
            }

            p {
                display: inline-block;

                &:hover {
                    text-decoration: none;
                }
            }

            img {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    @media (max-width: 991.98px) {
        ul {
            li {
                flex-basis: 100%;
            }
        }
    }
}
