﻿.article-resource {
    border: 1px solid #ddddde;
    border-left: 8px solid #b5d6e3;
    background: #edf5f9;
    padding-bottom: 20px;
    margin-bottom: 20px;

    h3 {
        font-size: 26px;
        color: #222d82;
        margin-left: 20px
    }

    .article-resource-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .article-resource-item {
            max-width: 320px;

            a, a:active, a:visited, a:focus {
                color: #0087ce
            }
        }

        img {
            width: 320px;
            height: 210px
        }

        .caption {
            margin-top: 5px;
            font-size: 19px;
            font-weight: 700;
            color: #0087ce
        }
    }
}

@media screen and (min-width:1366px) {
    .article-resource {
        width: 927px
    }
}

@media screen and (min-width:1366px) {
    .article-resource {
        .article-resource-images {
            .article-resource-item {
                max-width: 405px;
            }
        }
    }

    .article-resource {
        .article-resource-images {
            img {
                width: 405px;
                height: 228px;
            }
        }
    }
}
