.oma-marketplace-company-block {
    margin-bottom: $v-margin-between-blocks;

    .mat-expansion-panel-header, .mat-expansion-panel-header-title {
        outline: none;
    }

    .mat-expansion-indicator::after {
        border-style: solid;
        border-width: 0 2px 2px 0;
        content: '';
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        vertical-align: middle;
    }

    .flip {
        -moz-transition: transform 1s;
        -webkit-transition: transform 1s;
        transition: transform 1s;
        transform: rotate(-180deg);
    }

    .mat-expansion-panel-header {
        display: flex;
        justify-content: space-between;
    }

    .emarketplace-header .breadcrumbs {
        padding-top: 0 !important;
    }

    .emarketplace .emarketplace-header-container {
        width: 100%;
        height: 50px;
        max-height: 62px;
        background-color: #fff;
        padding: 20px;
        max-width: 1366px;
        margin: auto;
    }

    .emarketplace .emarketplace-header-container .title {
        font-size: 24px;
        font-weight: 700;
        color: #14284b;
    }

    .emarketplace .emarketplace-header-container .left {
        float: left;
    }

    .emarketplace {
        margin: auto;
        border-radius: 3px;
    }

    .emarketplace > div {
        width: 100%;
        border-radius: 5px;
    }

    .emarketplace .emarketplace-intro-container {
        display: flex;
    }

    .emarketplace .emarketplace-intro-container .welcome-div {
        width: 75%;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        padding-left: 15px;
        padding-right: 15px;
        float: left;
        margin-top: 20px;
        clear: both;
        overflow-wrap: break-word;
        max-height: 300px;
        overflow: auto;
    }

    @media only screen and (max-width: 649px) {
        .emarketplace .emarketplace-intro-container {
            flex-direction: column;
        }

        .emarketplace .emarketplace-intro-container .welcome-div {
            max-height: inherit;
            width: 91%;
            margin-bottom: 20px
        }
    }

    .emarketplace .emarketplace-intro-container .color-fill-div {
        width: 24%;
        background-color: #fcd672;
        border: 1px solid #fcd672;
        border-radius: 5px;
        margin-top: 20px;
        margin-left: 20px;
        min-width: 318px
    }

    .emarketplace .emarketplace-intro-container .color-fill-div .outer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .first-inner-div {
        font-weight: 600;
        font-size: 24px;
        color: #000;
        margin-top: 60px;
        margin-bottom: 25px;
    }

    @media screen and (max-width: 649px) {
        .emarketplace .emarketplace-intro-container .color-fill-div {
            width: 100%;
            margin: 0;
        }

        .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .first-inner-div {
            margin-top: 6px;
        }
    }

    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .second-inner-div .button {
        background-color: #00859b;
        border: 1px solid #588dca;
        color: #fff;
        transform: translate(-50%, -50%);
        height: 100%;
        left: 50%;
        margin-left: 50%;
        margin-right: 50%;
        width: 100%;
        border-radius: 3px;
        overflow-wrap: break-word;
        max-width: 250px;
        max-height: 25px;
        padding: 10px;
        text-align: center;
    }

    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .second-inner-div .button a,
    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .second-inner-div .button a:active,
    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .second-inner-div .button a:visited,
    .emarketplace .emarketplace-intro-container .color-fill-div .inner-div .second-inner-div .button a:focus {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
    }

    .emarketplace .emarketplace-filters-container {
        display: inline-block;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters {
        padding: 0 15px;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel {
        box-shadow: none !important;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-header {
        padding: 0;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-header:hover {
        background: 0 0 !important;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-header:focus {
        background: 0 0 !important;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-header:active {
        background: 0 0 !important;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator::after {
        border: solid #4d526e;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 2.5px;
        transform: rotate(45deg);
        position: relative;
        top: -3px;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-body {
        padding: 0 0 10px;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.enable-expansion-panel {
        box-shadow: none !important;
        background: rgba(227, 227, 227, .1);
        border-radius: 0;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.enable-expansion-panel.mat-expanded {
        border: 0;
    }

    .emarketplace .emarketplace-filters-container .mobile-filters.enable-expansion-panel .mat-expansion-panel-header {
        border-radius: 0;
    }

    .emarketplace .emarketplace-filters-container .filters-sub-container {
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 5px;
        padding-left: 15px;
        overflow-wrap: break-word;
        margin-bottom: 20px;
    }

    .emarketplace .emarketplace-filters-container .accordion-container {
        margin-bottom: 20px;
    }

    .emarketplace .emarketplace-filters-container .accordion-container .mat-checkbox {
        cursor: auto;
    }

    .emarketplace .emarketplace-filters-container .accordion-container .mat-expansion-panel-header-title header {
        display: flex;
    }

    .emarketplace .emarketplace-filters-container .accordion-container .mat-expansion-panel-header .mat-checkbox-inner-container {
        width: 15.6px;
        height: 15.6px;
        margin-top: 3px;
        margin-left: 8px;
    }

    .emarketplace .emarketplace-filters-container .accordion-header-title {
        font-size: 16px;
        font-weight: 700;
        color: #63666a;
        line-height: 22px;
    }

    .emarketplace .emarketplace-filters-container .accordion-header-title-selected {
        color: #00859b;
    }

    .emarketplace .emarketplace-filters-container .accordion-body-container {
        width: 99%;
        display: flex;
        flex-wrap: wrap;
    }

    .emarketplace .emarketplace-filters-container .accordion-body-container .mat-checkbox {
        cursor: pointer
    }

    .emarketplace .emarketplace-filters-container .accordion-body-checkbox-container {
        position: relative;
        width: 392px;
        border: 1px solid #efefef;
        border-radius: 5px;
        padding: 14px 10px 10px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .emarketplace .emarketplace-filters-container .accordion-body-checkbox-container .mat-checkbox-inner-container-no-side-margin {
        margin-top: -5px;
    }

    .emarketplace .emarketplace-filters-container .accordion-body-checkbox-container-selected {
        background-color: #bfe0dd;
        border: 1px solid #7fc2bb;
        border-radius: 5px;
    }

    .emarketplace .emarketplace-filters-container .accordion-body-checkbox-text {
        font-size: 12px;
        font-weight: 700;
        color: #63666a;
        line-height: 14pt;
    }

    .emarketplace .round {
        position: relative;
        margin: 0 5px;
    }

    .emarketplace .round label {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 50%;
        cursor: auto;
        height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
    }

    .emarketplace .round label:after {
        border: 2px solid #cecfd0;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 3px;
        opacity: .5;
        position: absolute;
        top: 4px;
        transform: rotate(-45deg);
        width: 12px;
    }

    .emarketplace .accordion-body-checkbox-container > .round label::after {
        opacity: 0;
        cursor: pointer;
    }

    .emarketplace .round input[type=checkbox] {
        margin: 0 6px;
    }

    .emarketplace .round input[type=checkbox]:checked + label {
        background-color: #00859b;
        border-color: #00859b;
    }

    .emarketplace .round input[type=checkbox]:checked + label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        opacity: 1;
    }

    .emarketplace .title {
        font-size: 24px;
        font-weight: 700;
        color: #000;
        line-height: 22pt;
        margin-top: 20px;
    }

    .emarketplace .description {
        font-size: 16px;
        color: #63666a;
        line-height: 20pt;
        margin-top: 15px;
    }

    .emarketplace .detail {
        font-size: 14px;
        color: #63666a;
        line-height: 18pt;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .emarketplace .form-field {
        width: 30% !important;
    }

    .emarketplace .search-button-container {
        padding-left: 15px;
        margin-bottom: 20px;
    }

    .emarketplace .action-buttons {
        text-align: left;
    }

    @media screen and (min-width: 650px) {
        .emarketplace .emarketplace-filters-container {
            height: 75%;
        }

        .emarketplace .action-buttons {
            text-align: right;
        }
    }

    @media screen and (min-width: 1366px) {
        .emarketplace .emarketplace-filters-container .mobile-filters.mat-expansion-panel .mat-expansion-panel-body {
            padding-bottom: 0;
        }

        .emarketplace .action-buttons {
            display: flex;
        }
    }

    .emarketplace .action-buttons button {
        width: 167px;
        height: 36px;
        font-size: 16px;
        line-height: 26px;
    }

    .emarketplace .action-buttons button a {
        display: block;
        text-decoration: none;
    }

    .emarketplace .action-buttons #emarketplace-search {
        background-color: #00859b;
        border: 1px solid #00859b;
        margin-right: 10px;
        color: #fff;
    }

    .emarketplace .action-buttons #emarketplace-search a, .emarketplace .action-buttons #emarketplace-search a:active,
    .emarketplace .action-buttons #emarketplace-search a:visited,.emarketplace .action-buttons #emarketplace-search a:focus {
        color: #fff;
    }

    .emarketplace .search-counter-container {
        font-size: 24px;
        ;
        padding: 45px 10px 15px;
        margin-bottom: 10px;
    }

    .emarketplace .search-content-wrapper {
        background-color: #fff;
        border-radius: 5px;
        padding: 5px;
    }

    .emarketplace .search-accordion-header-title {
        font-size: 24px;
        line-height: 22px;
        width: 100%;
    }

    .emarketplace .info-text {
        font-size: 16px;
        line-height: 22px;
    }

    .emarketplace .description-wrapper {
        border-top: .5px solid #bedc92;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .emarketplace .description-container {
        width: 80%;
        margin-left: 1%;
        margin-top: 2%;
        margin-bottom: 5%;
    }

    .emarketplace .contact-container {
        margin-left: 1%;
        padding-bottom: 2%;
        border-bottom: 1px solid #f6f6f6;
        font-size: 16px;
        line-height: 28px;
    }

    .emarketplace .contact-container .phone-number {
        font-weight: bold;
        font-size: 16px;
    }

    .emarketplace .contact-container .address p {
        margin: 0;
        line-height: 28px;
    }

    .emarketplace .service-tags-container {
        background-color: #fff;
        padding: 10px 0;
        margin-left: 3%;
    }

    .emarketplace .tag-header {
        font-size: 16px;
        font-weight: bold;
        margin-left: 3%;
    }

    .emarketplace .tag {
        margin-bottom: 1%;
    }

    .emarketplace mat-checkbox {
        border-radius: 50% !important;
    }

    .emarketplace mat-checkbox .mat-checkbox-inner-container {
        height: 20px;
        width: 20px;
    }

    .emarketplace mat-checkbox .mat-ink-ripple {
        background-color: #00859b;
        opacity: 0;
    }

    .emarketplace mat-checkbox .mat-checkbox-inner-container-no-side-margin {
        margin-left: 5px;
        margin-right: 0;
    }

    .emarketplace mat-checkbox .mat-checkbox-background,
    .emarketplace mat-checkbox .mat-checkbox-frame {
        border-radius: 50% !important;
        border: 1px solid #cecfd0;
    }

    .emarketplace mat-checkbox.mat-checkbox-focused .mat-ink-ripple,
    .emarketplace mat-checkbox.mat-checkbox-checked .mat-ink-ripple {
        background-color: #00859b;
    }

    .emarketplace mat-checkbox.mat-checkbox-focused .mat-checkbox-inner-container,
    .emarketplace mat-checkbox.mat-checkbox-checked .mat-checkbox-inner-container {
        height: 20px;
        width: 20px;
    }

    .emarketplace mat-checkbox.mat-checkbox-focused .mat-checkbox-background,
    .emarketplace mat-checkbox.mat-checkbox-focused .mat-checkbox-frame,
    .emarketplace mat-checkbox.mat-checkbox-checked .mat-checkbox-background,
    .emarketplace mat-checkbox.mat-checkbox-checked .mat-checkbox-frame {
        background-color: #00859b;
        border-radius: 50% !important;
    }

    .emarketplace mat-accordion .mat-accordion .mat-expansion-panel:last-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .emarketplace mat-accordion .mat-accordion .mat-expansion-panel:first-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .emarketplace mat-accordion .mat-expansion-panel:not([class*=mat-elevation-z]) {
        box-shadow: none;
    }

    .emarketplace mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
        background: #fff;
    }

    .emarketplace .example-chip-list {
        width: 100%;
    }

    .mat-chip {
        text-decoration: none;
        color: var(--black);
        display: inline-block;
        background: #e0e0e0;
        border-radius: 32px;
        padding: 7px 16px;
        margin-right: 3px;
        margin-bottom: 5px;
    }
}
