﻿.wide-featured-content-container {
    justify-content: center;
    margin-bottom: 30px;

    .wide-featured-content {
        display: flex;
        text-align: left;
        margin: 0;
        justify-content: center;
        margin-bottom: 30px;

        & > .wide-featured-content-image-container {
            position: relative;
            padding: 0;


            @media (min-width: 992px) {
                max-width: 255px;
            }

            & > .wide-featured-content-image {
                object-fit: cover;
                height: 144px;
                width: 100%;

                @media (max-width: 991px) {
                   height:277px;
                }
            }

            & > .wide-featured-content-new-flag {
                background-color: #FCB53B;
                position: absolute;
                right: 0;
                top: 6%;
                font-size: 12px;
                font-weight: bold;
                padding: 2px 10px;
            }
        }

        & > .wide-featured-content-text {
            margin: 5px 0px;
            margin-top: 10px;

            @media (max-width: 991px) {
                padding: 0;
            }

            & > .wide-featured-content-title {
                font-size: 24px;
                color: #006DA5;
                cursor: pointer;
                line-height: 28px;
                margin-bottom: 8px;
                display: inline-block;
            }

            & > .wide-featured-content-caption {
                font-family: var(--proxima-nova-light);
                font-size: 16px;
            }
        }
    }
}
