﻿.oma-masonry-container-block {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fill, minmax(350px,1fr));
    grid-auto-rows: 30px;
    margin: 0 auto;

    > .oma-publication-article-tile-block {
        width: auto;
    }

    .oma-publication-article-tile-block-content {
        min-width: 350px;
        width: auto;
    }

    @include media-breakpoint-down(lg) {
        &[class*="col-"]{
            max-width:100%;
        }
    }

    &.center-titles {
        .oma-publication-article-tile-block-content {
            .oma-publication-article-text {
                .oma-publication-article-headline {
                    text-align: center;
                }
            }
        }
    }

    & > * {
        display: block;
    }
}
