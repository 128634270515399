﻿.careers {
    /*background: #f5f5f5;*/
    color: #14284b;
    padding-bottom: 20px;
}

.careers .intro {
    margin: 20px auto auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    padding-bottom: 20px;
    flex-direction: column;
}

.careers .intro .join-our-team {
    background: #fff;
    border-radius: 4px;
    padding-bottom: 20px;
}

.careers .intro .join-our-team .title {
    font-size: 24px;
    font-family: var(--proxima-nova-regular);
    padding: 15px 20px 10px;
    color: #000;
}

.careers .intro .join-our-team .content {
    font-size: 14px;
    padding: 0 20px;
    color: #63666a;
}

.careers .intro .key-contact {
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;
    padding-bottom: 20px;
}

@media screen and (min-width:1366px) {
    .careers .intro {
        max-width: 1366px;
        padding-left: 20px;
        padding-right: 20px;
        flex-direction: row;
        justify-content: space-between;
        color: #63666a;
    }

    .careers .intro .join-our-team {
        width: 996px;
    }

    .careers .intro .key-contact {
        width: 314px;
        margin-top: 0;
    }
}

.careers .intro .key-contact .title {
    font-size: 24px;
    padding: 20px;
    font-family: var(--proxima-nova-light);
    font-weight: 600;
    color: #000;
}

.careers .intro .key-contact .content {
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
}

.careers .current-opportuinties {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.careers .current-opportuinties .loading-item {
    padding: 10px 20px;
    background: #fff;
    margin-bottom: 20px;
}

.careers .current-opportuinties .title {
    font-size: 22px;
    font-family: var(--proxima-nova-regular);
    padding-bottom: 20px;
    margin-top: 15px;
}

.careers .current-opportuinties .job-list .job-list-item {
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #e3e3e3;
    padding-top: 6px;
    padding-bottom: 6px;

}

.careers .current-opportuinties .job-list .job-list-item .job-summary .job-title {
    font-size: 18px;
    line-height: 24px;
    padding: 10px 20px 0;
    font-family: var(--proxima-nova-light);
    font-weight: 600;
    margin-bottom: 5px;
}

.careers .current-opportuinties .job-list .job-list-item .job-summary .job-department {
    color: #63666a;
    font-size: 14px;
    line-height: 18px;
    padding: 0 20px;
    font-family: var(--proxima-nova-light);
    font-weight: 600;
}

.careers .current-opportuinties .job-list .job-list-item .job-summary .job-description {
    color: #63666a;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 20px 20px;
}

.careers .current-opportuinties .job-list .job-list-item .action-buttons {

        padding: 0 20px;
        margin-bottom: 14px;
        @media screen and (max-width:480px){
            margin-top:7px;
            margin-bottom:7px;
        }
        @media screen and (min-width:480px){
             display: flex;
        }
       
}

@media screen and (min-width:1366px) {
    .careers .current-opportuinties {
        max-width: 1366px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .careers .current-opportuinties .job-list .job-list-item {
        flex-direction: row;
        height: auto;
    }

    .careers .current-opportuinties .job-list .job-list-item .job-summary {
        max-width: 1134px;
    }

    .careers .current-opportuinties .job-list .job-list-item .action-buttons {
        margin-bottom: 0;
    }

    .careers .current-opportuinties .job-list .job-list-item .action-buttons a {
        margin-top: 60px;
    }
}

.careers .current-opportuinties .job-list .job-list-item .action-buttons a {
    display: block;
    text-decoration: none;
    height: 30px;
    width: 90px;
    font-size: 12px;
    font-weight:400;
    line-height: 26px;
    margin: auto;
   
}

.careers .current-opportuinties .job-list .job-list-item .action-buttons .learn-more {
    background-color: transparent;
    margin-right: 10px;
    color: var(--blue);
    border-color:transparent;
    &:hover{
       text-decoration: underline;
    }
}

.careers .current-opportuinties .job-list .job-list-item .action-buttons .apply {
    background-color: var(--dark-blue);
    color: #fff;

    &:hover {
        background-color: var(--bright-blue);
    }
}

.careers {
    .action-buttons {
        .mat-button .mat-button-focus-overlay,
        .mat-icon-button .mat-button-focus-overlay {
            opacity: 0
        }

        .mat-button:hover .mat-button-focus-overlay,
        .mat-stroked-button:hover .mat-button-focus-overlay {
            opacity: .04
        }

        @media (hover:none) {
            .mat-button:hover .mat-button-focus-overlay,
            .mat-stroked-button:hover .mat-button-focus-overlay {
                opacity: 0
            }
        }

        .mat-button,
        .mat-flat-button,
        .mat-icon-button,
        .mat-stroked-button {
            box-sizing: border-box;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            display: inline-block;
            white-space: nowrap;
            text-decoration: none;
            vertical-align: baseline;
            text-align: center;
            margin: 0;
            min-width: 64px;
            line-height: 36px;
            padding: 0 16px;
            border-radius: 4px;
            overflow: visible
        }

        .mat-button::-moz-focus-inner,
        .mat-flat-button::-moz-focus-inner,
        .mat-icon-button::-moz-focus-inner,
        .mat-stroked-button::-moz-focus-inner {
            border: 0
        }

        .mat-button[disabled],
        .mat-flat-button[disabled],
        .mat-icon-button[disabled],
        .mat-stroked-button[disabled] {
            cursor: default
        }

        .mat-button.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-button.cdk-program-focused .mat-button-focus-overlay,
        .mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-flat-button.cdk-program-focused .mat-button-focus-overlay,
        .mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
        .mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-stroked-button.cdk-program-focused .mat-button-focus-overlay {
            opacity: .12
        }

        .mat-button::-moz-focus-inner,
        .mat-flat-button::-moz-focus-inner,
        .mat-icon-button::-moz-focus-inner,
        .mat-stroked-button::-moz-focus-inner {
            border: 0
        }

        .mat-raised-button {
            box-sizing: border-box;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            display: inline-block;
            white-space: nowrap;
            text-decoration: none;
            vertical-align: baseline;
            text-align: center;
            margin: 0;
            min-width: 64px;
            line-height: 36px;
            padding: 0 16px;
            border-radius: 4px;
            overflow: visible;
            transform: translate3d(0, 0, 0);
            transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1)
        }

        .mat-raised-button::-moz-focus-inner {
            border: 0
        }

        .mat-raised-button[disabled] {
            cursor: default
        }

        .mat-raised-button.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-raised-button.cdk-program-focused .mat-button-focus-overlay {
            opacity: .12
        }

        .mat-raised-button::-moz-focus-inner {
            border: 0
        }

        ._mat-animation-noopable.mat-raised-button {
            transition: none;
            animation: none
        }

        .mat-stroked-button {
            border: 1px solid currentColor;
            padding: 0 15px;
            line-height: 34px
        }

        .mat-stroked-button .mat-button-focus-overlay,
        .mat-stroked-button .mat-button-ripple.mat-ripple {
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px
        }

        .mat-fab {
            box-sizing: border-box;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            display: inline-block;
            white-space: nowrap;
            text-decoration: none;
            vertical-align: baseline;
            text-align: center;
            margin: 0;
            min-width: 64px;
            line-height: 36px;
            padding: 0 16px;
            border-radius: 4px;
            overflow: visible;
            transform: translate3d(0, 0, 0);
            transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
            min-width: 0;
            border-radius: 50%;
            width: 56px;
            height: 56px;
            padding: 0;
            flex-shrink: 0
        }

        .mat-fab::-moz-focus-inner {
            border: 0
        }

        .mat-fab[disabled] {
            cursor: default
        }

        .mat-fab.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-fab.cdk-program-focused .mat-button-focus-overlay {
            opacity: .12
        }

        .mat-fab::-moz-focus-inner {
            border: 0
        }

        ._mat-animation-noopable.mat-fab {
            transition: none;
            animation: none
        }

        .mat-fab .mat-button-wrapper {
            padding: 16px 0;
            display: inline-block;
            line-height: 24px
        }

        .mat-mini-fab {
            box-sizing: border-box;
            position: relative;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            outline: 0;
            border: none;
            -webkit-tap-highlight-color: transparent;
            display: inline-block;
            white-space: nowrap;
            text-decoration: none;
            vertical-align: baseline;
            text-align: center;
            margin: 0;
            min-width: 64px;
            line-height: 36px;
            padding: 0 16px;
            border-radius: 4px;
            overflow: visible;
            transform: translate3d(0, 0, 0);
            transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
            min-width: 0;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            padding: 0;
            flex-shrink: 0
        }

        .mat-mini-fab::-moz-focus-inner {
            border: 0
        }

        .mat-mini-fab[disabled] {
            cursor: default
        }

        .mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay,
        .mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
            opacity: .12
        }

        .mat-mini-fab::-moz-focus-inner {
            border: 0
        }

        ._mat-animation-noopable.mat-mini-fab {
            transition: none;
            animation: none
        }

        .mat-mini-fab .mat-button-wrapper {
            padding: 8px 0;
            display: inline-block;
            line-height: 24px
        }

        .mat-icon-button {
            padding: 0;
            min-width: 0;
            width: 40px;
            height: 40px;
            flex-shrink: 0;
            line-height: 40px;
            border-radius: 50%
        }

        .mat-icon-button .mat-icon,
        .mat-icon-button i {
            line-height: 24px
        }

        .mat-button-focus-overlay,
        .mat-button-ripple.mat-ripple {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
            pointer-events: none;
            border-radius: inherit
        }

        .mat-button-ripple.mat-ripple:not(:empty) {
            transform: translateZ(0)
        }

        .mat-button-focus-overlay {
            opacity: 0;
            transition: opacity .2s cubic-bezier(.35, 0, .25, 1), background-color .2s cubic-bezier(.35, 0, .25, 1)
        }

        ._mat-animation-noopable .mat-button-focus-overlay {
            transition: none
        }

        @media (-ms-high-contrast:active) {
            .mat-button-focus-overlay {
                background-color: #fff
            }
        }

        @media (-ms-high-contrast:black-on-white) {
            .mat-button-focus-overlay {
                background-color: #000
            }
        }

        .mat-button-ripple-round {
            border-radius: 50%;
            z-index: 1
        }

        .mat-button .mat-button-wrapper > *,
        .mat-fab .mat-button-wrapper > *,
        .mat-flat-button .mat-button-wrapper > *,
        .mat-icon-button .mat-button-wrapper > *,
        .mat-mini-fab .mat-button-wrapper > *,
        .mat-raised-button .mat-button-wrapper > *,
        .mat-stroked-button .mat-button-wrapper > * {
            vertical-align: middle
        }

        .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button,
        .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
            display: block;
            font-size: inherit;
            width: 2.5em;
            height: 2.5em
        }

        @media (-ms-high-contrast:active) {
            .mat-button,
            .mat-fab,
            .mat-flat-button,
            .mat-icon-button,
            .mat-mini-fab,
            .mat-raised-button {
                outline: solid 1px
            }
        }
    }
}
