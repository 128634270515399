﻿html {
    overflow-y: auto;
}

body {
    font-family: var(--proxima-nova-regular);
}

body, html {
    max-width: 100%;
    overflow-x: hidden;
    min-height: 100%;
    position: relative;
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--proxima-nova-regular);

    strong, b {
        font-weight: normal;
    }
}

h1 {
    font-size: 2.5rem;
    line-height: 50px;
    color: var(--dark-blue);
    margin-bottom: 30px;
}

h2 {
    font-size: 2.25rem;
    line-height: 40px;
    color: var(--black);
    font-family: var(--proxima-nova-light);
}

h3 {
    font-size: 1.875rem;
    line-height: 36px;
    color: var(--black);
    font-family: var(--proxima-nova-light);

    &.link {
        font-family: var(--proxima-nova-regular);
    }
}

h4 {
    font-size: 1.5rem;
    line-height: 28px;
    color: var(--black);

    &.link {
        color: var(--blue);
    }
}

h5 {
    font-size: 1.125rem;
    font-family: var(--proxima-nova-regular);
    line-height: 24px;
    color: var(--black);
}

p {
    font-size: 1rem;
    font-family: var(--proxima-nova-light);
    line-height: 1.5em;
    color: var(--black);

    strong, b {
        font-family: var(--proxima-nova-regular);
    }

    a {
        text-decoration: underline;

        &:hover {
            color: var(--dark-blue);
        } 
    }
}

a, a:active, a:visited, a:focus {
    color: var(--blue);
}


img {
    max-width: 100%;
}

input[type="date"]:before {
    color: lightgrey;
    content: attr(placeholder) !important;
    margin-right: 0.5em;
}

input[type="date"]:focus:before {
    content: '' !important;
}

input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
    color: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    opacity: 1;
    background: url(/Static/Images/Calendar_Icon.png) no-repeat center;
    background-size: contain;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-repeat: no-repeat;
    background-image: url(/Static/Images/down_arrow.svg);
    background-size: 12px;
    background-position: right 15px center;
}

.collapsing {
    @include transition(height 0.35s ease);
}

.form-control {
    border-color: var(--dark-grey);
}

.oma-cta-link {
    font-size: 16px;
    line-height: 20px;
    font-family: var(--proxima-nova-semibold);
    color: var(--blue);
}

.m-page-text {
    font-size: 14px;
    line-height: 20px;
	font-family: var(--proxima-nova-light);
    color: var(--black);
}

.oma-small-light {
    font-size: 14px;
    line-height: 19px;
    font-family: var(--proxima-nova-light);
    color: var(--black);
}
.m-page-text {
    font-size: 14px;
    line-height: 20px;
    font-family: var(--proxima-nova-light);
    color: var(--black);
}

.oma-btn {
    background-color: var(--dark-cyan);
    width: auto;
    overflow: hidden;
    font-size: 15px;
    min-height: 30px;
    padding: 2px 15px;
    font-family: var(--proxima-nova-semibold);

    &:disabled {
        background-color: #f5f5f5;
        color: #212121;
        border-color:gray;
    }

    &:hover:enabled {
        background-color: var(--dark-blue);
    }
}
.btn-info, .btn-primary {
    &:not([disabled]) {
        color: var(--white) !important;
    }
}
.oma-btn-link {
    padding: 2px 15px;
}

.show-on-focus-container {
    background: #fff;

    .column {
        margin: 0 auto;
        width: 25%;
        text-align: center;

        .show-on-focus {
            color: #000;
            clip: rect(1px,1px,1px,1px);
            height: 1px;
            overflow: hidden;
            position: absolute !important;
            width: 1px;
        }

        .show-on-focus:active,
        .show-on-focus:focus {
            position: static !important;
            height: auto;
            width: auto;
            overflow: visible;
            clip: auto;
        }
    }
}