﻿// OMA Resource Card Block
.oma-resource-card {
    border: 1px solid #D5D6D6;
    padding: 10px 15px 20px;
    margin-bottom: 25px;

    .source-text {
        font-family: var(--proxima-nova-regular);
        font-size: 18px;
        line-height: 24px;
        margin: 0;
    }
}

// OMA Resource Link Block
.oma-resource-link {
    margin-bottom: 25px;

    .link-title {
        margin-bottom: 15px;
        padding-top: 15px;
        padding-right: 20px;
        margin-top: 0;

        .link-icon {
            max-height: 24px;
            bottom: 4px;
            position: relative;
            padding-left: 4px;
        }
    }

    .link-title a {
        font-size: 30px;
        line-height: 36px;
        font-family: var(--proxima-nova-light);
        color: var(--blue);
    }

    .link-description, .link-description p {
        font-family: var(--proxima-nova-light);
        font-size: 16px;
        line-height: 22px;
    }

    .tag {
        
        background-color: var(--bright-blue);
        font-size: 12px;
        padding: 2px 1em 0 1em;
        position: absolute;
        right: 0;
        color: white;
        text-transform: uppercase;
        font-family: 'Proxima Nova Semibold';
    }
}
