﻿.oma-split-column-container {
    display: flex;

    & > .left-column {
        width: 66%;
        margin-right: 1em;
    }

    & > .right-column {
        width: 33%;
        padding-left: 1em;
    }

    .left-column, .right-column {
        .cta-block {
            padding: 0 0 0 14px;

            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .oma-text-block {
            h2, h3, h4 {
                margin-top: 32px;
            }
        }

        .oma-text-block:first-of-type h2:first-of-type:first-child, h3:first-of-type:first-child, h4:first-of-type:first-child {
            margin-top: 0;
        }
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;

        & > .left-column {
            width: 100%;
        }

        & > .right-column {
            width: 100%;
            padding-left: initial;
        }
    }
}
