
#pcfaForm, #pafForm {
    :root {
        --invalid: #D90B0B;
        --valid: #636363;
    }

    ul.inputs li .error {
        background-color: rgba(217,11,11,0.15);
        padding: 10px;
        grid-column-start: 1;
    }

    form ul.inputs, ul.inputs {
        display: grid;
        grid-template-columns: repeat(8, 12.5%);
        column-gap: 10px;
        grid-row-gap: 15px;
    }

    .wrapper {
        max-width: 700px;
        margin: 0 auto 2em;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ul.inputs .grid-half {
        grid-column-start: span 4;
    }

    ul.inputs .grid-full {
        grid-column-start: span 8;
    }

    ul.inputs li label {
        display: block;
        font-weight: bold;
    }

    ul.inputs li input, ul.inputs li textarea, ul.inputs li select {
        display: block;
        width: 100%;
        border: 1px solid #CCC;
        height: 3em;
        padding-left: 1em;
    }

    ul.inputs li input[type="checkbox"] {
        width: 2em;
        height: 1.5em;
    }

    ul.inputs li ul.checkboxes {
        margin-left: 1em;
    }

    ul.inputs li ul.checkboxes li {
        border-bottom: 1px solid #f0f0f0;
        display: grid;
        grid-template-columns: 9fr 1fr;
        gap: 1em;
        padding: 1em 0;
    }

    ul.inputs li ul.checkboxes li label {
        display: inline;
        font-weight: normal;
        grid-column-start: 1;
    }

    .header {
        margin: 0 auto;
        padding-bottom: 3em;
        text-align: center;
    }

    ul.inputs li ul.checkboxes li input[type="checkbox"] {
        display: inline;
        grid-column-start: 2;
        width: 2em;
        height: 1.5em;
        padding-left: 0;
        margin-right: 2rem;
    }

    p.notice {
        padding: 1em;
        border: 2px solid #000;
    }

    ul.inputs li input[type="submit"] {
        width: auto;
    }
    input[type="date"]:in-range::-webkit-datetime-edit-year-field,
    input[type="date"]:in-range::-webkit-datetime-edit-month-field,
    input[type="date"]:in-range::-webkit-datetime-edit-day-field,
    input[type="date"]:in-range::-webkit-datetime-edit-text {
        color: initial;
    }
}

#pafForm {
    ul.inputs {
        select[multiple='multiple'] {
            height: 25rem;
        }
    }

    ul.inputs .grid-half.demographics {
        grid-row: span 4;

        ul li {
        }
    }

    .pafbranches ul, .pafaudiences ul {
        margin-left: 1em;

        li {
            margin-bottom: 0;
        }
    }

    .rounded {
        border: 2px solid #333;
        padding: 1em;
        border-radius: 3px;
    }
}
