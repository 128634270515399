.oma-quick-links {
    margin-bottom: $v-margin-between-blocks;

    .oma-quick-links-title {
        margin-top: 0;
        font-size: 1.875rem;
        line-height: 36px;
        font-family: var(--proxima-nova-light);
    }

    &__link-wrapper {
        margin-top: 16px;
    }

    &__link {
        font-family: var(--proxima-nova-semibold);
        font-size: 16px;
        line-height: 20px;
        position: relative;
    }

    &__link_white, &__link_white:focus {
        font-family: var(--proxima-nova-regular);
        color: var(--white);

        &:active {
            color: var(--white);
        }

        &:visited {
            color: var(--white);
        }

        &:hover {
            color: var(--white);
        }
    }

    &__icon {
        width: 16px;
        max-height: 16px;
        position: absolute;
        bottom: 2px;
        right: -22px;
    }

    &__forward-arrow-icon {
        width: 10px;
        height: 12px;
        background: url(/Static/images/oma/white_forward_arrow.svg) no-repeat center;
        background-size: contain;
        display: inline-block;
        position: relative;
        top: 1px;
    }

    &__title_white, &__caption_white {
        color: var(--white);
        margin-top: 0;
    }

    &__line-element {
        position: absolute;
        width: 12px;
        height: 100%;
        background-color: var(--cyan);
        left: 0;
        top: 0;
    }
}

.oma-quick-links_blue, .oma-quick-links_light-blue, .oma-quick-links-centered {
    position: relative;
    padding: 27px;

    .oma-quick-links-title {
        margin-top: 0;
        font-size: 1.875rem;
        line-height: 36px;
        font-family: var(--proxima-nova-light);
    }

    @include media-breakpoint-only(xxxl) {
        padding: 30px;
    }
}
.oma-quick-links-centered{
    text-align: center;
    background-color:var(--pale-cyan);
}
.oma-quick-links_light-blue {
    background-color: var(--pale-cyan);

    .oma-quick-links__link, .oma-quick-links__link:active, .oma-quick-links__link:visited, .oma-quick-links__link:focus {
        color: var(--blue);
    }

    .oma-quick-links__forward-arrow-icon {
        background: url(/Static/images/oma/blue_forward_arrow.svg) no-repeat center;
    }
}

.oma-quick-links_blue {
    background-color: var(--dark-cyan);
}