﻿
.oma-cta-block {
    margin-bottom: $v-margin-between-blocks;


}
.oma-row-content-container {
    .oma-cta-block {
        &:last-child {
            margin-bottom: 0 !important;
        }
    }

    &.match-heights .oma-cta-block-content{
        height:100%;
    }
}

@media (max-width: 974.98px) {
    .oma-row-content-container {
        .oma-cta-block {
           flex-basis: 0 !important;

        }
    }
}

.oma-cta-block-content {
    position: relative;
    padding: 27px 27px;
    background-size: cover;
    background-position: center;
    background-color: var(--bright-blue);

    .oma-cta-block-blue-line-element {
        position: absolute;
        width: 12px;
        height: 100%;
        background-color: var(--cyan);
        left: 0;
        top: 0;
    }

    .oma-cta-block-text {
        text-align: left;
    }

    .oma-cta-title {
        color: var(--white);
        margin-top: 0;
        margin-bottom: 10px;
    }

    .oma-cta-caption {
        font-family: var(--proxima-nova-regular);
        font-size: 16px;
        color: var(--white);
        line-height: 20px;
    }

    .oma-cta-link-wrapper {
        margin-top: 5px;

        a, a:active, a:visited, a:focus {
            width: 100%;
            font-size: 16px;
            color: var(--white);
            font-family: var(--proxima-nova-semibold);
            line-height: 20px;
        }
    }
}
