﻿.oma-page-three-column {
    .page-title {
        color: var(--dark-blue);
        font-size: 40px;
        line-height: 50px;
        font-family: var(--proxima-nova);
        margin-bottom: 30px;
    }

    .page-description {
        color: var(--dark-blue);
        font-size: 28px;
        line-height: 36px;
        font-family: var(--proxima-nova);
        margin-bottom: 30px;
    }
}
