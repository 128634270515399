.oma-table-block {
    margin-bottom: $v-margin-between-blocks;

    .table-filter {
        padding: 3px;
        padding-right: 18px;
        background-position: right 5px center;
        border: 0;
        margin-top: 3px;
        width: -webkit-fill-available;
        display: block;
        border-radius: 0;
    }
}

.oma-table {
    overflow-y: scroll;
    max-height: 600px;
    display: flex;

    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--border-grey);
        min-height: 120px;

        &:hover {
            background: var(--grey);
        }
    }

    table {

        th {
            padding: 16px 20px;
        }

        td {
            padding: 20px;
        }

        p {
            margin: 0;
            font-family:var(--proxima-nova-regular)
        }

        thead {
            th {
                background: var(--bright-blue);
                color: white;
                border-right: 1px solid white;

                &:nth-child(2) {
                    border-left: 1px solid white;
                }

                &:last-of-type {
                    border-right: 1px solid var(--bright-blue);
                }

                &:first-child:empty {
                    background: transparent;
                    border-right: 1px solid var(--bright-blue);
                    padding: 0;
                }

                p {
                    color: white;
                }
            }
        }

        tbody {
            th, td {
                border: 1px solid var(--bright-blue);
            }

            th {
                background: var(--light-grey);

                &:first-child:empty {
                    background: transparent;
                    border: 0;
                    padding: 0;
                }
            }
        }

        &__row-header {
            background: var(--light-grey);
        }
    }

    &.oma-table_scroll {

        .oma-table_scroll-left {
            float: left;
            overflow: hidden;

            th {
                border-right: 0;
            }
        }

        .oma-table_scroll-right table {
            display: block;
        }


        td {
            min-width: 160px;
        }
    }

    &.oma-table_sortable {

        thead th {
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            padding-right: 40px;
            cursor: pointer;
            height: 56px;

            &.sort {
                .oma-table__sort-icon {
                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        opacity: .5;
                    }
                }
            }

            &.sort-up {
                .oma-table__sort-icon {
                    &:before {
                        opacity: .5;
                    }

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }


    &__sort-icon {
        position: absolute;
        width: 20px;
        top: 26px;
        right: 0;
        display: inline-block;

        &:before, &:after {
            content: '';
            width: 10px;
            height: 10px;
            background: url(/Static/Images/oma/arrow-white.svg) no-repeat center;
            background-size: contain;
            display: inline-block;
            position: absolute;
            opacity: .5;
        }

        &:after {
            transform: rotate(180deg);
            bottom: 0;
        }
    }

    &__caption {
        margin-top: 10px;
        font-size: 14px;
    }

    .hide-col {
        overflow: hidden;
        width: 0 !important;
        max-width: 0 !important;
        padding: 0 !important;
        border-width: 0 !important;
        font-size: 0 !important;
        min-width: unset !important;
    }

    @include media-breakpoint-down(md) {
        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        ::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: var(--bright-blue);
            border-radius: 10px;
            border: 2px solid white;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #ffffff;
        }
    }
}

@include media-breakpoint-down(md) {
    .oma-table.card-view {
        table {
            width: 100%;
        }

        table thead {
            display: none;
        }

        table tr, table td {
            border-bottom: 1px solid #ddd;
            padding: 0;
        }

        table tr {
            border: 1px solid var(--black);
            margin-bottom: 1.5rem;
            display: block;
        }

        table td {
            display: flex;
            align-items: center;
        }

        table td::before {
            content: attr(label);
            font-weight: bold;
            width: 4em;
            min-width: 4em;
            background-color: var(--bright-blue);
            color: var(--white);
            padding: 20px;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            margin-right: 1em;
            border: 1px solid var(--dark-blue);
        }
    }

    .oma-table.horizontal-scroll {
        overflow-y: auto;
        overflow-x: scroll;
        display: block;

        table {
            width: max-content;

            td,th {
                border-left: 0px;
            }

            thead th:nth-child(2) {
                position: sticky;
                left: 0;
                z-index: 5;
                outline: 1px solid white;
            }

            tbody tr td:nth-child(2) {
                position: sticky;
                left: 0;
                z-index: 5;
                background-color: white;
                outline: 1px solid var(--bright-blue);
                border-right: 0px;
                border-bottom: 0px;
                border-top: 0px;
            }
        }
    }
}

.plc-btn {
    font-family: var(--proxima-nova-regular);
    font-size: 14px;
    line-height: 38px !important;
    background-color: var(--bright-blue);
    color: #fff !important;
    font-weight: 500;
    border: 1px solid currentColor;
    padding: 0 15px;
    cursor: pointer;
    border-radius: 4px;
}

.plc-btn:hover {
    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
}

.plc-input {
    padding: 5px;
    padding-right: 30px;
    margin: 0 10px 10px 0;
}

#ddlPrac, #ddlCity, #ddlConstituencyGroup {
    display: block;
    font-size: 16px;
    color: #444;
    line-height: 1.3;
    padding: .6em 1.4em .5em .8em;
    width: 80%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E), linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
