﻿body {
}
.topicSelector {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    list-style: none;
    line-height: 2;
    padding: 10px;
    border: solid 1px black;
}
.locationSelector {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 240px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    list-style: none;
    line-height: 2;
    padding: 10px;
    border: solid 1px black;
}

.search-speakers-container {
    
    margin-bottom: 40px;
}
.search-speakers {
    margin: auto;
    padding: 35px 10px;
}
h3.title {
    font-size: 20px;
    color: #232e83;
    font-weight: 700;
    margin: 20px auto;
}
input.searchbox {
    width: 90%;
    height: 90%;
    outline: 0;
    border: 0;
    margin-left: 20px;
    font-size: 18px;
    color: #0088ce;
}
.searchbar {
    height: 43px;
    margin: 0 auto 20px;
    border: 1.5px solid #0088ce;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    overflow: hidden;
}
.mat-icon-button {
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    border:none;
}
.search-button {
    margin-right: 10px;
    padding: 0;
    min-width: 0;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    line-height: 40px;
    border-radius: 50%;
    border:none;
    background:0 0;
}
.mat-button-ripple.mat-ripple {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    pointer-events: none;
    border-radius: inherit;

}
.filter-speakers{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.filter-col-1 {
    display: flex;
    flex-direction: column;
}
.sort-col {
    display: flex;
    flex-direction: column;
}

.filter-action {
    display: flex;
    flex-direction: column;
    line-height: 16px;
    font-weight: 700;
    margin-left: 0;
    font-size: 16px;
    margin: 10px 15px 10px 0;
}

.filter-title {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 15px 10px 0;
}
.filter-topic {
    min-width: 159px;
    max-width: 200px;
    margin-right: 15px;
    padding-bottom: 10px;
    height: 43px;
}

.filter-location {
    min-width: 159px;
    max-width: 240px;
    margin-right: 15px;
    padding-bottom: 10px;
    height: 43px;
}

.field-flex {
    align-items: baseline;
    box-sizing: border-box;
    width: 100%;
}
.field-infix {
    position: relative;
    height: 43px;
    padding: 0;
    border: 1.5px solid #0088ce;
    background: #fff;
    border-radius: 4px;
}
.mat-select {
    display: inline-block;
    width: 100%;
    outline: 0;
}
.mat-form-field-label-wrapper {
    position: absolute;
    left: 0;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
    top: -.84375em;
    padding-top: .84375em;
}
.mat-form-field-label {
    display: table-cell;
    font-size: 16px;
    color: #212121;
    position: unset;
    padding-left: 10px;
    line-height: 43px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
}
.topic-field {
    max-width: 139px;
    min-width: 139px;
    margin-right: 15px;

}

.location-field {
    max-width: 179px;
    min-width: 179px;
    margin-right: 15px;
}

.mat-select-arrow {
    border: solid #fff;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(45deg);
    position: relative;
    top: -1px;
    width: 2px;
    height: 2px;
}

.mat-select-trigger {
    display: inline-table;
}

.mat-select-arrow-wrapper {
    display: block;
    height: 43px;
    min-width: 57px;
    background: #0088ce;
    text-align: center;
    position: relative;
    border-top-right-radius: 5%;
    border-bottom-right-radius: 5%;
    left: 1.5px;
    top: -2px;
    padding: 10px 0;
}

.speakers-list-container {
    margin: auto auto 40px;
    padding-left: 10px;
    padding-right: 10px;
    color: rgba(0,0,0,.87);
}


.speaker-card {
    width: 100%;
    border: 1px solid #e3e3e3;
    margin-bottom: 25px;
}
.speaker-card:hover {
    box-shadow: 0 3px 10px rgba(0,0,0,.26);
    cursor: pointer;
}
.speakers-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
}

.speakers-list::after {
    content: "";
    flex: auto;
}

.speaker-card-wrapper {
    display: flex;
    justify-content: center;
    width: 25%;
}

.speaker-card {
    width: 100%;
    border: 1px solid #e3e3e3;
    margin-bottom: 25px;
}

.sortby-filter {
    min-width: 240px;
    max-width: 240px;
    height: 43px;
    padding: 10px;
    border-radius: 4px;
    color: #0088ce;
    font-family: var(--proxima-nova-regular);
    font-size: 18px;
}

.sort-title {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 15px 10px 0;
}

.speaker-profile-picture {
    background-position: center;
    background-size: cover;
    height: 268px;
    width: 268px;
    border-radius: 50%;
    background-color: #b7b7b7;
    margin: 23px auto;
}
.speaker-name
{
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #0088ce;
}
.speaker-specialty {
    font-size: 15px;
    font-weight: 700;
    text-align: center;
    color: #273386;
    margin: 5px auto;
    max-width: 90%;
}
.speaker-description {
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    line-height: 18px;
    padding: 0 19px;
    margin-bottom: 10px;
    color: var(--black);
}
.speaker-topics {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}
.speakers-list a{
    text-decoration:none;
}

.speakers-list a:hover {
    text-decoration: none;
}
.search-form {
    background: #f5f5f5;
    width:100%;
}
.margin10
{
    margin-bottom:10px;
}

.paginator {
    margin: 20px 0;
    text-align: center;
}

.paginator .current {
    padding: 0.1875rem 0.625rem;
    background: #2199e8;
    color: #fefefe;
    cursor: default;
}

.paginator .current {
    background: #00859b !important;
    border-radius: 4px;
}

.pagination-previous::before,  .pagination-previous.disabled::before {
    content: '«';
    display: inline-block;
    margin-right: 0.5rem;
}

.pagination-previous:before, .pagination-previous.disabled:before {
    content: "<" !important;
    font-weight: 700;
}

.pagination-next::after, .pagination-next.disabled::after {
    content: '»';
    display: inline-block;
    margin-left: 0.5rem;
}
.page a {
    margin-right: 0.0625rem;
}

.pagination-next:after, .pagination-next.disabled:after {
    content: ">" !important;
    font-weight: 700;
}

.no-result {
    font-size: 18px;
    color: #0088ce;
    margin: 25px auto;
}

@media screen and (min-width: 650px) {
    .mat-form-field-empty {
        font-size: 18px;
    }
}


@media screen and (max-width: 1365px) and (min-width: 650px) {
    .filter-speakers {
        flex-direction: row;
        justify-content: space-around;
    }

    .speakers-list {
        justify-content: space-around;
    }

    .speaker-card {
        width: 350px;
    }
}

@media screen and (min-width: 1366px) {
    .search-speakers {
        max-width: 1366px;
        padding: 35px 0 0;
    }

    .filter-speakers {
        flex-direction: row;
        justify-content: space-between;
    }

    .speakers-list {
        justify-content: space-between;
    }

    .speaker-card {
        width: 314px;
        margin: 0 13px 25px 13px;
    }

    .filter-col-1 {
        flex-direction: row;
    }
    .sort-col
    {
        flex-direction: row;
    }
    .filter-action {
        flex-direction: row;
    }

}

@include media-breakpoint-only(xl) {
    .speaker-card-wrapper {
        width: 33%;
    }

    .speakers-list::after {
        content: "";
        width:33%;
        flex:initial;
    }
}

@include media-breakpoint-down(lg) {
    .speaker-card-wrapper {
        width: 50%;
    }

    .speaker-card {
        width: 300px;
    }
}

@include media-breakpoint-down(sm) {
    .speaker-card-wrapper {
        width: 100%;
    }
}