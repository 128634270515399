// stylesheet for one- and two- column pages

.oma-page {

    &__banner,
    &__main-content,
    &__main-body {
        margin-bottom: $v-margin-between-blocks;
    }

    &__caption-top {
        margin-bottom: 18px;
    }

    &__dates {
        font-size: 0.875rem;
        font-family: var(--proxima-nova-light);
        padding:3em 15px !important;
    }

    &__bottom-banners {
    }
}