﻿.oma-cta-with-background-image {
    height: 260px;
    padding: 0;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    @include media-breakpoint-only(xxl) {
        height: 315px;
    }

    @include media-breakpoint-only(xxxl) {
        height: 360px;
    }

    .oma-cta-block-text {
        width: 270px;
        padding: 15px 15px 8px 15px;
        overflow: hidden;
        background-color: var(--white);
        text-align: left;
        opacity: 0.93;
        margin-bottom: $v-margin-between-blocks;
        max-height: calc(100% - #{$v-margin-between-blocks * 2});

        @include media-breakpoint-only(xxl) {
            width: 327px;
            padding: 18px 18px 10px 18px;
        }

        @include media-breakpoint-only(xxxl) {
            width: 370px;
            padding: 20px 20px 10px 20px;
        }

        .oma-cta-caption {
            line-height: 1.5em;
            color: var(--black);
            font-size: 14px;
            margin-bottom: 5px;
        }

        .oma-cta-title {
            line-height: 24px;
            overflow: hidden;
            color: var(--blue);
            font-size: 24px;
            margin-bottom: 0;
        }
    }
}
