﻿.benefit-container {
    background-color: #EAF6F6;
    padding: 20px 14px;
}
.OMF {
    .benefit-container {
        background-color: var(--light-grey);
    }
}

.OMA {
    .benefit-container {
        background-color: var(--pale-cyan);
    }
}