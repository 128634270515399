﻿.priority-leadership {
    padding: 0;
    background-color: var(--white);
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;

    .oma-image-container {
        width: 120px;
        height: 150px;
        overflow: hidden;
        margin-bottom: 0;
        flex-shrink: 0;

        @include media-breakpoint-only(xxl) {
            width: 145px;
            height: 180px;
        }

        @include media-breakpoint-only(xxxl) {
            width: 165px;
            height: 205px;
        }

        .oma-image {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .oma-text-container {
        padding: 20px 15px;
        text-align: left;

        @include media-breakpoint-only(xxl) {
            padding: 23px 18px;
        }

        @include media-breakpoint-only(xxxl) {
            padding: 25px 20px;
        }

        .oma-title {
            font-size: 18px;
            line-height: 24px;
            font-family: var(--proxima-nova-regular);
            color: var(--blue);
        }

        ul {
            font-family: var(--proxima-nova-light);
        }
    }
}
