﻿.award-winners-container {
    background-color: #f5f5f5;
    padding-bottom: 45px;
    position:relative;
}

.award-winners .top-text {
    border-radius: 4px;
    padding: 10px 15px;
    margin: 10px 0;
    background: #fff;
    line-height: 30px;
}

.award-winners-container .page-container {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 20px;
}

.award-winners-container .page-title {
    font-size: 22px;
    font-family: var(--proxima-nova-regular);
    color: #63666a;
    margin-top: 25px;
    margin-bottom: 10px;
}

.award-filters-container {
    background: rgba(227,227,227,.5);
    border-top: 1px solid #e9e9e9;
}

.award-filters {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    color: #4d526e;
    align-items: center;
    font-size: 14px;
}


.award-filters.desktop {
    display: none;
}

.award-filters.mobile {
    display: none;
}


.winners-list-container {
    display: flex;
    flex-wrap:wrap;
}

@media screen and (min-width: 650px) {
    .award-filters.desktop {
        display: flex;
    }
}

@media screen and (max-width: 650px) {
    .award-filters.mobile {
        display: flex;
    }
}

.award-filters .filter-title {
    margin-right: 30px;
    color: rgba(77,82,110,.75);
    font-size: 14px;
    position: relative;
    top: 1px;
    margin-top: 10px;
    margin-bottom: 11px;
}


.award-filters .filter-select {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 650px) {
    .award-filters .filter-select {
        display: block;
    }
}

.category-name {
    font-size: 16px;
    font-family: var(--proxima-nova-regular);
    
    color: #12284c;
    border: #000;
    margin-bottom: 15px;
}

.mat-select-arrow-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.mat-select-arrow {
    border: solid #4d526e;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2.5px;
    transform: rotate(45deg);
    position: relative;
    top: -3px;

}
.dropdownsize {
    padding: 2.5px;
    top: 9px;
    border: solid #FFF;
    border-width: 0 2px 2px 0;
    display: inline-block;
    transform: rotate(45deg);
    position: relative;
}


.filter-link {
    margin-right: 30px;
}

.filter-selectors {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.filter-selectors .filter-link {
    margin: 10px 10px 10px 5px;
}

.filter-selectors-mobile {
    display: none;
    margin: 0px 10px 10px 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.filter-title-mobile {
    flex: 1;
}
