﻿.oma-marketing-intro-block {
    border-width: 0px;
    margin: 0 auto;
    max-width: 920px;
    height: 405px;
    position: relative;
    margin-bottom:2em;

    .oma-marketing-intro-image {
        border-width: 0px;
        background: inherit;
        background-color: rgba(170, 170, 170, 1);
        width: 540px;
        height: 405px;
    }

    .oma-marketing-intro-caption {
        position: absolute;
        background-color: var(--teal);
        padding: 40px 15px 40px 15px;
        color: var(--white);
        left: 470px;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        padding: 40px 15px;
        left: 473px;
        left: 51%;
        width: 460px;
        min-height: 230px;
        display: flex;
        flex-direction: column;

        h3, p {
            color: var(--white);
        }

        a, a:active ,a:visited, a:focus {
            color: var(--white);


            font-family: var(--proxima-nova-semibold);

            &:hover {
                color: var(--white);
            }
        }
    }

    &.OMA_bg_style {
        .oma-marketing-intro-caption {
            background-color: var(--dark-blue);
        }
    }

    @media only screen and (max-width: 990px) {
        .oma-marketing-intro-caption {
            left: 33%;
        }
    }

    @media only screen and (max-width: 720px) {
        max-width: 690px;
        height: auto;
        margin-bottom:-50px;

        .oma-marketing-intro-image {
            width: 100%;
            height: auto;
            aspect-ratio: 330/246;
        }

        .oma-marketing-intro-caption {
            left: initial;
            -ms-transform: translateY(0);
            transform: translateY(0);
            position: relative;
            top: -50px;
            width: 90%;
            margin: 0 auto;
            height: auto;
            float: none;

            h3 {
            }

            a {
                &:hover {
                }
            }
        }
    }
}

 
@media only screen and (max-width: 960px) {
    .oma-marketing-intro {
        max-width: 690px;

        .oma-marketing-intro-image {
            width: 330px;
            height: 246px;
        }

        .oma-marketing-intro-caption {
            left: 30px;
            top: 206px;
            width: 300px;
            height: 310px;

            h3 {
            }

            a {
                &:hover {
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .oma-marketing-intro {
        max-width: 690px;

        .oma-marketing-intro-image {
            width: 330px;
            height: 246px;
        }

        .oma-marketing-intro-caption {
            left: 30px;
            top: 206px;
            width: 300px;
            height: 310px;

            h3 {
            }

            a {
                &:hover {
                }
            }
        }
    }
}
