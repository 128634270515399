﻿.oma-featured-content-block {
    margin-bottom: $v-margin-between-blocks;

    @include media-breakpoint-down(lg) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.startpage {
    .oma-featured-content-text {
        .oma-featured-content-caption {
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            margin-top: 0px;
        }

        .oma-featured-content-title {
            margin-top: 10px;
        }
    }
}

.oma-featured-content-block-content {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-only(md) {
        flex-direction: row;
    }

    .oma-featured-content-image-container {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        overflow: hidden;
        //width: 100%;
        height: 200px;

        @include media-breakpoint-only(md) {
            flex-basis: 350px;
            flex-grow: 0;
            flex-shrink: 0;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
            flex-basis: auto;
        }

        a {
            height: 100%;
        }

        @include media-breakpoint-only(xxl) {
            height: 242px;
        }

        @include media-breakpoint-only(xxxl) {
            height: 280px;
        }

        .oma-featured-content-image {
            //height: 100%;
            object-fit: cover;
        }
    }
    .oma-featured-content-text {
        display: flex;
        flex-direction: column;
        padding: 1.5em 1em 0.5em 1em;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .oma-featured-content-title {
            color: var(--blue);
        }
    }
}
.feature1 {
    .oma-featured-content-image-container {
        height: 100%;

        .oma-featured-content-image-container {
        }
    }
}