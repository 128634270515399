.oma-breadcrumb {

    padding: 0;
    margin: 16px 0;

    @include media-breakpoint-down(sm) {
        display: none;

    }

    &__item {
        list-style-type: none;
        display: inline-block;
        font-size: 13px;
        font-family: var(--proxima-nova-light);

        &:after {
            content: "/";
            display: inline-block;
            margin: 0 2px;
            color: var(--breadcrumb-grey);
        }
    }

    &__link, &__link:active,&__link:visited,&__link:focus {
        color: var(--breadcrumb-grey);
        text-decoration: underline;

        &:hover {
            color: var(--breadcrumb-grey);
        }
    }

}