.oma-discount-detail {

    &__banner,
    &__images {
        margin-bottom: $v-margin-between-blocks;
    }

    &__banner img,
    &__main-image {
        width: 100%;
    }

    &__images {
        position: relative;
    }

    &__logo-image-container {
        width: 160px;
        height: 120px;
        position: absolute;
        bottom: 0;
        right: 95px;
        overflow: hidden;
        max-height: 80%;

        @include media-breakpoint-only(md) {
            width: 120px;
            height: 90px;
            right: 60px;
        }

        @include media-breakpoint-down(sm) {
            width: 80px;
            height: 60px;
            right: 30px;
        }
    }

    &__logo-image {
        height: 100%;
        object-fit: contain;
        width: 100%;
    }

    &__categories {
        margin-top: $v-margin-between-blocks;
        margin-bottom: $v-margin-between-blocks;

        a {
            text-decoration: underline;
        }
    }

    &__caption {
        font-family: var(--proxima-nova-regular);
        color: var(--dark-blue);
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        margin-bottom: 20px;
    }

    &__main-content {
        ul, ol {
            font-size: 16px;
            font-family: var(--proxima-nova-light);
            line-height: 22px;
            color: var(--black);
        }

        li {
            margin-bottom: 1rem;
        }
    }
}

.white-bg {
    background-color: var(--white);
}

.transparent-bg{
    background-color:transparent;
}