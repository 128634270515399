.oma-salesforce-case-form {
    margin: 0 auto;
}

.oma-salesforce-case-form-title {
    font-size: 36px;
    font-family: var(--proxima-nova-light)
}

.salesforce-case-form-link .salesforce-link {
    font-family: var(--proxima-nova-semibold);
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    color: #006DA5;
    text-decoration: none;
    cursor:pointer;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}

.oma-saleforce-case-form-container {
    & > h2, & > p, & > h4{
        text-align:center;
    }
    & > .oma-saleforce-case-form-inner-container {
        & > * {
            margin-bottom: 30px;
            display: flex;
        }
    }
}

.oma-saleforce-case-form-block {
    width: 100%;
    background-color: #fff;
    max-width: 750px;
    max-height: 750px;
    margin: 5% auto;
    border: 2px solid var(--bright-blue);
    overflow-y: scroll;

    .oma-saleforce-case-form-container {
        text-align: center;
        flex-direction: column;
        height: 100%;
        padding: 24px;
        background: #fff;
        margin: 5% auto;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--border-grey);

            &:hover {
                background: var(--grey);
            }
        }

        .member-detail {
            font-family: var(--proxima-nova-light);
            text-align: left;
            font-size: 30px;
            line-height: 36px;
            color: #006DA5;
            margin-top: 2px;
            font-weight: 400;
            margin-bottom: 0.5rem;

        }

        .with-image {
            display: inline-block;
        }

        .sub-title {
            font-family: var(--proxima-nova-regular);
            text-align: center;
            font-size: 18px;
            line-height: 24px;
            color: #212121;
        }

        .top-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            & .expander {
                flex: 1;
            }

            .close-button {
                cursor: pointer;
                background: url(/Static/images/oma/header/close-icon.png);
                width: 20px;
                height: 20px;
            }
       }     
    }
}

.salesforce-case-form-modal {
    display: none;
    position: fixed;
    z-index: 2500;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
    cursor: auto;

    .spinner{
        display: none;
    }
}

.salesforce-case-form-modal.active {
    display: block;

    .submitted {
        display: block;
    }
}

.case-submission-error, .invalid-case-input {
    display: none;
    padding: 20px;

    & > p {
        color: red;
        font-weight: bold;
    }
}

.salesforce-captcha{
    padding: 20px;
}

  