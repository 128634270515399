.omai-table-block {
    margin-bottom: $v-margin-between-blocks;
}

.omai-table {
    table {
        font-family: var(--proxima-nova-regular);
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        color: black;
    }

    .omaiscroll {
        overflow-x: auto;
    }
}

.plc-btn {
    font-family: var(--proxima-nova-regular);
    font-size: 14px;
    line-height: 38px !important;
    background-color: var(--bright-blue);
    color: #fff !important;
    font-weight: 500;
    border: 1px solid currentColor;
    padding: 0 15px;
    cursor: pointer;
    border-radius: 4px;
}

.plc-btn:hover {
    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
}

.plc-input {
    padding: 5px;
    padding-right: 30px;
    margin: 0 10px 10px 0;
}


.columns {
    padding: 20px;
    background-color: #EAF6F6;
}

.SecRow {
    background-color: white;
}

.SecCol {
    font-size: 18px;
    font-weight: 500;
    padding: 20px;
    text-align: left;
}


.NormalCol {
    font-size: 15px;
    font-weight: 400;
    padding: 20px;
}


.NormalRow {
}

.p-cell {
    font-size: 15px;
    font-family: var(--proxima-nova-light);
    margin-bottom: 0px;
}


.table-scroll {
    position: relative;
    max-width: inherit;
    overflow: hidden;
    color: lightgray;
    border-color: transparent;
}

.table-wrap {
    overflow: auto;
}

.table-scroll table {
    font-family: var(--proxima-nova-regular);
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: black;
    margin-bottom: 30px;
}

.table-scroll th, .table-scroll td {
    padding: 20px;
    border-right: 1px solid lightgray;
    vertical-align: top;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;
}

.table-scroll1 th, .table-scroll1 td {
    padding: 20px;
    border-top: 2px solid lightgray;
    vertical-align: top;
}


.table-scroll thead, .table-scroll tfoot {
    background: #f9f9f9;
}

.clone {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.clone th, .clone td {
    visibility: hidden
}

.clone td, .clone th {
    padding: 20px;
    background-color: #EAF6F6;
    border-right-color: transparent;
}

.clone tbody th {
    background-color: White;
    padding: 20px;
}

.clone .fixed-side {
    background-color: white;
    visibility: visible;
    padding: 20px;
    text-align: left;
    border-top: 2px solid lightgray;
}

.clone .fixed-side2 {
    background-color: white;
    visibility: visible;
    padding: 20px;
    text-align: left;
    border-top: 1px solid lightgray;
}

.clone thead, .clone tfoot {
    background: transparent;
}

.th-background {
    font-size: 18px;
    font-weight: 500;
    background-color: white;
}

.th-background-normal {
    font-size: 15px;
    font-weight: 400;
    background-color: white;
}

.hiding td {
    border-right-color: transparent;
}
