﻿.oma-row-content-container {
    display: grid;
    grid-gap: 30px;
    margin-bottom: 30px;

    &.match-heights {
        grid-auto-rows: 1fr;
    }

    & > * {
        margin-bottom: 0;
        height: fit-content;
    }

    &.match-heights > * {
        height: 100%;
        width: 100%;
    }

    &.row-count-Two {
        grid-template-columns: repeat(2,1fr);
    }

    &.row-count-Three {
        grid-template-columns: repeat(3,1fr);
    }

    &.row-count-none {
        grid-auto-flow: column;
    }

    @media (max-width: 991.98px) {
        grid-template-columns: 1fr !important;
    }

    &.light-gray-background {
        background-color: #E7E2DF;
        min-height: 300px;
        padding: 0 2rem 0 0;

        .oma-featured-content-text {
            padding: 3.5em 1em 0.5em 1em;
            font-family: var(--proxima-nova-regular);

            .yellow-left-border {
                margin-bottom: 1rem;
            }

            p {
                font-family: var(--proxima-nova-regular);
            }
        }

        .phpvideo iframe {
            display: block;
            width: 100%;
            border: none;
        }
    }
}
.startpage {
    .oma-row-content-container{
        grid-gap:30px;
    }
}
