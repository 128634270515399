.oma-news-event-detail {
    &__banner {
        margin-bottom: $v-margin-between-blocks;

        img {
            width: 100%;
        }
    }

    &__left-col {
        margin-bottom: $v-margin-between-blocks;
    }

    &__news-type,
    &__location {
        font-size: 24px;
    }

    &__date {
        margin-top: 10px;
    }

    &__author {
        &-box {
            margin: 32px 0 16px 0;
        }

        &-pic {
            width: 65px;
            height: 65px;
            background-size: cover;
            background-position: top center;
            border-radius: 50%;
            margin-right: 10px;
        }

        &-no-pic {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background: rgba(141, 158, 176, 0.82) none no-repeat scroll 50% 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
        }

        &-name {
            margin-top: 22px;
            font-family: var(--proxima-nova-semibold);
            font-weight: bold;
        }

        &-col2 {
            
        }
    }

    &__addthis {
        margin-top: 30px;
        .at-share-btn {
            margin-right: 10px;
        }
    }

    &__contact {
        &-name,
        &-phone,
        &-email {
            font-size: 14px;
        }
    }

    &__page-title {
        margin-bottom: 30px;
    }

    &__caption {
        font-family: var(--proxima-nova);
        color: var(--dark-blue);
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 36px;
    }

    &__left-content {
        margin-bottom: $v-margin-between-blocks;
    }

    &__publication-note {
        margin-top: 20px;
    }

    &__publication-note,
    &__publication-note p {
        font-size: 0.875rem;
    }

    &__bottom-dates {
        margin-top: 32px;
        font-size: 14px;
    }

    &__topic {
        text-decoration: underline;
    }

    .omr-light-blue {
        border: 1px solid #ddddde;
        background-color: #e0f1f3;
        background-repeat: no-repeat;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-height: 217px;
        margin: 0 15px 30px;

        h3 {
            font-size: 26px;
            color: #222d82;
            margin: 15px;
        }

        p {
            margin: 15px;
            font-size: 16px;
            font-family: var(--proxima-nova-light);
            line-height: 1.4;
        }
    }

    h2,
    h3 {
        margin-top: 32px;
    }

    li {
        font-family: var(--proxima-nova-light);
        margin-bottom: 1rem;
    }

    li strong,
    li b {
        font-family: var(--proxima-nova-regular);
    }
}
