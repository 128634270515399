.oma-text-block {
    margin-bottom: $v-big-margin-between-blocks;
    font-family: var(--proxima-nova-light);
    display: block;
    clear: both;
    // block title
    &-title {
        font-size: 28px;
        line-height: 36px;
        margin-bottom: 36px;
        color: var(--dark-blue);
        font-family: var(--proxima-nova-regular);
    }

    h4 {
        font-family: var(--proxima-nova-regular);
    }

    h6 {
        font-family: var(--proxima-nova-regular);
        font-size: 16px;
        line-height: 20px;
    }

    h2, h3, h4, h5, h6 {
        margin-top: 32px;
    }

    blockquote, blockquote p {
        color: var(--bright-blue);
        font-size: 22px;
        line-height: 36px;
        font-family: var(--proxima-nova-regular);
        font-style: italic;
    }

    ul, ol {
        line-height: 22px;
    }

    li {
        margin-bottom: 1rem;

        a{
            text-decoration:underline;
            &:hover{
                color:var(--dark-blue);
            }
        }
    }

    li strong, li b {
        font-family: var(--proxima-nova-regular);
    }

    & .block-quote-2 {
        font-size: 24px;
        color: #212121;
        font-style: normal;
        border: 1px solid #0088CE;
        border-width: 2px 0;
        padding: 32px 18px;
        margin: 30px 0;
        line-height: 28px;
    }

    & .font-Large {
        font-size: 1.75em;
    }

    & .font-Medium {
        font-size: 1.5em;
    }

    & .font-Small {
        font-size: 0.85em;
    }

    & .steps {
        max-width: inherit;
        counter-reset: my-awesome-counter;
        list-style: none;
        padding-left: 40px;
    }

    & .steps li {
        margin: 30px;
        counter-increment: my-awesome-counter;
        position: relative;
        list-style-type: none;
    }

    & .steps li::before {
        content: counter(my-awesome-counter);
        color: #006DA5;
        font-family: var(--proxima-nova-bold);
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        --size: 32px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        background: #EAF6F6;
        border-radius: 50%;
        text-align: center;
    }
}

.lightblue75 {
    border-radius: 4px;
    padding: 1.1em 1em;
    margin: 1em 0;
    background-color: #DFF6FC;
}

.darkblue {
    border-radius: 4px;
    padding: 1.1em 1em;
    margin: 1em 0;
    background: #232E83;
    color: #ffffff;

    h3:first-of-type {
        color: #ffffff;
    }
}

@include media-breakpoint-up(md) {
    .lightblue75, .darkblue {
        max-width: 75%;
    }
}

.darkblue * {
    color: #ffffff;
}

.lightblue100 {
    padding: 1em;
    background: #DFF6FC;
}

.oma-content-highlight {
    padding: 27px;
    margin-bottom: 30px;

    h3 {
        margin-top: 0;
    }
}
