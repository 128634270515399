﻿.priority-cta-with-video {
    display: flex;
    background-color: #DFF6FC;
    margin-bottom: 30px;

    &.narrow {
        flex-direction: column;
        align-items: stretch;

        & > .inner-elements {
            align-items: center;

            

            & h3 {
                text-align: center;
                font-family: var(--proxima-nova-light);
                color:var(--black);

            }

            & a.btn {
                align-self: initial;
                background-color: #007DBB;

                &:hover {
                    background-color: #232e83;
                }
            }
        }
    }

    @media (max-width: 991px) {
        flex-direction: column;
        align-items: stretch;

        & > div.inner-elements {
            align-items: center;
            padding-top: 0;

            & h3 {
                text-align: center;
            }

            & a.btn {
                align-self: initial !important;
            }
        }
    }

    & > div {
        padding: 15px;

        &.inner-elements {
            display: flex;
            flex-direction: column;
            min-width: 60%;
            max-width: 100%;
            & > * {
            }

            & > a {
                font-family: var(--proxima-nova-semibold);
                margin-bottom: 1em;
            }

            & h3 {
                font-family: var(--proxima-nova-light);
                color: var(--black);
            }

            & a.btn {
                align-self: start;
            }
        }

        &.inner-video {
            min-width: 40%;
            max-width: 100%;
            flex-grow: 1;

            .video {
                max-width: 100%;
                min-width: 100%;
                aspect-ratio: 16/9;
            }
        }
    }
}

.OMA .priority-cta-with-video {
    background-color: #DFF6FC;

    &.narrow {
        & > .inner-elements {

            & a.btn {
                align-self: initial;
                background-color: #007DBB;

                &:hover {
                    background-color: #232e83;
                }
            }
        }
    }

    & > div {
        &.inner-elements {

            & > a {
                font-family: var(--proxima-nova-semibold);
            }

            & h3 {
                color: var(--black);
            }
        }
    }
}

.OMAI {
    .priority-cta-with-video {
        background-color: #EAF6F6;

        &.narrow {
            & > .inner-elements {
                & a.btn {
                    align-self: initial;
                    background-color: #007DBB;

                    &:hover {
                        background-color: #232e83;
                    }
                }
            }
        }

        & > div {
            &.inner-elements {
                & > a {
                    font-family: var(--proxima-nova-semibold);
                }

                & h3 {
                    color: var(--black);
                }
            }
        }
    }
}
