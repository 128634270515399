﻿.oma-publication-article-tile-block {
    width: auto;
    background-color: #F5F5F5;
}

.oma-publication-article-tile-block-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;


    .article-tag {
        width: auto;
        height: auto;
        right: 5px !important;
        text-transform: uppercase;
        z-index: 1;

        .tag-text {
            padding: 0px 10px;
            line-height: 20px;
            margin-bottom: 0px;
            font-family: var(--proxima-nova-semibold);
            font-size: 12px;
        }
    }


    .oma-publication-article-image-container {
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        overflow: hidden;
        height: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
            flex-basis: auto;
        }

        a {
            height: 100%;

            img {
                width: 350px;
                height: 197px;

                @include media-breakpoint-only(xs) {
                    width: 100%;
                }
            }
        }

        .oma-publication-article-image {
            //height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }

    .oma-publication-article-text {
        display: flex;
        flex-direction: column;
        padding: 0px 15px;
        padding-top: 25px;
        font-size: 24px;
        font-family: var(--proxima-nova-regular);
        

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        .oma-publication-article-headline {
            color: var(--blue);
        }

        .oma-publication-article-caption {
            font-size: 16px;
            font-family: var(--proxima-nova-light);
            margin-bottom: 10px;
        }

        .article-date {
            font-size: 14px;
            line-height: 17px;
        }
    }
}