﻿.oma-document-bottom-part {
    position: relative;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .oma-footer-icon {
        position: relative;
        width: 185px;
        height: 160px;

        .oma-footer-icon-square {
            position: absolute;
            width: 80px;
            height: 80px;

            &.blue-square {
                top: 80px;
                left: 0;
                background-color: var(--bright-blue);
            }

            &.cyan-square {
                top: 0;
                left: 80px;
                background-color: var(--light-cyan);
            }

            &.dark-blue-square {
                top: 80px;
                left: 160px;
                width: 25px;
                height: 25px;
                background-color: var(--dark-blue);
            }
        }
    }

    .oma-back-to-top-link-container {
        margin-bottom: 15px;

        .oma-back-to-top-link, .oma-back-to-top-link:active, .oma-back-to-top-link:visited, .oma-back-to-top-link:focus {
            display: flex;
            flex-direction: row;
            color: var(--black);
            font-size: 16px;
            line-height: 30px;
            font-family: var(--proxima-nova-regular);

            &:hover {
                color: var(--black);
            }
        }
    }

    .oma-back-to-top-arrow-container {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bright-blue);
        padding-top: 3px;
        margin-left: 10px;

        .oma-back-to-top-arrow {
            width: 10px;
            height: 10px;
            border-top: 3px solid var(--white);
            border-right: 3px solid var(--white);
            transform: rotate(-45deg);
        }
    }
}
.OMF{
    .oma-document-bottom-part {
        

        .oma-footer-icon {
           

            .oma-footer-icon-square {
               

                &.blue-square {
                 
                   display:none;
                }

                &.cyan-square {
                    top:25px;
                    left:0px;
                    background-color: var(--omf-green);
                }

                &.dark-blue-square {
                    left:80px;
                    top:0px;
                    background-color: var(--omf-blue);
                }
            }
        }
    }
}