﻿.oma-footer {
    background-color: var(--light-grey);

    &-top-row {
        display: flex;
        padding: 2.5rem 0;

        h4 {
            margin-bottom: 0.8rem;
        }

        a, a:active, a:visited, a:focus {
            font-size: 16px;
            line-height: 20px;
            font-family: var(--proxima-nova-semibold);
            color: var(--blue);
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
            color: var(--blue);
        }

        &-item {
            .oma-footer-title {
                margin-bottom: 1rem;
                font-size: 1.5rem;
                line-height: 28px;
                color: var(--black);
                font-family: var(--proxima-nova-regular);
            }

            .oma-connect-icons {
                display: flex;
                flex-direction: column;

                .oma-app-links {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;

                    .oma-app-link-wrapper {
                        margin-right: 10px;
                        margin-bottom: 10px;

                        .oma-app-link img {
                            width: 152px;
                            height: 45px;
                        }
                    }
                }

                .oma-social-icons-wrapper {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 1.5rem;

                    .oma-social-icon {
                        margin-right: 10px;
                        margin-bottom: 5px;
                        text-decoration: none;

                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                }
            }

            .oma-last-column-links {
                display: flex;
                flex-direction: column;

                .oma-cta-link {
                    margin-bottom: 1rem;
                }
            }

            p:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-bottom-row {
        display: flex;
        flex-direction: row;
        padding: 1.5rem 0;

        &-item {
            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: flex-end;
            }

            .oma-bottom-row-link {

                &:not(:last-child) {
                    margin-right: 20px;
                }

                color: var(--black);
                text-decoration: underline;
            }
        }

        &-item.oma-footer-copyright {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 768px) {
    .oma-footer {
        &-top-row {
            flex-direction: column;
            padding-bottom: 0;

            &-item {
                padding-bottom: 2.5rem;
            }
        }

        &-bottom-row {
            flex-direction: column;
            padding: 0;

            &-item {
                padding-bottom: 1rem;
            }
        }
    }
}

@media only screen and (min-width: 420px) and (max-width: 768px) {
    .oma-footer-top-row-item {
        .oma-connect-icons {
            flex-direction: row;

            .oma-social-icons-wrapper {
                margin-right: 40px;
            }
        }
    }
}