﻿
.emarketplace {
    background: #f5f5f5;
    padding-bottom: 10px;
}
.emarketplace-results {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
    background: #f5f5f5;
    border-radius: 3px;
}

.emarketplace-intro-container {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flex;
}

@media only screen and (max-width: 649px) {
    .emarketplace .emarketplace-intro-container {
        flex-direction: column;
    }

    .emarketplace .emarketplace-intro-container .welcome-div {
        max-height: inherit;
        width: 100%;
        margin-bottom: 20px
    }

    .emarketplace .emarketplace-intro-container .color-fill-div {
        width: 100%;
        background-color: #fcd672;
        border: 1px solid #fcd672;
        border-radius: 5px;
        margin-top: 0;
        margin-left: 0;
        min-width: 318px;
    }
}

.welcome-div {
    width: 75%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding: 20px 25px 15px;
    float: left;
    margin-top: 20px;
    clear: both;
    overflow-wrap: break-word;
}
.color-fill-div {
    width: 24%;
    background-color: #fcd672;
    border: 1px solid #fcd672;
    border-radius: 5px;
    margin-top: 20px;
    margin-left: 20px;
    min-width: 318px;
}
@media screen and (min-width: 1366px) {
    .emarketplace-results {
        max-width: 1366px;
        padding-left: 20px;
        padding-right: 20px;
    }
}


@media screen and (min-width: 650px){
    .emarketplace-filters-container {
        height: 75%;
    }
}

.emarketplace-filters-container {
    display: inline-block;
}
.emarketplace .emarketplace-results > div {
    width: 100%;
    border-radius: 5px;
}

.filters-sub-container {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 5px;
    padding-left: 15px;
    overflow-wrap: break-word;
    padding-bottom: 20px;
}

.emarketplace .emarketplace-results .title {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 22pt;
    margin-top: 20px;
}

.emarketplace .emarketplace-results .description {
    font-size: 16px;
    color: #63666a;
    line-height: 20pt;
    margin-top: 15px;
}

.emarketplace .emarketplace-results  .detail {
    font-size: 14px;
    color: #63666a;
    line-height: 18pt;
    margin-top: 15px;
    margin-bottom: 15px;
}

.emarketplace-location-selector {
    background-color: #ffffff;
    min-width: 240px;
    /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
    z-index: 1;
    list-style: none;
    line-height: 2;
    padding: 15px;
    border: solid 1px #63666a;
    border-radius: 5px;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    width: 30% !important;
}
.emarketplace-filter-prod {
    padding-left: 15px;
}
.accordion-container {
    box-shadow: none;
    margin-top: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin: 16px 0;
    box-sizing: content-box;
    display: block;
    border-radius: 4px;
    overflow: hidden;
    transition: margin 225ms cubic-bezier(.4,0,.2,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
    background: #fff;
    color: rgba(0,0,0,.87);
    margin-bottom: 20px;
}
.mat-expansion-panel-header {
    display: flex;
    flex-grow: 1;
    margin-right: 16px;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    border-radius: inherit;
    height: 73px;
    cursor:pointer;
}
.mat-expansion-panel-header-title {
    font-size: 16px;
    font-weight: 700;
    color: #63666a;
    line-height: 22px;
    padding:3px;
}
.mat-checkbox-frame {
    border-radius: 50% !important;
    border: 1px solid #cecfd0;
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0,0,.2,.1);
    width: 15.6px;
    height: 15.6px;
}
.mat-expansion-indicator {
    width: 15.6px;
    height: 15.6px;
    margin-top: 3px;
    margin-left: 8px;
}
.accordion-body-container {
    display: flex;
    flex-wrap: wrap;
    cursor:pointer;
}
.mat-expansion-panel-body {
    padding: 0 24px 16px;
    width: 99%;
    display: flex;
    flex-wrap: wrap;
}
.accordion-body-checkbox-container {
    position: relative;
    width: 392px;
    border: 1px solid #efefef;
    border-radius: 5px;
    padding: 14px 10px 10px;
    margin-right: 20px;
    margin-bottom: 10px;
}
.accordion-body-checkbox-text {
    font-size: 12px;
    font-weight: 700;
    color: #63666a;
    line-height: 14pt;
    float: left;
    clear: both;
    padding:6px;
}
.accordion-checkbox-footer {
    float: right;
    position: relative;
    margin: 0 5px;
}
.mat-market-expansion-indicator
{

}
.mat-market-expansion-indicator::after {
    border-style: solid;
    border-width: 0 2px 2px 0;
    content: '';
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    vertical-align: middle;
}

.mat-content {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
}
.round {
    margin-left: 10px;
}
.search-button-container {
    padding-left: 15px;
    margin-bottom: 20px;
}
.mat-stroked-button {
    background-color: #00859b;
    border: 1px solid #00859b;
    margin-right: 10px;
    color: #fff;
    box-shadow: 0 0 0 0 rgba(0,0,0,.2), 0 0 0 0 rgba(0,0,0,.14), 0 0 0 0 rgba(0,0,0,.12);
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 4px;
    overflow: visible;
    width: 167px;
    height: 36px;
    font-size: 16px;
    line-height: 26px;
}
.mat-expansion-panel-header-footer
{
    padding:2px;
}

.flip {
    -moz-transition: transform 1s;
    -webkit-transition: transform 1s;
    transition: transform 1s;
    transform: rotate(-180deg);
}

.roundcheckbox {
    width: 1.5em;
    height: 1.5em;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #ddd;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.roundcheckbox:checked {
    background-color: gray;
}

.roundcheckbox:checked::after {
    content: '\2713';
    padding: 8px;
    color: white;
}
.accordion-body-checkbox-container-selected {
    background-color: #bfe0dd;
    border: 1px solid #7fc2bb;
    border-radius: 5px;
}